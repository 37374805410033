import React, { useState, useContext, useEffect } from 'react';
import { address } from '../commonImports/fields/commonFields'
import { Menu, notification, Popover,List, Avatar, Badge, Button, Dropdown } from 'antd';
import { graphql, withApollo, useQuery, Query, Subscription } from 'react-apollo';
import { subscribeToNotification, getNotificationsByUserByCreatedAt, getEncounterById } from '../../graphql/queries'
import { updateEncounter } from '../../graphql/mutations'

import {  BellOutlined, DownOutlined, LogoutOutlined, SkypeOutlined, FullscreenOutlined } from '@ant-design/icons';
import {flowRight as compose} from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';


const updateEncounterMut = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.mutate({
      mutation: updateEncounter,
      variables: variables 
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
    })
  })
}

const getNotificationsByUserByCreatedAtQuery = async (props, variables) => {
    return await new Promise((res, rej) => {
      props.client.query({
        query: getNotificationsByUserByCreatedAt,
        variables: variables,
        fetchPolicy:'no-cache',
        // pollInterval: 500
      }).then((data) => {
        res(data)
      }).catch((err) => {
        console.log(err)
      })
    })
  }



  const getEncounterByIdQuery = async (props, variables) => {
    return await new Promise((res, rej) => {
      props.client.query({
        query: getEncounterById,
        variables: variables,
        fetchPolicy:'no-cache',
      }).then((data) => {
        res(data)
      }).catch((err) => {
        console.log(err)
      })
    })
  }



const NotificationComp = (props) =>{

  const [data, setData] = useState([])
  const [openCount, setopenCount] = useState(0)
  const [pendingCount, setpendingCount] = useState(0)
  


 

  useEffect(() => {
    const fetchDataAsync = () => {
    
        getNotificationsByUserByCreatedAtQuery(props)
        .then(async(res)=>{
            if (res.data && res.data.getNotificationsByUserByCreatedAt && res.data.getNotificationsByUserByCreatedAt != null && res.data.getNotificationsByUserByCreatedAt.items.length > 0) {
                setData(res.data.getNotificationsByUserByCreatedAt.items)
                // let pendingIndex = 0;
                // let openIndex = 0;
                // let arr = [];
                // let dd = res.data.getNotificationsByUserByCreatedAt.items;
                // dd = dd.slice(0,5);
           
                
                // for(let i=0; i < dd.length; i++){
               

                //   let item = dd[i];
                //   let variables={
                //     patientId: item.patientId,
                //     encounterId: item.encounterId
                //   }
             

                //   let ss = await getEncounterByIdQuery(props,variables);
            
                //   if(ss.data.getEncounterById != null){
                //     item['status'] = ss.data.getEncounterById.status;
                //     if(ss.data.getEncounterById.status == 'PENDING'){
                //       pendingIndex = pendingIndex + 1;
                //     }
                //     if(ss.data.getEncounterById.status == 'OPEN'){
                //       openIndex = openIndex + 1;
                //     }
                //   }

                //   arr.push(item)
                // }
             
                // console.log(arr)
                // setData(arr)
                // setpendingCount(pendingIndex)
                // setopenCount(openIndex)
            }

        }).catch((err)=>{
            console.log(err)
        })
    }
    fetchDataAsync()
  }, [])


  useEffect(() => {
    let arr = [...data];
    if(props.data && props.data.loading == false){
      let obj = props.data.subscribeToNotification;
      if(obj){
        let flag = data.find((item)=>item && item.createdAt == obj.createdAt);
        if(flag == undefined){
          arr = [obj,...arr];
          notification.warning({
              message: 'Teleconsultation Call',
              description:
                'You have a new teleconsultation call.',
              onClick: () => {
                // console.log('Notification Clicked!');
                updateEncounterFun(obj)
              },
              icon: <SkypeOutlined/>,
              btn:<Button size="small" onClick={()=>{
                updateEncounterFun(obj)
              }}>Check In</Button>,
              duration:5000,
              style:{cursor:'pointer'}
          })
        }

      }
  }
  setData(arr)
  }, [props.data.loading])

  
//   let arr = [...data];

// if(props.data && props.data.loading == false){
//     let obj = props.data.subscribeToNotification;
//     let flag = data.find((item)=>item.createdAt == obj.createdAt);
//     if(flag == undefined){
//       arr = [obj,...arr];
//       notification.warning({
//           message: 'Teleconsultation Call',
//           description:
//             'You have a new teleconsultation call.',
//           onClick: () => {
//             // console.log('Notification Clicked!');
//             updateEncounterFun(obj)
//           },
//           icon: <SkypeOutlined/>,
//           btn:<Button size="small" onClick={()=>{
//             updateEncounterFun(obj)
//           }}>Check In</Button>,
//           duration:5000,
//           style:{cursor:'pointer'}
//       })
//     }
// }



const updateEncounterFun=(record)=>{
  let variables={
    patientId:record.orgId+'::'+record.patientId,
    encounterId:record.encounterId,
    status:'PENDING'
  }
  updateEncounterMut(props,variables)
  .then((res)=>{
    console.log(res)
    notification.destroy()
    props.history.push('/coversheet', { record: record })
  }).catch((err)=>{
    console.log(err)
  })
}



const getEncIdsMenuVisible=async()=>{
  let pendingIndex = 0;
  let openIndex = 0;
  let arr = [];
  let dd = data;
  dd = dd.slice(0,5);

  
  for(let i=0; i < dd.length; i++){
 
    let item = dd[i];
    if(!item.status){
      let variables={
        patientId: item.patientId,
        encounterId: item.encounterId
      }
  
      let ss = await getEncounterByIdQuery(props,variables);
  
      if(ss.data.getEncounterById != null){
        item['status'] = ss.data.getEncounterById.status;
        if(ss.data.getEncounterById.status == 'PENDING'){
          pendingIndex = pendingIndex + 1;
        }
        if(ss.data.getEncounterById.status == 'OPEN'){
          openIndex = openIndex + 1;
        }
      }

    }
    
    arr.push(item)
  }
  
  setData(arr)
}



const handleVisibleChange=(flag)=>{
  if(flag == true){
    getEncIdsMenuVisible()
  }
}


const content = (
    <Menu >
     <div style={{padding:'5px 10px', minWidth:'300px'}}>
      <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => {
            // console.log(item)
            return (
            <List.Item
            style={item.status != 'CLOSED' ? {cursor:'pointer'} : {}}
            onClick={()=>{
              if(item.status != 'CLOSED'){
                updateEncounterFun(item)
              }
            }}
            actions={[
              <span style={item.status == 'PENDING' ? {fontSize:'12px', color:'red'} : item.status == 'OPEN' ? {fontSize:'12px', color:'#13c713'} : {fontSize:'12px'}}>{item.status}</span>,
            ]}>
              <List.Item.Meta
                avatar={<Avatar icon={<SkypeOutlined/>} />}
                title={'You have a new teleconsultation call.'}
                description={<p>{moment.unix(item.createdAt).fromNow()} </p>}
              />
            </List.Item>
          )}}
        />
        <Link to="/allNotifications">
        <Button type="link" > All Notifications</Button>
        </Link>

     </div>
        </Menu>
     
  );

    return (
        // <Popover content={content} placement="bottom">
        //     <Badge count={data.length} showZero>
        //         <BellOutlined style={{fontSize:'18px'}}/>
        //     </Badge>
        // </Popover>
        <Dropdown overlay={content} onVisibleChange={handleVisibleChange} placement={"bottomLeft"}>
        <Badge count={data.length} showZero>
            <BellOutlined style={{fontSize:'18px'}}/>
        </Badge>
    </Dropdown>
    )

}

const wrapNotificationComp= compose(
    withApollo,
    graphql(subscribeToNotification, {
        options: props => {
            return({
            variables: {
                userId: props.unitId+'::'+props.docId,
            }
        })}
    }),
)(NotificationComp);


export default withRouter(withApollo(wrapNotificationComp));