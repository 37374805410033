

export const SET_LOADING = 'SET_LOADING'
export const SET_DOCID = 'SET_DOCID'
export const SET_DOCINFO = 'SET_DOCINFO'
export const SET_APPT_MONTHLY = 'SET_APPT_MONTHLY'




const MainReducer = (state, action)=>{
    switch (action.type) {
        case SET_LOADING:
            return {...state, loading: action.payload }
        case SET_DOCID:
            return {...state, docId: action.payload }
        case SET_DOCINFO:
            return {...state, docInfo: action.payload }
        case SET_APPT_MONTHLY:
            return {...state, appointmentsMonth: action.payload }
        
        default:
          return state
      }
}

export {
    MainReducer
}