import React, { Component } from 'react';
import { Hub } from "aws-amplify";
import { ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignIn, SignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';
import { Button, Form, Spin, Card, Input, Checkbox } from 'antd';
import AuthLayout from './AuthLayout';
import { ThemeContext } from '../../context/themeContext';

class CustLogin extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.err = '';
  }


  handleLogin = e => {
    Hub.listen("auth", res => {
      const errorMsg = res.payload.data.message ? res.payload.data.message : null;
      // this.setState(prevState => ({...prevState, errMsg: errorMsg}));
      this.err = errorMsg
    })
    this.signIn(e);
  }


  returnUsername = (e) => {
    if (e.target.value.includes('::') == true) {
      return e
    } else {
      if (window) {
        let url = window.location.origin;
        url = url.replace('https://', '');
        url = url.replace('http://', '');
        e.target.value = `${url}::${e.target.value}`;
        // e.target.value = `${e.target.value}`;
        return e
      } else {
        return e
      }
    }
  }

  showComponent(theme) {
    return (
      <ThemeContext.Consumer>
        {({ theme1 }) => {
          return (
            <AuthLayout>
              {/* <Card style={{ width: 600, margin: 'auto' }}> */}
              {this.err ? <p style={{ color: '#e01414', fontSize: '12px' }}>{this.err}</p> : ''}
              <Form autoComplete="false" className="auth-form" style={{ justifyContent: 'space-around', flexDirection: 'column' }}>
                <div>
                  <Input
                    size="large"
                    style={{ color: 'black', borderRadius: '5px' }}
                    name="username"
                    placeholder="UserName"
                    onChange={(e) => {
                      this.setState({
                        username: e.target.value
                      })
                      if (e.target.value) {
                        e.target.value = e.target.value.trim();

                        let event = this.returnUsername(e);

                        this.handleInputChange(event)
                      }
                    }} />
                </div>
                <div>
                  <Input.Password
                    size="large"
                    style={{ color: 'black', borderRadius: '5px', marginTop: '10px' }}
                    name="password"
                    placeholder="Password"
                    onChange={(e) => {
                      if (e.target.value) {

                        e.target.value = e.target.value.trim()
                        let event = e;

                        this.handleInputChange(event)

                        this.handleInputChange(e)
                      }
                      this.setState({
                        password: e.target.value
                      })
                    }} />
                </div>
                <div>
                  <Button
                    size="large"
                    block
                    loading={this.state.loading}
                    block
                    htmlType="submit"
                    style={{ background: theme1.accentColor, color: theme1.color, marginTop: 20, borderRadius: '5px' }}
                    //disabled={this.state.loading&& this.state.error == null}
                    onClick={(e) => {
                      this.handleLogin(e)
                    }}>
                    SIGN IN
                    {/* {this.state.loading&& this.state.error == null ? 
                          <Spin /> :
                      <p style={{color:'#fff'}}>Log In</p>
                          } */}
                  </Button>
                </div>

              </Form>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                {/* <Checkbox onChange={(e)=>{
                           this.setState({
                            rememberMe: e.target.checked
                          })
                          localStorage.setItem('remember',e.target.checked)
                        }}>Remember me</Checkbox> */}
                <Button type="link"
                  onClick={() => { this.changeState('forgotPassword') }}>
                  <p >Forgot Password ? </p>
                </Button>
              </div>
              {/* </Card> */}
            </AuthLayout>
          )
        }}
      </ThemeContext.Consumer>
    )
  }
  // render() {
  //   if (this.props.authState == 'signIn') {

  //     return (
  //       <ThemeContext.Consumer>
  //         {({ theme1 }) => {
  //           return (
  //             <AuthLayout>
  //               {/* <Card style={{ width: 600, margin: 'auto' }}> */}
  //                 <Form autoComplete="false" className="auth-form" style={{ justifyContent: 'space-around', flexDirection: 'column' }}>
  //                   <div>
  //                     <Input
  //                     size="large"
  //                       style={{ color: 'black', borderRadius:'5px' }}
  //                       name="username"
  //                       placeholder="UserName"
  //                       onChange={(e) => {
  //                         if(e.target.value){
  //                           this.setState({
  //                             username: e.target.value.trim()
  //                           })
  //                         e.target.value = e.target.value.trim()
  //                         let event = e;

  //                         this.handleInputChange(event)
  //                         }
  //                       }} />
  //                   </div>
  //                   <div>
  //                     <Input.Password
  //                      size="large"
  //                       style={{ color: 'black', borderRadius:'5px', marginTop:'10px' }}
  //                       name="password"
  //                       placeholder="Password"
  //                       onChange={(e) => {
  //                         if(e.target.value){

  //                           e.target.value = e.target.value.trim()
  //                           let event = e;

  //                           this.handleInputChange(event)

  //                         this.handleInputChange(e)
  //                         this.setState({
  //                           password: e.target.value.trim()
  //                         })
  //                       }
  //                       }} />
  //                   </div>
  //                   <div>
  //                     <Button
  //                      size="large"
  //                      block
  //                      loading={this.state.loading}
  //                       block
  //                       htmlType="submit"
  //                       style={{ background: theme1.accentColor, color: theme1.color,marginTop:20, borderRadius:'5px' }}
  //                       //disabled={this.state.loading&& this.state.error == null}
  //                       onClick={(e) => {
  //                         this.signIn(e)
  //                       }}>
  //                       SIGN IN
  //                     {/* {this.state.loading&& this.state.error == null ? 
  //                     <Spin /> :
  //                 <p style={{color:'#fff'}}>Log In</p>
  //                     } */}
  //                     </Button>
  //                   </div>
  //                 </Form>
  //                 {/* <p style={{color:'green',fontSize:14}}>{this.state.msg}</p> */}
  //                 {/* <p style={{color:'red',marginLeft:10}}>{this.state.error}</p> */}
  //                 <a
  //                   style={{ marginTop: '10px', float:'right' }}
  //                   onClick={() => { this.changeState('forgotPassword') }}>
  //                   <p >Forgot Password ? </p>
  //                 </a>
  //               {/* </Card> */}
  //             </AuthLayout>
  //           )
  //         }}
  //       </ThemeContext.Consumer>
  //     );
  //   } else {
  //     return <span></span>
  //   }
  // }
}


export default CustLogin;