import React, { useState, useContext, useEffect } from 'react';
import { Layout, Menu, Select, Typography, Space, Dropdown, Tooltip } from 'antd';
import { GlobalOutlined, CheckCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import { ThemeContext } from '../../context/themeContext';
import { useTranslation, withTranslation, Trans } from 'react-i18next'
import { AuthContext } from '../../context/authContext';
import { Link } from 'react-router-dom';
import Amplify, { Auth } from "aws-amplify";
import { withApollo } from 'react-apollo';
import { OrgInfoContext } from '../../context/orgInfoContext';
import RenderSelectCascader from './renderDoctorCascader'
import NotificationComp from './notificationComp'
import { returnSentenceCase } from './functions'


const { Option } = Select;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Text } = Typography;

const style = {
  iconStyle: {
    fontWeight: 800,
    fontSize: 20
  }
}

const logout = () => {
  Auth.signOut().then((data) => {
    console.log(data)
    window.location.href = '/'
    // window.location.reload(true)
  });
};


const btnStyle = {
  borderRadius:'50%', 
  background:'#cc4a31', 
  height:'35px', 
  width:'35px', 
  display:'flex', 
  justifyContent:'center', 
  alignContent:'center', 
  alignItems:'center',
  cursor:'pointer'
}

const MainHeader = (props) => {
  const { t, i18n } = useTranslation();
  const currLang = i18n.language
  const handleChange = (lang) => {
    i18n.changeLanguage(lang)
  }
  const context = useContext(OrgInfoContext)
  const authContext = useContext(AuthContext)

  const [collapsed, setCollapsed] = useState(true);
  const [docId, setdocId] = useState(null)
  const [online, setonline] = useState(false)

  useEffect(() => {
    if (context.docInfo != null) {
      setdocId(context.docInfo.email)
    }
  }, [context])

  useEffect(() => {
    setonline(props.online)
  }, [props.online])


  const menu = (
    <Menu>
      {authContext.doctorUnitMapping.map((item)=>{
        if(context.unitData.unitId != item.email){
          return(
            <Menu.Item onClick={()=>{
              context.changeDoctorUnit(item.email)
            }}>{returnSentenceCase(item.email)}</Menu.Item>
          )
        }
      })}
    </Menu>
  );


  return (
    <ThemeContext.Consumer>
      {({ theme1 }) => {
        return (

          <Header className="header"
            style={{
              background: '#ffffff', paddingLeft: 20,
              // boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.05)', 
              zIndex: 1000
            }}
          >

            <Menu mode="horizontal">
              {/* <Menu.Item> 
                <MenuOutlined
                  style={{ color: 'rgba(0,0,0,.65)', fontSize: 15, display: 'inline-block', marginRight: 20 }}
                  onClick={() => {
                    props.setCollapsed(!props.collapsed)
                  }} />
                  </Menu.Item> */}
              {/* <Menu.Item>
                <h2 style={{ color: 'rgba(0,0,0,.65)', marginRight: 20, margin: 0 }}>{props.heading ? props.heading : ''}</h2>
              </Menu.Item> */}
              <Menu.Item>
                {context.isDoctor == false && props.permissions && props.permissions.length > 0 && props.permissions.indexOf('Admin') == -1 &&
                  <RenderSelectCascader />
                }
              </Menu.Item>
              <Menu.Item style={{ float: 'right' }}>
                <GlobalOutlined style={{ marginRight: 0 }} />
                <Select bordered={false} defaultValue={currLang} onChange={(value) => {
                  handleChange(value)
                }}>
                  <Option value="en">EN</Option>
                  <Option value="hi">HI</Option>
                </Select>
              </Menu.Item>

              {/* <Menu.Item style={{float:'right'}}>
                  <Link to='/editProfile'>

              <UserOutlined style={{fontSize:'18px'}}/>
  
              </Link>
                  </Menu.Item> */}

                  <Menu.Item key={'unitMapping'} style={{ float: 'right' }}>
                  {authContext.doctorUnitMapping.length > 1 && 
                    <Dropdown overlay={menu}>
                      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                      <h4 className="theme-color" style={{fontSize:'12px', margin:0}}>{context.unitData.unitName} (Change)</h4>
                      </a>
                    </Dropdown>
                    }
                  </Menu.Item>
              <Menu.Item style={{ float: 'right' }}>
                {docId != null && context.isDoctor == true &&
                  <NotificationComp docId={docId} unitId={authContext.user.unitId} />
                }
              </Menu.Item>
              {/* <Menu.Item style={{ float: 'right' }}>
            
              <Tooltip title={online == true ? 'Go Offline' : "Go Online"}>

              <CheckCircleFilled style={{fontSize:'20px', color: online == true ? '#7fbf2d' : '#cc4a31', cursor:'pointer'}}
              onClick={() => {
                if (online == true) {
                    // disconnectSocket()
                } else {
                    // createSocketConnection()
                }
            }}
              />
              </Tooltip>

              </Menu.Item> */}
            </Menu>
          </Header>

        )
      }}
    </ThemeContext.Consumer>


  );
}

export default withApollo(MainHeader)
