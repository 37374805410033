
import React, { createContext, useReducer, useEffect, useContext, useState } from "react";
import { OrgInfoContext } from '../../../../context/orgInfoContext';
import { withApollo } from 'react-apollo';
import { message } from 'antd'
import { Link, withRouter } from 'react-router-dom';
import { getConsultationServicesQuery, createConsultationServiceQuery, updateConsultationServiceMut } from '../../../common/graphqlFunctions';


export const ADD_SERVICE = 'add_service'
export const INIT_SERVICE = 'init_service'


const initialState = {
  services:[]
};


const addServiceToStore=(state,service)=>{
  let arr = [...state.services];
  let index = arr.findIndex((item)=>item.serviceId == service.serviceId);
  if(index == -1){
    arr.push(service);
  }else{
    arr.splice(index,1,service)
  }
  return {
    services: arr
  }
}


const ServiceReducer = (state, action)=>{
    switch (action.type) {
        case INIT_SERVICE:
          return {services: action.services};
        case ADD_SERVICE:
          return addServiceToStore(state,action.service);
        default:
          return state
      }
}


const ServiceStore = (props) => {
  const [state, dispatch] = useReducer(ServiceReducer, initialState);
  const orgContext = useContext(OrgInfoContext)
  const [docId, setdocId] = useState(null)
  const [loading, setloading] = useState(false)
  const [mutErr, setmutErr] = useState(null)


  useEffect(() => {
    if(orgContext.docInfo !=null){
      setdocId(orgContext.docInfo.email)
    }
  },[orgContext]);



  useEffect(() => {
    if(docId !=null){
      setloading(true)
      const fetchDataAsync = async () => {
        getConsultationServicesQuery(props,{doctorId:docId})
        .then((res)=>{
          setloading(false)
          if (res.data && res.data.getConsultationServices && res.data.getConsultationServices != null) {
            dispatch({
              type: INIT_SERVICE,
              services: res.data.getConsultationServices
            })
          }
        }).catch((err)=>{
          setloading(false)
          console.log(err)
        })
      }
      fetchDataAsync()
    }
  },[docId]);


  const updateService=(values, record)=>{
    setloading(true)
    let variables = {
      serviceId:record.serviceId,
      doctorId: record.doctorId,
      serviceName: values['serviceName'],
      type: values['type'],
      speciality: values['speciality'],
      duration: values['duration'],
      cost: values['cost'],
      colorCode: values.colorCode != null ? values.colorCode : undefined
    }
  
    updateConsultationServiceMut(props,variables).then((data)=>{
      setloading(false)
      setmutErr(null)
      if(data.data.updateConsultationService != null){
        try{
          dispatch({
            type: ADD_SERVICE,
            service: data.data.updateConsultationService
          })
        }catch(err){
          console.log(err)
        }
          message.success('Updated Successfully')
          props.history.push('/manageConsultationServices')
      }
    
    }).catch((err)=>{
      console.log(err)
      setmutErr('There is an error in creating service, please check if consultation service already exists')
      setloading(false)
      message.error('There is some error')
    })
  }


  const createService=(values)=>{
    setloading(true)
    let variables = {
      doctorId: docId,
      serviceName: values['serviceName'],
      type: values['type'],
      speciality: values['speciality'],
      duration: values['duration'],
      cost: values['cost'],
      colorCode: values.colorCode != null ? values.colorCode : undefined
    }
  
    createConsultationServiceQuery(props,variables).then((data)=>{
      setloading(false)
      setmutErr(null)

      if(data.data.createConsultationService != null){
        try{
          dispatch({
            type: ADD_SERVICE,
            service: data.data.createConsultationService
          })
        }catch(err){
          console.log(err)
        }
          props.history.push('/manageConsultationServices')
          message.success('Created Successfully')
      }
    }).catch((err)=>{
      console.log(err)
      setmutErr('There is an error in creating service, please check if consultation service already exists')

      setloading(false)
      message.error('There is some error')
    })
  }

  const callDisptch=(type)=>{
    dispatch(type)
  }

  return (
      <ServiceContext.Provider value={{state, serviceDispatch : callDisptch, loading, createService, updateService, mutErr }}>
          {props.children}
      </ServiceContext.Provider>
  )
};

const ServiceContext = createContext(initialState);

const ServiceStoreW = withRouter(withApollo(ServiceStore))


export {
  ServiceReducer,
  ServiceStoreW,
  ServiceContext
}


