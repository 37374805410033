import moment from 'moment'
export { calculateAgeFun, returnDateWithFormat, removeCharachers, returnSentenceCase, csvJSON }

function csvJSON(csv) {
    const lines = csv.split('\n')
    const result = []
    const headers = lines[0].split(',')

    for (let i = 1; i < lines.length; i++) {
        if (!lines[i])
            continue
        const obj = {}
        const currentline = lines[i].split(',')

        for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j]
        }
        result.push(obj)
    }
    return result
}



const calculateAgeFun = (val, age) => {
    // console.log('val, age',val, age)
    var birthday = (moment(val).format('YYYY-MM-DD'))
    let dob = birthday.split('-')
    var bthDate = new Date(parseInt(dob[0]), parseInt(dob[1]) - 1, parseInt(dob[2])).getTime();
    var curDate = new Date().getTime();
    var days = Math.floor((curDate - bthDate) / (1000 * 60 * 60 * 24));
    var ageYears = Math.floor(days / 365);
    var ageMonths = Math.floor((days % 365) / 31);
    var ageDays = days - (ageYears * 365) - (ageMonths * 31);

    let ageObj = { ...age }
    ageObj.year = ageYears == NaN ? 0 : ageYears;
    ageObj.month = ageMonths == NaN ? 0 : ageMonths;
    ageObj.day = ageDays == NaN ? 0 : ageDays;
    return ageObj
}

const returnDateWithFormat = (date, type) => {
    if (type == 'date') {
        return moment(date).format('YYYY-MMM-DD').toUpperCase()
    }
    if (type == 'month') {
        return moment(date).format('YYYY-MMM').toUpperCase()
    }
    return moment(date).format('YYYY-MMM-DD').toUpperCase()
}


const removeCharachers = (str) => {
    if (str) {
        return str.replace(/_/g, ' ')
    }
}

const returnSentenceCase = (str) => {

    if (str) {
        return str.replace(/-/g, ' ').split(' ').map((item) => { let arr = item.split(''); arr[0] = arr[0].toUpperCase(); return arr.join(''); }).join(' ');
    }
}