import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { configVariables } from '../../../../configs/configs'
import { listDoctorsByOrg } from '../../../../graphql/queries';
import { message } from 'antd'
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { docBasicDetails, docContactDetails } from '../../../../graphql/fragments'

const GetDocByOrgQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query:  gql`query listDoctorsByOrg( $limit: Int $nextToken: String){
        listDoctorsByOrg(limit: $limit nextToken: $nextToken){
            items{
                ...docBasicDetails
                ...docContactDetails
            }
            nextToken
            }
        }

        ${docBasicDetails}
        ${docContactDetails}
        `,
      fetchPolicy:'no-cache'
        
    }).then((data) => {
        res(data)
      }).catch((err) => {
        console.log(err)
        rej(err)
      })
    })
  }
  

  
  const GetDocByOrg = async(props)=>{
    let res = await GetDocByOrgQuery(props)
    // console.log(res)
    if(res.data.listDoctorsByOrg && res.data.listDoctorsByOrg != null){
        return res.data.listDoctorsByOrg.items
    }else{
      return []
    }
  }


  export default GetDocByOrg