import React from 'react';
import { ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignIn, SignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';
import Amplify, { Auth } from "aws-amplify";
import './assets/main.css';
import './assets/react-phone-input-2.css';
import { configVariables } from './configs/configs'
import { CustLogin, CustForgotPassword, CustRequireNewPassword } from './components/authentication'
import { ThemeContext } from './context/themeContext';
// import { InMemoryCache } from 'apollo-cache-inmemory';
import AWSAppSyncClient, { createAppSyncLink, createLinkWithCache } from "aws-appsync";
import { ApolloProvider } from "react-apollo";
import App from './App';
import  AWS  from 'aws-sdk'


var jwtDecode = require('jwt-decode');

Amplify.configure({
  region: configVariables.REGION, 
  userPoolId: configVariables.COGNITO_USERPOOL_ID,
  userPoolWebClientId: configVariables.COGNITO_USERPOOL_WEBCLIENT_ID, 
});


// const cache = new InMemoryCache();

// cache.writeData({
//   data: {
//     todos: [],
//     visibilityFilter: 'SHOW_ALL',
//     networkStatus: {
//       __typename: 'NetworkStatus',
//       isConnected: false,
//     },
//   },
// });


function MainApp() {
  const client = new AWSAppSyncClient({
    url: configVariables.ENDPOINT,
    region: configVariables.REGION,
    auth: {
      type: configVariables.AUTHENTICATION_TYPE,
      // jwtToken: signInUserSession.idToken.jwtToken, 
      jwtToken: async () =>(await Auth.currentSession()).getIdToken().getJwtToken()
      // apiKey: configVariables.API_KEY
    },
    disableOffline: true,
    complexObjectsCredentials: () => {
      return new AWS.Credentials({
        accessKeyId: configVariables.accessKeyId,
        secretAccessKey: configVariables.secretAccessKey
      });
    }
  });
  
  return (

    <ApolloProvider client={client}>
      <App/>
    </ApolloProvider>

  );
}

const MyTheme = {
  toast: {display: 'none'}
}

export default withAuthenticator(MainApp,false,[
  <CustLogin/>,
  <CustForgotPassword/>,
  <CustRequireNewPassword/>
],null,MyTheme);

// export default withAuthenticator(App);
// 
// export default App;
