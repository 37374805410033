import React from 'react'
import { AuthContext } from '../context/authContext';

function WithAuthenticator(WrappedComponent) {

    return class extends React.Component {
      constructor(props) {
        super(props);
      }

      render() {
        return <AuthContext.Consumer>
          {({permissions})=>{
            return <WrappedComponent permissions={permissions} {...this.props} />
          }}
          
          </AuthContext.Consumer>;
      }
    };
  }

  export default WithAuthenticator;