import React from 'react';
import MainLayout from './layout';
import { Result, Button, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';


const { Paragraph, Text } = Typography;


class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    }
  
    render() {
        
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <MainLayout>
            <Result
    status="error"
    title="There is something wrong"
    // subTitle="Please check and modify the following information before resubmitting."
    // extra={[
    //   <Button type="primary" key="console">
    //     Go Console
    //   </Button>,
    //   <Button key="buy">Buy Again</Button>,
    // ]}
  >
    {/* <div className="desc">
      <Paragraph>
        <Text
          strong
          style={{
            fontSize: 16,
          }}
        >
          The content you submitted has the following error:
        </Text>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your account has been frozen
        <a>Thaw immediately &gt;</a>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your account is not yet
        eligible to apply <a>Apply Unlock &gt;</a>
      </Paragraph>
    </div> */}
  </Result>
            </MainLayout>;
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary