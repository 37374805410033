import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Card, Space, Tooltip, Input, Form, Row, Col, notification, List, Avatar, Alert, message } from 'antd';
import { UserOutlined, VideoCameraOutlined, TeamOutlined, VideoCameraFilled, RightCircleOutlined, CommentOutlined, AudioOutlined, AudioMutedOutlined, FullscreenOutlined, PhoneFilled, DragOutlined, MenuFoldOutlined } from "@ant-design/icons";
import socketObjClass from 'video-socket-client';
import IncomingCallGif from '../../../../../assets/sounds/incoming.mp3'
import Icon from '@ant-design/icons';
import { AuthContext } from '../../../../../context/authContext';

let audioUrl = "../../../../../assets/sounds/incoming.mp3";

let socketObj = new socketObjClass()


function dragElement(elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById("holder")) {
        // if present, the header is where you move the DIV from:
        document.getElementById("holder").onmousedown = dragMouseDown;
    } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }

    function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
    }
}


const btnStyle = {
    borderRadius: '50%',
    background: '#cc4a31',
    height: '35px',
    width: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
}

// Media contrains
const constraints = {
    video: true,
    // Uncomment to enable audio
    audio: true,
};

const WebRTC_POC = (props) => {
    let context = useContext(AuthContext);
    let remoteVideoref = useRef();

    useEffect(() => {
        if (document.getElementById("dragged")) {
            dragElement(document.getElementById("dragged"));
        }
    })

    const [socket, setsocket] = useState(socketObj)
    const [online, setonline] = useState(false)
    const [activeUsers, setactiveUsers] = useState([])
    const [conversation, setconversation] = useState([
        {
            userId: '01',
            owner: 'self',
            payload: 'hi , how r u ? not able to hear you'
        },
        {
            userId: '01',
            owner: 'remote',
            payload: 'abc'
        }
    ])
    const [callStarted, setcallStarted] = useState(false)
    const [chatCollapsed, setchatCollapsed] = useState(true)
    const [activeUsersCollapsed, setactiveUsersCollapsed] = useState(true)
    const [audioMuted, setaudioMuted] = useState(true)
    const [disableVideo, setdisableVideo] = useState(true)
    const [callStatus, setcallStatus] = useState('Not_Started');
    const [callerId, setcallerId] = useState(null);
    const [groupId, setgroupId] = useState(null);


    useEffect(() => {
        if(context.data){
            setcallerId(context.data.email)
            setgroupId(context.data.orgId)
        }
      },[context.data])
    

    var receiveBroadcast = (data) => {
        // console.log('broadcasted message', data)
        // let convArr = [...conversation];
        // data.owner = 'broadcast'
        // convArr.push(data)
        // setconversation(convArr)
    }

    var receiveMessage = (data) => {
        // console.log('broadcasted message', data)
        let convArr = [...conversation];
        data.owner = 'broadcast'
        convArr.push(data)
        setconversation(convArr)
    }

    var getActiveUsers = (data) => {
        setactiveUsers(data)
    }

    var playAudio=()=>{
        var audio = document.createElement('audio');
        audio.src = audioUrl;
        audio.style.display = "none"; //added to fix ios issue
        audio.autoplay = false; //avoid the user has not interacted with your page issue
        audio.onended = function(){
          audio.remove(); //remove after playing to clean the Dom
        };
        let doc = `<audio>
        <source src="${audioUrl}" type="audio/mpeg">
        Your browser does not support the audio tag.
      </audio>`
        document.body.innerHTML = doc;
      }

    var receiveOffer = () => {
        setcallStarted(true)
        notification.open({
            message: 'You have received a new call',
            duration: 2000,
            description: <Space>
                {/* <Button><img src={IncomingCallGif} style={{height:'120px', width:'auto'}}/></Button> */}
                {/* <Button style={{ ...btnStyle, background: '#40c0d4' }} icon={<VideoCameraFilled style={{ color: 'white', fontSize: '15px' }} />}></Button> */}
                <Button style={{ ...btnStyle, background: '#40c0d4' }} icon={<PhoneFilled style={{ color: 'white' }} />} onClick={() => {
                    socketObj.answerCall()
                    updateCallStatus('call_accepted')
                }}></Button>
                <Button
                    style={{ ...btnStyle }}
                    onClick={()=>{
                        socketObj.declineCall()
                        updateCallStatus('call_declined')
                    }}><PhoneFilled style={{ color: 'white', transform: 'rotate(227deg)' }} /></Button>

            </Space>,
            onClick: () => {
                notification.destroy()
                console.log('Notification Clicked!');
            },
        });

        // let tt = setTimeout(() => {
        //     console.log(callStatus)
        //     if(callStatus == 'Not_Started'){
        //         notification.destroy();
        //         socketObj.declineCall();
        //         clearTimeout(tt)
        //         updateCallStatus('Not_Started')
        //     }
        // }, 10000);
    }

    var receiveRemoteTrack = (stream) => {
        // console.log('remote stream received', stream)
        let container = document.getElementById('remoteVideoContainer');

        let childContainer = document.getElementById('self-stream-container');

        let childrens = container.getElementsByTagName('video');

        if (!childrens || Object.keys(childrens).length == 0) {

            let video = document.createElement('video');
            video.setAttribute('class', 'videoPlayer');
            video.setAttribute('id', `video-${stream.id}`);
            video.setAttribute('autoplay', true);
            if (!document.getElementById(`video-${stream.id}`)) {
                container.appendChild(video);
            }


            let vid = document.getElementById(`video-${stream.id}`);
            vid.srcObject = stream;
        } else {
            let video = document.createElement('video');
            video.setAttribute('class', 'videoPlayer1');
            video.setAttribute('id', `video-${stream.id}`);
            video.setAttribute('autoplay', true);
            if (!document.getElementById(`video-${stream.id}`)) {
                childContainer.appendChild(video);
            }
            // childContainer.appendChild(video);
            let vid = document.getElementById(`video-${stream.id}`);
            vid.srcObject = stream;
        }

    }

    var receiveGroupCallRequest1 = () => {
        console.log('client 1, you have recieved a group call request')
        if (true == true) {
            socketObj.joinGroupCall()
        }
    }

    var updateCallStatus=(status)=>{
        setcallStatus(status)
        console.log(status)
        if(status == 'call_disconnected'){
            message.warning('Call disconnected')
        }
        if(status == 'call_declined'){
            message.warning('Call declined')
        }
    }

    socketObj.receiveBroadcast = receiveBroadcast;
    socketObj.getActiveUsers = getActiveUsers;
    socketObj.receiveOffer = receiveOffer;
    socketObj.receiveRemoteTrack = receiveRemoteTrack;
    socketObj.receiveGroupCallRequest = receiveGroupCallRequest1;
    socketObj.receiveMessage = receiveMessage;
    socketObj.updateCallStatus = updateCallStatus;


  

    useEffect(() => {
        let session = localStorage.getItem('session');
        if (session && session == 'online' && callerId != null) {
                createSocketConnection()
        }
    }, [callerId])


    const createSocketConnection = () => {

        socketObj.connect({ url: 'https://video.cliniq.app/video', id: callerId, groupId: groupId, userId: callerId })
            .then((res) => {
                setsocket(socketObj)
                setonline(socketObj.connected)
                localStorage.setItem('session', 'online')
            })
            .catch((err) => {
                console.log('err', err)
            })

    }


    const disconnectSocket = () => {
        console.log('in disconnect',callerId, groupId)
        socketObj.disconnect({userId:callerId, groupId:groupId})
            .then((res) => {
                setsocket(socketObj)
                setonline(socketObj.connected)
                localStorage.setItem('session', 'offline')
            })
            .catch((err) => {
                console.log('err')
            })
    }


    const renderCallActionButtons = () => {
        return (
            <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'space-around', alignItems: 'center', alignContent: 'center', padding: '10px' }}>
                {/* <Button style={{ ...btnStyle, background: '#40c0d4' }} icon={<PhoneFilled style={{ color: 'white' }} />}
                    onClick={() => {
                        let elem = document.getElementById('self')

                        if (elem) {
                            socketObj.acceptOffer(elem)

                        }
                    }}></Button> */}

                <Button style={{ ...btnStyle, background: disableVideo == true ? '#40c0d4' : 'red' }}
                    icon={<VideoCameraFilled style={{ color: 'white', fontSize: '15px' }} />}
                    onClick={() => {
                        let flag = socketObj.disableVideo(!disableVideo)

                        setdisableVideo(flag)
                    }}></Button>

                <Button style={{ ...btnStyle, background: '#40c0d4' }}
                    icon={audioMuted == true ? <AudioOutlined style={{ color: 'white' }} /> : <AudioMutedOutlined style={{ color: 'white' }} />}
                    onClick={() => {

                        let flag = socketObj.muteCall(!audioMuted)
                        setaudioMuted(flag)


                    }}></Button>
                <Button
                    style={{ ...btnStyle }}><PhoneFilled style={{ color: 'white', transform: 'rotate(227deg)' }}
                        onClick={() => {
                            socketObj.disconnectCall({ userId: 'atm1@gmail.com', type: 'answerer' })
                            updateCallStatus('call_disconnected')
                        }} /></Button>

                {/* <Tooltip title={online == true ? 'Go Offline' : "Go Online"}>
                <div
                    style={{
                        ...btnStyle,
                        background: online == true ? '#7fbf2d' : '#cc4a31'
                    }}
                    onClick={() =
                        if (online == true) {
                            disconnectSocket()
                        } else {
                            createSocketConnection()
                        }
                    }}
                >{online == true && <CheckCircleOutlined style={{ color: "#fff" }} />}</div>

            </Tooltip> */}

            </div>
        )
    }


    return (
        <div>
          
            <div style={{ position: 'fixed', right: '30px', top: '20px', zIndex: 1000 }}>
            {/* <Button onClick={()=>{
                playAudio()
            }}>Play</Button> */}
                <Tooltip title={online == true ? 'Go Offline' : "Go Online"}>
                    <div
                        style={{
                            ...btnStyle,
                            background: online == true ? '#7fbf2d' : '#cc4a31',
                            height: '20px',
                            width: '20px'
                        }}
                        onClick={() => {
                            if (online == true) {
                                disconnectSocket()
                            } else {
                        createSocketConnection()
                            }
                        }}
                    ></div>

                </Tooltip>
                {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {activeUsers.map((a) => {
                        return (
                            <button
                                onClick={() => {
                                    let elem = document.getElementById('self')
                                    if (elem) {
                                        socketObj.createCall(callerId, elem, constraints, a)

                                    }
                                }}>{a.userId}</button>
                        )
                    })}
                </div> */}
            </div>
            {callStatus == 'call_accepted' &&
                <div id="dragged">
                    <div className="toolbar-video-call" style={{ position: 'absolute', right: 0, top: 0, zIndex: 1000, padding: '5px' }}>
                        <Tooltip title="Drag">
                            <Button type="link" id="holder" icon={<DragOutlined style={{ fontSize: '18px' }} />}></Button>

                        </Tooltip>
                        {/* <Tooltip title="Full Screen">

                            <Button type="link"><FullscreenOutlined style={{ fontSize: '18px' }} /></Button>
                        </Tooltip> */}

                        {/* <Tooltip title="Toggle Chat">
                            <Button
                                onClick={() => {
                                    setchatCollapsed(!chatCollapsed)
                                    setactiveUsersCollapsed(true)
                                }}
                                type="link" icon={<CommentOutlined style={{ fontSize: '18px' }} />}
                            ></Button>

                        </Tooltip>
                        <Tooltip title="Active Users">
                            <Button
                                onClick={() => {
                                    setactiveUsersCollapsed(!activeUsersCollapsed)
                                    setchatCollapsed(true)
                                }}
                                type="link" icon={<TeamOutlined style={{ fontSize: '18px' }} />}
                            ></Button>

                        </Tooltip> */}
                    </div>
                    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                        <div id="remoteVideoContainer" >
                            {/* <video autoPlay id="received" style={{ height: '100%', width: '100%' }}></video> */}


                            {renderCallActionButtons()}
                        </div>
                        <div id="self-stream-container" >
                            <video autoPlay id="self" style={{ height: '100%', width: 'auto', margin: '5px' }}></video>
                        </div>
                    </div>
                    <div className="chat-window" style={{ width: chatCollapsed == true ? '0px' : '50%', opacity: chatCollapsed == true ? 0 : 1 }}>
                        <Row >
                            {conversation.map((a) => {
                                if (a.owner == 'self') {
                                    return (
                                        <Col span={14} offset={10} style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
                                            <div className="chat-self" style={{ textAlign: 'right', background: '#e0e0e0', padding: '5px 10px', width: 'max-content', marginRight: '5px', borderRadius: '10px' }}>
                                                <span style={{ fontSize: '10px', marginRight: '10px', color: 'grey' }}>{a.userId}</span>
                                                <span style={{ color: 'grey' }}>{a.payload}</span>

                                            </div>
                                        </Col>
                                    )
                                } else {
                                    return (
                                        <Col span={14} style={{ marginBottom: '5px' }}>
                                            <div style={{ background: '#40c0d4', padding: '5px 10px', width: 'max-content', marginLeft: '5px', borderRadius: '10px' }}>
                                                <span style={{ fontSize: '10px', marginRight: '10px', color: '#fff' }}>{a.userId}</span>
                                                <span style={{ color: '#fff' }}>{a.payload}</span>

                                            </div>
                                        </Col>
                                    )
                                }
                            })}

                        </Row>
                        <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, }}>

                            <Form
                                style={{ width: '100%' }}
                                onFinish={(values) => {
                                    let msg = values['message']
                                    if (msg) {
                                        let obj = { userd: callerId, msg: msg }
                                        socketObj.sendMessage(obj)
                                        let arr = [...conversation]
                                        obj.owner = 'self'
                                        arr.push(obj)
                                        setconversation(arr)


                                    }
                                }}>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <Form.Item style={{ marginBottom: '0px', flex: 1 }} name="message">
                                        <Input placeholder={'Type a message'} id="msg" />
                                    </Form.Item>
                                    <Button htmlType="submit" type="primary"
                                        icon={<RightCircleOutlined />}
                                    ></Button>

                                </div>
                            </Form>

                        </div>

                    </div>
                    <div className="active-users-window" style={{ width: activeUsersCollapsed == true ? '0px' : '50%', opacity: activeUsersCollapsed == true ? 0 : 1 }}>
                        <List
                            size="small"
                            itemLayout="horizontal"
                            dataSource={activeUsers}
                            renderItem={a => (
                                <List.Item onClick={() => {
                                    let receiverObj = a;
                                    let offererObj = {
                                        userId: callerId,
                                        groupId: 'org2'
                                    }
                                    socketObj.createCall(constraints, receiverObj, offererObj)
                                }}>
                                    <List.Item.Meta
                                        avatar={<Avatar size="small" icon={<UserOutlined />} style={{ backgroundColor: '#87d068' }} />}
                                        title={a.userId}
                                    // description={a.groupId}
                                    />
                                </List.Item>
                            )}
                        />
                        {/* <Row >
                        {activeUsers.map((a) => {
                        return (
                            <Col span={24}>
                            <div
                            style={{padding:'5px', width:'100%', border:'1px solid grey', cursor:'pointer'}}
                                onClick={() => {
                                    let receiverObj = a;
                                    let offererObj = {
                                        userId : callerId,
                                        groupId:'org2'
                                    }
                                        socketObj.createCall(constraints, receiverObj, offererObj)

                                    
                                }}>{a.userId} - {a.groupId}</div>
                            </Col>
                        )
                    })}

                        </Row> */}


                    </div>

                    {/* <div style={{ flex: 1, width: '100%', padding: '10px' }}>
                        <div >
                            <Row >
                                {conversation.map((a) => {
                                    if (a.owner == 'self') {
                                        return (
                                            <Col span={14} offset={10} style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
                                                <div style={{ textAlign: 'right', background: '#fff', padding: '5px', width: 'max-content' }}>
                                                    <span style={{ fontSize: '10px', marginRight: '10px', color: 'grey' }}>{a.userId}</span>
                                                    <span>{a.payload}</span>

                                                </div>
                                            </Col>
                                        )
                                    } else {
                                        return (
                                            <Col span={14} style={{ marginBottom: '5px' }}>
                                                <div style={{ background: '#fff', padding: '5px', width: 'max-content' }}>
                                                    <span style={{ fontSize: '10px', marginRight: '10px', color: 'grey' }}>{a.userId}</span>
                                                    <span>{a.payload}</span>

                                                </div>
                                            </Col>
                                        )
                                    }
                                })}

                            </Row>

                        </div> */}
                    {/* <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, }}>

                            <Form
                                style={{ width: '100%' }}
                                onFinish={(values) => {
                                    console.log(values)

                                    let msg = values['message']
                                    if (msg) {
                                        socketObj.sendMessage({ id: callerId, payload: msg })
                                            .then((data) => {
                                                let arr = [...conversation]
                                                data.owner = 'self'
                                                arr.push(data)
                                                setconversation(arr)
                                            })
                                            .catch((err) => {
                                                console.log('there is some error in sending message')
                                            })

                                    }
                                }}>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <Form.Item style={{ marginBottom: '0px', flex: 1 }} name="message">
                                        <Input placeholder={'Type a message'} id="msg" />
                                    </Form.Item>
                                    <Button htmlType="submit" type="primary"
                                        icon={<RightCircleOutlined />}
                                    ></Button>

                                </div>
                            </Form>



                        </div> */}
                </div>


            }
        </div>
    )


    return null
}

export default WebRTC_POC;