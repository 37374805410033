export const SET_COMPLAINTS_LIST = 'SET_COMPLAINTS_LIST'
export const SET_DIAGNOSIS_LIST = 'SET_DIAGNOSIS_LIST'
export const SET_MEDICATION_LIST = 'SET_MEDICATION_LIST'




export const SecondReducer = (state, action)=>{
    switch (action.type) {
        case SET_COMPLAINTS_LIST:
          return {...state, complaintsList : action.payload };
        case SET_DIAGNOSIS_LIST:
            return {...state, diagnosisList: action.payload }
        case SET_MEDICATION_LIST:
            return {...state, medicationList: action.payload }
        default:
          return state
      }
}