

export const INCREMENT = 'increment'
export const UPDATE_FORM = 'UPDATE_FORM'
export const UPDATE_FLAG = 'UPDATE_FLAG'
export const SET_SELECTED_MENU = 'SET_SELECTED_MENU'
export const UPDATE_PATIENT_DATA = 'UPDATE_PATIENT_DATA'
export const UPDATE_APPT_RECORD = 'UPDATE_APPT_RECORD'
export const UPDATE_DOC_DATA = 'UPDATE_DOC_DATA'
export const UPDATE_ENC_ID = 'UPDATE_ENC_ID'
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB'
export const SET_LOADING = 'SET_LOADING'
export const SET_CONSULT_TYPE = 'SET_CONSULT_TYPE'




const returnState=(state,action)=>{
    let ff = [...state.fields];
    ff.splice(1, 1, "test results");

    return {...state, consultType: action.payload, fields: ff }
}

const ContextReducer = (state, action)=>{
    switch (action.type) {
        case INCREMENT:
          return {count: state.count+1};
        case UPDATE_FORM:
            return {...state, form : action.payload }
        case UPDATE_FLAG:
            return {...state, flag: !state.flag }
        case SET_SELECTED_MENU:
            return {...state, selectedMenu: action.payload }
        case UPDATE_PATIENT_DATA:
            return {...state, patientData: action.payload }
        case UPDATE_DOC_DATA:
            return {...state, doctorData: action.payload }
        case UPDATE_APPT_RECORD:
            return {...state, record: action.payload }
        case UPDATE_ENC_ID:
            return {...state, encounterId: action.payload }
        case SET_SELECTED_TAB:
            return {...state, selected: action.payload }
        case SET_LOADING:
            return {...state, loading: action.payload }
        case SET_CONSULT_TYPE:
            return returnState(state, action)
        default:
          return state
      }
}

export {
    ContextReducer
}