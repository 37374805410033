import React, { useState, useContext } from 'react';

import { useTranslation, withTranslation, Trans } from 'react-i18next'

import { Translation } from 'react-i18next';


let layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  let horiLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };


  const TranslationComp = (props)=>{
    const { t } = useTranslation();
    // return t(name)

    // return(
    //   <p>{t(name)}</p>
    // )
    return(<Translation>
      {
        (t, { i18n }) => <span className="custLabel">{t(props.name)}</span>
      }
    </Translation>)
  }

  export { layout, horiLayout, TranslationComp }