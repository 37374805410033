import React, { useState, useContext, useEffect } from 'react';
import { Layout, Menu, Breadcrumb, Button, Select, Dropdown, Card, Avatar, Typography } from 'antd';
import { UserOutlined, CheckCircleOutlined, MenuOutlined, UsergroupAddOutlined, LaptopOutlined, NotificationOutlined, OrderedListOutlined, GlobalOutlined, DownOutlined } from '@ant-design/icons';
// import { UserOutlined, CheckCircleOutlined } from '@ant-design/icons';
import MainSidebar from './sidebar';
// import MainBraidcrumb from './../breadcrumb';
import MainMenu from './menu';
import { ThemeContext } from '../../context/themeContext';
import logo from '../../assets/CHT_logo_color.png'
import GetDocByOrg from '../admins/doctorAdmin/manageServices/funGetDocByOrg'
import SideMenu from '../commonImports/sidemenu'
import { useTranslation, withTranslation, Trans } from 'react-i18next'
import { AuthContext } from '../../context/authContext';
import { Link } from 'react-router-dom';
import MainHeader from './header';
import { OrgInfoContext } from '../../context/orgInfoContext';
import { withApollo } from 'react-apollo';
import VideoCall from '../admins/doctorAdmin/coverSheet/videCall'


const { Option } = Select;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Text } = Typography;


const MainLayout = (props) => {
  const { t, i18n } = useTranslation();
  const currLang = i18n.language;
  const handleChange = (lang) => {
    i18n.changeLanguage(lang)
  }

  const context = useContext(AuthContext)

  const [docList, setdocList] = useState([])
  const [docInfo, setDocInfo] = useState(null)
  const [collapsed, setCollapsed] = useState(true);


  let permissions = context.permissions;

  var menuS = SideMenu(permissions)

  // useEffect(() => {
  //   if(context.isDoctor == true && context.data != null){
  //     if(setDocInfo){
  //       setDocInfo(context.data)
  //     }
  //     let arr = [];
  //     arr.push(context.data);
  //     if(setdocList){
  //       setdocList(arr)
  //     }
  //   }
  // },[context]);


  // useEffect(() => {
  //   const fetchDataAsync = async () => {
  //    let res = await GetDocByOrg(props);
  //   //  console.log(res)
  //    if (res) {
  //      if(setdocList){
  //        setdocList(res)
  //      }
  //      if(res.length > 0 && setdocList){
  //       setDocInfo(res[0])
  //      }
  //    }
  //  }
  // //  if(context.isDoctor == false){
  //  if(context.permissions.indexOf('UnitAdmin') != -1){
  //     fetchDataAsync()
  //  }
  //  },[docInfo]);


  const ProfileCard = (
    <Card style={{ padding: 20 }} bodyStyle={{ textAlign: 'center' }}>
      <Avatar size="large" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
      <h3>Doctor Name</h3>
      <Text disabled>Profile Information</Text>
    </Card>
  );


  let height = '900px';

  if (window) {
    height = window.screen.height - 200
  }


  return (
    // <OrgInfoContext.Provider value={{docInfo:docInfo, docList:docList}}>

    <ThemeContext.Consumer>
      {({ theme1 }) => {
        return (
          <Layout>

            <MainSidebar
              theme1={theme1}
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              menuS={menuS}
            />

            <Layout>
              <MainHeader
                setdocList={setdocList}
                setDocInfo={setDocInfo}
                heading={props.heading}
                setCollapsed={setCollapsed}
                collapsed={collapsed}
                permissions={permissions} />

              <Layout style={{ padding: '20px' }}>
                <Content
                  style={{
                    padding: '0px',
                    margin: 0,
                    minHeight: height,
                  }}
                >

                  {props.children}
                </Content>
              </Layout>
            </Layout>
          </Layout>
        )
      }}
    </ThemeContext.Consumer>
    // </OrgInfoContext.Provider>


  );
}

export default withApollo(MainLayout)
