
export const UPDATE_LIMIT ='UPDATE_LIMIT'
export const UPDATE_FORM = 'UPDATE_FORM'
export const SET_LOADING = 'SET_LOADING'
export const SET_GLOBALCOMPLAINTS_LIST = 'SET_GLOBALCOMPLAINTS_LIST'
export const SET_GLOBALDIAGNOSIS_LIST = 'SET_GLOBALDIAGNOSIS_LIST'
export const SET_GLOBALMEDICATION_LIST = 'SET_GLOBALMEDICATION_LIST'
export const SET_GLOBALADVICE_LIST = 'SET_GLOBALADVICE_LIST'
export const SET_GLOBALALLERGY_LIST = 'SET_GLOBALALLERGY_LIST'
export const SET_GLOBALHABIT_LIST = 'SET_GLOBALHABIT_LIST'
export const SET_GLOBALPHYSICALEXAMINATION_LIST = 'SET_GLOBALPHYSICALEXAMINATION_LIST'

const ContextReducer = (state, action)=>{
    switch (action.type) {
        case UPDATE_LIMIT:
            return {...state, limit : action.payload }
        case UPDATE_FORM:
            return {...state, form : action.payload }
        case SET_GLOBALCOMPLAINTS_LIST:
            return {...state, globalComplaintsList : action.payload };
        case SET_GLOBALDIAGNOSIS_LIST:
            return {...state, globalDiagnosisList: action.payload }
        case SET_GLOBALMEDICATION_LIST:
            return {...state, globalMedicationList: action.payload }
        case SET_GLOBALADVICE_LIST:
            return {...state, globalAdviceList : action.payload };
        case SET_GLOBALALLERGY_LIST:
            return {...state, globalAllergyList : action.payload };
        case SET_GLOBALHABIT_LIST:
            return {...state, globalHabitList : action.payload };
        case SET_GLOBALPHYSICALEXAMINATION_LIST:
            return {...state, globalPhysicalExaminationList : action.payload };
        case SET_LOADING:
            return {...state, loading: action.payload }
        
        default:
          return state
      }
}

export {
    ContextReducer
}