import React, { Fragment, Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  HashRouter
} from "react-router-dom";
import ErrorComp from "../../common/errorComp";
// import HomePage from './homePage';
import MainLayout from "../../common/layout";
import WithAuthenticator from "../../withAuthenticator";
import { ServiceStoreW } from '../doctorAdmin/manageServices/context-reducer';
import { MainCasesheetWrapper } from '../doctorAdmin/coverSheet/context-reducer/mainCasesheetWrapper'
import { MainCaseSheetConfigurationWrapper } from '../doctorAdmin/CaseSheetConfig/context-reducer/mainContextReducer'
import { MainMasterWrapper } from '../doctorAdmin/masterManagement/context-reducer/mainMasterwrapper'
import { MainStoreWrapper } from '../../stores/mainStore'

function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}


const HomePage = lazy(() => retry(()=> import("./homePageComp/homePage")));
const ManageDevices = lazy(() => retry(()=> 
  import("../doctorAdmin/devices/manageDevices")
));
const EditProfile = lazy(() => retry(() =>import("../doctorAdmin/profile/editProfile")));

const ScheduleList = lazy(()  => retry(() => import("./doctor/schedule/scheduleList")));
// const DeleteSchedule = lazy(() => import('./doctor/deleteSchedule'));

const CreateConsultationService = lazy(()  => retry(() =>
  import("./manageServices/createConsultationService")
));
const UpdateConsultationService = lazy(()  => retry(() =>
  import("./manageServices/updateConsultationService")
));
const ManageSevices = lazy(()  => retry(() =>
  import("./manageServices/manageConsultServices")
));

const EmployeeRegistration = lazy(()  => retry(() => import("./employee/empRegistration")));

// const DoctorSchedule = lazy(() => import('./doctor/createSchedule'));
// const DoctorList = lazy(() => import('./doctor/doctorList'));
const DoctorRegistration = lazy(()  => retry(() => import("./doctor/doctorRegistration")));
const UpdateDoctor = lazy(()  => retry(() => import("./doctor/updateDoctor")));
// const CoverSheet4 = lazy(() => import("./coverSheet/coverSheet4"));
const Casesheet = lazy(()  => retry(() => import("./coverSheet/mainCasesheet")));


const UserManagementDashboard = lazy(()  => retry(() =>
  import("./manageUsers/userManagement")
));
const AtmOperatorRegistration = lazy(()  => retry(() =>
  import("./manageUsers/atmOperatorRegi")
));

const TestConfig = lazy(()  => retry(() => import("./devices/serviceDahsboardComp")));
const MasterDashboard = lazy(()  => retry(() =>
  import("../doctorAdmin/masterManagement/dashboard")
));
const UnitConfig = lazy(()  => retry(() =>
  import("../doctorAdmin/masterManagement/unitConfig")
));

const PatientRegistration2 = lazy(()  => retry(() =>
  import("./patient/patientRegistration2")
));
const EditPatientInformation = lazy(()  => retry(() => import("./patient/editPatient")));
const PatientList = lazy(()  => retry(() => import("./patient/patientList")));
const QuickPatientRegistration = lazy(() => retry(()  =>
  import("./patient/quickPatientRegistration")
));

const CreateAppointment = lazy(()  => retry(() =>
  import("./appointments/createAppointment")
));
const AppointmentsList = lazy(()  => retry(() => import("./appointments/appointmentsList")));

// const OrgRegistration = lazy(()=> import("./orgRegistration")) ;
const UnitRegistrationForm = lazy(()  => retry(() => import("./units/unitRegistration")));
const ManageUnits = lazy(()  => retry(() => import("./units/manageUnits")));
const EditUnit = lazy(()  => retry(() => import("./units/editUnitInfo")));

// const CreateSectionComp = lazy(()=> import("./masterManagement/templateMaster/createSection")) ;
// const ManageSection = lazy(()=> import("./masterManagement/templateMaster/manageSections")) ;
// const CreateTemplateComp = lazy(()=> import("./masterManagement/templateMaster/formNew/createTemplate")) ;
// const ManageTemplateComp = lazy(()=> import("./masterManagement/templateMaster/manageTemplates")) ;
// const CreateQuestionComp = lazy(()=> import("./masterManagement/templateMaster/createQuestions")) ;
// const SaveForm = lazy(()=> import("./masterManagement/templateMaster/saveForm")) ;
// const SaveForm2 = lazy(()=> import("./masterManagement/templateMaster/saveForm2")) ;

const RefDoctorList = lazy(()  => retry(() => import("./refDoctor/refDocterList")));
const AddReferralDoctor = lazy(()  => retry(() => import("./refDoctor/addReferralDoctor")))
const EditReferralDoctor = lazy(() => retry(() => import('./refDoctor/editRefDoc')));
const ReasonMasterList = lazy(() => retry(() => import("./masterManagement/reasonMaster/reasonMasterList")));
const AddReasonMaster = lazy(() => retry(() => import("./masterManagement/reasonMaster/addReasonMaster")));
const EditReasonMaster = lazy(() => retry(() => import('./masterManagement/reasonMaster/editReasonMaster')));
const LabRegistrationMaster = lazy(() => retry(() => import('./masterManagement/LabRegistrationmaster/labRegmasterList')));
const AddLabRegistration = lazy(() => retry(() => import('./masterManagement/LabRegistrationmaster/addlabRegistration')));
const EditLabRegistration = lazy(() => retry(() => import('./masterManagement/LabRegistrationmaster/editlabRegistration')));
const InventoryMaster = lazy(() => retry(() => import('./masterManagement/inventoryMaster/inventoryList')));
const ROAMasterList = lazy(() => retry(() => import('./masterManagement/roaMaster/roaMasterList')));
const WaiveOffReasonMasterList = lazy(() => retry(() => import('./masterManagement/WaiveOffReasonMaster/WaiveOffReasonMasterlist')));
const NHSMaster = lazy(() => retry(() => import('./masterManagement/NHSMaster/nhsMasterList')));
const AddNHS = lazy(() => retry(() => import('./masterManagement/NHSMaster/addNHS')));
const SpecialityMasterList = lazy(() => retry(() => import('./masterManagement/specialityMaster/specialityList')));
const ComplaintConfiguration = lazy(() => retry(() => import('./CaseSheetConfig/complaintConfiguration')));
const PhysicalExmConfiguration = lazy(() => retry(() => import('./CaseSheetConfig/physicalExaminationConfiguration')));
const DiagnosisSystemConfiguration = lazy(() => retry(() => import('./CaseSheetConfig/diagnosisConfiguration')));
const ObservationConfiguration = lazy(() => retry(() => import('./CaseSheetConfig/observationConfiguration')));
const InstructionConfiguration = lazy(() => retry(() => import('./CaseSheetConfig/InstructionConfiguration')));
const AdviceConfiguration = lazy(() => retry(() => import('./CaseSheetConfig/adviceConfiguration')));
const PharamacyMasterList = lazy(() => retry(() => import('./masterManagement/PharamacyMaster/pharamacyList')));
const VitalTypeList = lazy(() => retry(() => import('./masterManagement/vitalMaster/vitalTypeList')));
const DocumentTypeMasterList = lazy(() => retry(() => import('./masterManagement/DocumentTypeMaster/documentTypeList')));
const NotificationPage = lazy(() => retry(() => import('../../common/notificationPage')));

const MedicationMaster = lazy(() => retry(() => import('./masterManagement/Medicationmaster/medicationList')));
const ComplaintsMaster = lazy(() => retry(() => import('./masterManagement/complaintsMaster/complaintsList')));


const DiagnosisMaster = lazy(() => retry(() => import('./masterManagement/DiagnosisMaster/diagnosisList')));
const EditAtmOperatorRegistration = lazy(() => retry(() => import('./manageUsers/editatmOperatorRegi')));
const OPBilling = lazy(() => retry(() => import('./Billing/opBilling')));
const BillDeatails = lazy(() => retry(() => import('./Billing/billDetails')));
const BillView = lazy(() => retry(() => import('./Billing/billView')));
const OrderSet = lazy(() => retry(() => import('../doctorAdmin/coverSheet/activityBar/orderSet')));
const AllergyMasterList =lazy(() => retry(() => import('./masterManagement/AllergyMatser/allergyList')));
const HabitMasterList =lazy(() => retry(() => import('./masterManagement/HabitMaster/habitMsterList')));

const ManageInventory =lazy(() => retry(() => import("./inventory/manageInventory.js")));
const SearchDevice =lazy(() => retry(() => import("./inventory/searchDevice.js")));
const ManageConfigurations =lazy(() => retry(() => import("./inventory/manageConfigurations.js")));
const ManageReports = lazy(()=> retry(() => import('../doctorAdmin/reports/manageReports')));

const AdviceMasterList=lazy(()=>retry(() => import('./masterManagement/AdviceMaster/adviceList')));
const PhysicalExaminationMaster =lazy(()=> retry(() => import('./masterManagement/examinationMaster')));



// const SubSpecialityMasterList

const Routes = (props) => {

  return (
    <Router >
      <ErrorComp>

        <Suspense fallback={<MainLayout heading="PMS"><div>Loading...</div></MainLayout>}>
          <Switch>
            <Route exact path="/" render={() => {
              // if(props.permissions.indexOf('OrgAdmin') != -1){
              //     return(<ManageUnits/>)
              // }else{
              return (<HomePage />)
              // }
            }} />

            {/* <Route exact path="/createOrganization" render={()=>(<OrgRegistration/>)}/> */}

            {/* <Route exact path="/createUnit" render={()=>(<UnitRegistrationForm/>)}/> */}
            <Route exact path="/manageUnits" render={() => (<ManageUnits />)} />
            <Route exact path="/editUnit" render={() => (<EditUnit />)} />

            <Route exact path="/manageDevices" render={() => (<ManageDevices />)} />
            <Route exact path="/editProfile" render={() => (<EditProfile />)} />

            <Route exact path="/createAtmOperator" render={() => (<AtmOperatorRegistration />)} />
            <Route exact path="/editAtmOperator" render={() => (<EditAtmOperatorRegistration />)} />

            <Route exact path="/createAppointment" render={() => (<CreateAppointment />)} />
            <Route exact path="/manageAppointments" render={() => (<AppointmentsList />)} />
            <Route exact path="/quickPatientRegistration" render={() => (<QuickPatientRegistration />)} />
            <Route exact path="/patientRegistration" render={() => (<PatientRegistration2 />)} />
            <Route exact path="/editPatient" render={() => (<EditPatientInformation />)} />
            <Route exact path="/managePatients" render={() => (<PatientList />)} />

            <Route exact path="/createDoctor" render={() => (<DoctorRegistration />)} />
            <Route exact path="/updateDoctor" render={() => (<UpdateDoctor />)} />
            <Route exact path="/coversheet" render={() => (<MainCasesheetWrapper><Casesheet /></MainCasesheetWrapper>)} />

            {/* <Route exact path="/createSchedule" render={()=>(<DoctorSchedule />)}/> */}
            <Route exact path="/manageSchedule" render={() => (<ScheduleList />)} />
            {/* <Route exact path="/updateSchedule" render={()=>(<DeleteSchedule />)}/> */}


            <Route exact path="/masterDashboard" render={() => (<MasterDashboard />)} />

            <Route exact path="/unitConfig" render={() => (<UnitConfig />)} />

            <Route exact path="/testConfig" render={() => (<TestConfig />)} />

            <Route exact path="/manageUsers" render={() => (<UserManagementDashboard />)} />
            <Route exact path="/empRegistration" render={() => (<EmployeeRegistration />)} />
            <Route exact path="/manageConsultationServices" render={() => (<ServiceStoreW><ManageSevices /></ServiceStoreW>)} />
            <Route exact path="/createConsultationService" render={() => (<ServiceStoreW><CreateConsultationService /></ServiceStoreW>)} />
            <Route exact path="/updateConsultationService" render={() => (<ServiceStoreW><UpdateConsultationService /></ServiceStoreW>)} />

            {/* <Route exact path="/createSection" render={()=>(<CreateSectionComp />)}/>
               <Route exact path="/manageSection" render={()=>(<ManageSection />)}/>
               <Route exact path="/createTemplate" render={()=>(<CreateTemplateComp />)}/>
                <Route exact path="/manageTemplates" render={()=>(<ManageTemplateComp />)}/>
                <Route exact path="/createQuestion" render={()=>(<CreateQuestionComp />)}/>
                <Route exact path="/saveForm" render={()=>(<SaveForm />)}/>
                <Route exact path="/chat" render={()=>(<SaveForm2 />)}/> */}

            <Route exact path="/refDoctor" render={() => <RefDoctorList />} />
            <Route
              exact
              path="/addReferralDoctor"
              render={() => <AddReferralDoctor />}
            />
            <Route
              exact
              path="/editReferralDoctor"
              render={() => <EditReferralDoctor />}
            />
            <Route
              exact
              path="/reasonMaster"
              render={() => <ReasonMasterList />}
            />
            <Route
              exact
              path="/addReasonMaster"
              render={() => <AddReasonMaster />}
            />
            <Route
              exact
              path="/editReasonMaster"
              render={() => <EditReasonMaster />}
            />
            <Route
              exact
              path="/labRegistrationMaster"
              render={() => <LabRegistrationMaster />}
            />
            <Route
              exact
              path="/addLabRegistration"
              render={() => <AddLabRegistration />}
            />
            <Route
              exact
              path="/editLabRegistration"
              render={() => <EditLabRegistration />}
            />
            <Route
              exact
              path="/inventoryMaster"
              render={() => <InventoryMaster />}
            />
            <Route exact path="/roaMaster" render={() => <ROAMasterList />} />
            <Route
              exact
              path="/waiveOffReasonMaster"
              render={() => <WaiveOffReasonMasterList />}
            />
            <Route exact path="/nhsMaster" render={() => <NHSMaster />} />
            <Route exact path="/addNHS" render={() => <AddNHS />} />
            <Route
              exact
              path="/specialityList"
              render={() => <SpecialityMasterList />}
            />
            <Route exact path="/complaintConfiguration" render={() => (<MainCaseSheetConfigurationWrapper> <ComplaintConfiguration/> </MainCaseSheetConfigurationWrapper>)} />

            {/* <Route
              exact
              path="/complaintConfiguration"
              render={() => (<CasesheetConfigContext><ComplaintConfiguration /></CasesheetConfigContext>)}
            /> */}
            <Route
              exact
              path="/physicalExaminationConfiguration"
              render={() => <PhysicalExmConfiguration />}
            />
            <Route
              exact
              path="/diagnosisConfiguration"
              render={() =><MainCaseSheetConfigurationWrapper>  <DiagnosisSystemConfiguration /></MainCaseSheetConfigurationWrapper>}
            />
            <Route
              exact
              path="/observationConfiguration"
              render={() => <ObservationConfiguration />}
            />
            <Route
              exact
              path="/instructionConfiguration"
              render={() => <InstructionConfiguration />}
            />
            <Route
              exact
              path="/adviceConfiguration"
              render={() => <MainCaseSheetConfigurationWrapper><AdviceConfiguration /></MainCaseSheetConfigurationWrapper>}
            />
            <Route
              exact
              path="/pharamacyMaster"
              render={() => <PharamacyMasterList />}
            />
            <Route exact path="/vitalMaster" render={() => <VitalTypeList />} />
            <Route
              exact
              path="/medicationMaster"
              render={() => <MainMasterWrapper><MedicationMaster /></MainMasterWrapper>}
            />
            <Route
              exact
              path="/diagnosisMaster"
              render={() => <MainMasterWrapper><DiagnosisMaster /></MainMasterWrapper>}
            />
            <Route
              exact
              path="/documentTypeMaster"
              render={() => <DocumentTypeMasterList />}
            />
            <Route
              exact
              path="/complaintsMaster"
              render={() => <MainMasterWrapper><ComplaintsMaster /></MainMasterWrapper>}
            />

            <Route
              exact
              path="/allNotifications"
              render={() => <NotificationPage />}
            />
            <Route exact path="/op_bill" render={() => <OPBilling />} />
            <Route exact path="/billDetails" render={() => <BillDeatails />} />
            <Route exact path="/billView" render={() => <BillView />} />
            <Route exact path="/orderSet" render={() => <OrderSet />} />
            

            <Route exact path="/searchDevice" render={() => <SearchDevice/>} />
            <Route exact path="/manageInventory" render={() => <ManageInventory/>} />
            <Route exact path="/manageConfigurations" render={() => <ManageConfigurations/>} />
            <Route exact path="/manageReports" render={() => <ManageReports/>} />


            <Route exact path="/allergyMaster" render={() => <MainMasterWrapper><AllergyMasterList /></MainMasterWrapper>} />
            <Route exact path="/habitMaster" render={() => <MainMasterWrapper> <HabitMasterList /> </MainMasterWrapper> } />
            <Route exact path="/adviceMaster" render={() =><MainMasterWrapper> <AdviceMasterList /></MainMasterWrapper>} />
            <Route exact path="/physicalExaminationMaster" render={() => <MainMasterWrapper><PhysicalExaminationMaster /></MainMasterWrapper>} />
            <Route render={() => <ErrorComp role={props.role} />} />
          </Switch>
        </Suspense>
      </ErrorComp>
    </Router>
  )
}

export default WithAuthenticator(Routes);
