import React, { Component } from 'react';
import { Hub } from "aws-amplify";
import { ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignIn, SignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';
import { Button, Form, Spin, Card, Input } from 'antd';
import AuthLayout from './AuthLayout';
import { ThemeContext } from '../../context/themeContext';
import validateFields from 'antd/lib/form';


const renderCodeForm=(that)=>{

  return(

      <ThemeContext.Consumer>
        {({ theme1 }) => {
          return (
            <AuthLayout>
               {that.err ? <p style={{color:'#e01414',fontSize:'12px'}}>{that.err}</p> : ''}
              <Form 
   
              name="main"
              onFinish={()=>{

              }}
                className="auth-form" style={{ justifyContent: 'space-around', flexDirection: 'column' }}>
             
             
                  <div>
                    <Input
                      placeholder="Code"
                      size="large"
                      style={{ color: 'black' , borderRadius:'5px'}}
                      name="code"
                      value={that.state.code}
                      onChange={(e) => {
                        if(e.target.value){
                          e.target.value = e.target.value.trim()
                          let event = e;
                          that.handleInputChange(event)
                        }
                            that.setState({
                              code:e.target.value
                            })
                      }} />
                  </div>
                  <div>
                    <Input.Password
                      placeholder="New Password"
                      size="large"
                      style={{ color: 'black' , borderRadius:'5px'}}
                      name="password"
                      value={that.state.newPassword}
                      onChange={(e) => {
                        if(e.target.value){
                          e.target.value = e.target.value.trim()
                          let event = e;
                          that.handleInputChange(event)
                        }
                            that.setState({
                              newPassword:e.target.value
                            })
                      }} />
                  </div>
                  <div>
                    <Button
                      block
                      size="large"
                      htmlType="submit"
                      style={{ background: theme1.accentColor, color: theme1.color, marginTop: 20, borderRadius:'5px' }}
                      //   disabled={that.state.loading&& that.state.error == null}
                      onClick={(e) => {
                        that.handleSubmit()
                      }}>
                      Submit
                        {/* {that.state.loading&& that.state.error == null ? 
                              <Spin /> :
                          <p style={{color:'#fff'}}>Log In</p>
                        } */}
                    </Button>
                  </div>



</Form>


              <a
                style={{ marginTop: 40, float: 'right' }}
                onClick={() => { that.changeState('signIn') }}>
                <p >Back To Sign In</p>
              </a>


            </AuthLayout>
          )
        }}
      </ThemeContext.Consumer>

  
  )
}

const renderMainForm=(that)=>{
  return(
    <ThemeContext.Consumer>
    {({ theme1 }) => {
      return (
        <AuthLayout>
           {that.err ? <p style={{color:'#e01414',fontSize:'12px'}}>{that.err}</p> : ''}
          <Form 
          // autoComplete={"off"}
          // form={form}
          name="main"
          onFinish={()=>{

          }}
            className="auth-form" style={{ justifyContent: 'space-around', flexDirection: 'column' }}>
          {/* {this.state.delivery == null ? */}
          <>
              <div>
                <Input
                  placeholder="Username"
                  size="large"
                  style={{ color: 'black' }}
                  name="username"
                  onChange={(e) => {
                    that.setState({
                        username: e.target.value
                    })
                    if(e.target.value){
                      e.target.value = e.target.value.trim()
                      let event = that.returnUsername(e);
                      console.log(event.target.value)
                      that.handleInputChange(event)
                    }
                  }} />
              </div>
              <div>
                <Button
                  block
                  size="large"
                  htmlType="submit"
                  style={{ background: theme1.accentColor, color: theme1.color, marginTop: 20 , borderRadius:'5px'}}
                  //   disabled={that.state.loading&& that.state.error == null}
                  onClick={(e) => {
                    that.handleSendCode()
                    // form.resetFields()
                  }}>
                  SEND CODE
                    {/* {that.state.loading&& that.state.error == null ? 
                          <Spin /> :
                      <p style={{color:'#fff'}}>Log In</p>
                    } */}
                </Button>
              </div>

          </>
         
          
        </Form>


          <a
            style={{ marginTop: 40, float: 'right' }}
            onClick={() => { that.changeState('signIn') }}>
            <p >Back To Sign In</p>
          </a>


        </AuthLayout>
      )
    }}
  </ThemeContext.Consumer>
  )
}


class CustForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this.err = ''
    this.state={
      code:'',
      username: '',
      newPassword: ''
    }
  }

  handleSendCode = e => {
    Hub.listen("auth", res => {
      const errorMsg = res.payload.data.message ? res.payload.data.message : null;
      // this.setState(prevState => ({...prevState, errMsg: errorMsg}));
      this.err = errorMsg
    })
    this.send();     
  }


  handleSubmit = e => {
    Hub.listen("auth", res => {
      const errorMsg = res.payload.data.message ? res.payload.data.message : null;
      // this.setState(prevState => ({...prevState, errMsg: errorMsg}));
      this.err = errorMsg
    })
    this.submit();     
  }


  returnUsername=(e)=>{
    if(e.target.value.includes('::') == true){
      return e
    }else{
      if(window){
       let url = window.location.origin;
       url = url.replace('https://','');
       url = url.replace('http://','');
       e.target.value = `${url}::${e.target.value}`;
       return e
      }else{
        return e
      }
    }
  }

  showComponent(theme){
    console.log(this)
    if(this.state.delivery == null){
      return renderMainForm(this)
    }else{
      return renderCodeForm(this)
    }
  }

  // render() {
  //   // console.log(this)
  //   if (this.props.authState == "forgotPassword") {

  //   if(this.state.delivery == null){

  //     return renderMainForm(this)
  //   }else{
  //     return renderCodeForm(this)
  //   }
        
       
  //   } else {
  //     return <span></span>

  //   }
  // }
}

export default CustForgotPassword;