import React, { useState } from 'react';
import { Tooltip, Select, DatePicker, Cascader, Avatar } from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined, UploadOutlined } from '@ant-design/icons';
import { OrgInfoContext } from '../../context/orgInfoContext';
import { speciality, specialityList } from '../commonImports/data'
const { Option } = Select;



const RenderDoctorCascader = (props) => {

  const options = [
    {
      value: 'ORTHOPEDICS',
      label: 'ORTHOPEDICS',
      children: [
        {
          value: 'Dr Ajay Bagalane',
          label: 'Dr Ajay Bagalane',

        },
      ],
    },
    {
      value: 'jiangsu',
      label: 'Jiangsu',
      children: [
        {
          value: 'nanjing',
          label: 'Nanjing',
          children: [
            {
              value: 'zhonghuamen',
              label: 'Zhong Hua men',
            },
          ],
        },
      ],
    },
  ];
  const filter = (inputValue, path) => {

    let arr = [];
    arr = path.filter((item) => {
      if (!item.children && inputValue.length > 2 && item.label.toLowerCase().includes(inputValue.toLowerCase())) {
        return item
      }

    })
    return arr.length > 0
    // return path.some(option => {
    //     // return option.label.toLowerCase().includes(inputValue.toLowerCase()) 
    //     let query = inputValue.toLowerCase()
    //     let regex = new RegExp(query,'g')
    // if(!option.children){

    //     return option.label.toLowerCase().match(query) !=null
    // }
    //     // return option.label.toLowerCase().search(regex) >-1

    // });
  }

  // function filter(inputValue, path) {
  //     return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  //   }
  const sort = (a, b) => {
    console.log(a, b)
  }


  return (
    <OrgInfoContext.Consumer>
      {(value) => {
        // console.log('value', value.speciality, value.docInfo)
        let options1 = [];
        let arr1 = []
        let subspecialityList = []
        specialityList.map((item) => {
          item.subSpeciality.map((a, i) => {
            subspecialityList.push(a)
          })
        })
        options1 = subspecialityList && subspecialityList.map((item) => {
          let arr = []
          value.docList.forEach((a) => {
            if (a.speciality != null && a.speciality.length > 0) {
              a.speciality.filter((data) => {
                if (item.slugName.indexOf(data.subSpeciality) != -1) {
                  arr.push({
                    value: a,
                    label: `Dr. ${a.fName} ${a.lName}`
                  })
                }
              })
            }
            //   if (a.allocateClassification != null && a.allocateClassification.indexOf(item.toUpperCase()) != -1) {
            //     arr.push({
            //       value: a,
            //       label: `Dr. ${a.fName} ${a.lName}`
            //     })
            //   }
          })
          if (arr.length > 0) {
            arr1.push({
              value: item.slugName,
              label: item.subspeciality,
              children: arr
            })
          }
          return (
            {
              value: item.slugName,
              label: item.subspeciality,
              children: arr
            }
          )

        })

        // console.log('arr1', arr1)


        return (
          <span style={{ marginRight: 20 }} className="doctor-list">
            <Tooltip title="Select Doctor">
            <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />

            </Tooltip>

            <Cascader
              placeholder={'Select Doctor'}
              value={value.docInfo != null && value.speciality != null ? [value.speciality, value.docInfo] : null}
              style={{ width: 350 }}
              showSearch={{ filter }}
              options={arr1}
              onChange={(val) => {

                value.setDoctorIdOnChange(val[1]);
                value.setDoctorSpecialityOnChange(val[0])
              }}>

            </Cascader>
          </span>
        )

      }}
    </OrgInfoContext.Consumer>


  );

}

export default RenderDoctorCascader;