
import gql from 'graphql-tag';

export { 
    listDoctorsByOrgCust,
 }

  const listDoctorsByOrgCust = (docBasicDetails,docContactDetails)=>{
      console.log(docBasicDetails)
      return gql`
        query listDoctorsByOrg( $limit: Int $nextToken: String){
        listDoctorsByOrg(limit: $limit nextToken: $nextToken){
            items{
                ...docBasicDetails
                # ...docContactDetails
            }
            nextToken
            }
        }

        ${docBasicDetails}
    

        # {
        #   "limit": 10,
        #   "nextToken": ""
        # }
        `
        
  }
;



