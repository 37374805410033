
export const UPDATE_LIMIT ='UPDATE_LIMIT'
export const UPDATE_FORM = 'UPDATE_FORM'
export const UPDATE_DOC_LIST = 'UPDATE_DOC_LIST'
export const UPDATE_DOC_DATA= 'UPDATE_DOC_DATA'
export const SET_LOADING = 'SET_LOADING'
export const UPDATE_SUBSPECIALITY = 'UPDATE_SUBSPECIALITY'
export const SET_GLOBALCOMPLAINTS_LIST = 'SET_GLOBALCOMPLAINTS_LIST'
export const SET_GLOBALDIAGNOSIS_LIST = 'SET_GLOBALDIAGNOSIS_LIST'
export const SET_GLOBALMEDICATION_LIST = 'SET_GLOBALMEDICATION_LIST'
export const UPDATE_DOC_DATA_LIST = 'UPDATE_DOC_DATA_LIST'
export const UPDATE_SELECTED_SUBSPECIALITY= 'UPDATE_SELECTED_SUBSPECIALITY'


const ContextReducer = (state, action)=>{
    switch (action.type) {
        case UPDATE_LIMIT:
            return {...state, limit : action.payload }
        case UPDATE_FORM:
            return {...state, form : action.payload }
        case UPDATE_DOC_LIST:
            return {...state, doctorList: action.payload }
        case UPDATE_DOC_DATA_LIST:
            return {...state, selectedDocList: action.payload }
        case UPDATE_DOC_DATA:
        return {...state, selectedDocData: action.payload }
            case UPDATE_SUBSPECIALITY:
            return {...state, subSpeciality: action.payload }
        case SET_GLOBALCOMPLAINTS_LIST:
            return {...state, globalComplaintsList : action.payload };
        case SET_GLOBALDIAGNOSIS_LIST:
            return {...state, globalDiagnosisList: action.payload }
        case SET_GLOBALMEDICATION_LIST:
            return {...state, globalMedicationList: action.payload }
        case UPDATE_SELECTED_SUBSPECIALITY :
            return { ...state, selectedSubSpeciality: action.payload }
        case SET_LOADING:
            return {...state, loading: action.payload }
        
        default:
          return state
      }
}

export {
    ContextReducer
}