import React, { createContext,useReducer,useState, useEffect ,useContext} from 'react';
import { Layout, Menu, Breadcrumb, Card, List, Avatar,Form, Button,Upload,Switch, Popconfirm, message, Skeleton, Row, Col, Calendar, PageHeader, Badge, Table, Radio, Input, Dropdown, Modal } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { OrgInfoContext } from '../../../../../context/orgInfoContext';
import { withApollo } from 'react-apollo';
import WithAuthenticator from '../../../../withAuthenticator';
import { getDocList ,fetchGlobalComplaintListQuery ,fetchGlobalDiagnosisListQuery } from  '../../../../common/graphqlFunctions'
import { ContextReducer } from './mainReducer'
import { UPDATE_FORM, UPDATE_DOC_LIST ,UPDATE_SUBSPECIALITY ,UPDATE_DOC_DATA ,SET_GLOBALCOMPLAINTS_LIST ,UPDATE_DOC_DATA_LIST,
  UPDATE_LIMIT ,UPDATE_SELECTED_SUBSPECIALITY ,  SET_LOADING ,SET_GLOBALDIAGNOSIS_LIST} from './mainReducer'
import {  specialityList } from '../../../../commonImports/data'
import { SecondaryWrapper } from './secondaryWrapper'



const { Search } = Input;
// const symptoms = ['Itching','Red Eyes','Left Hand Pain','Joint Pain','Vomiting' ,'Acne' ,'Shortness of breath','Fever','Testicular pain']




export const initialState = {
    doctorList: null,
    limit:50,
    selectedDocData:null,
    selectedDocList:null,
    subSpeciality:[],
    form: null,
    loading: false,
    selectedSubSpeciality:null,
    globalComplaintsList:[],
    globalDiagnosisList: [],
    globalMedicationList: [],
  };

export const CasesheetConfigContext = createContext(initialState);


const MainCaseSheetConfigComp = (props) => {

  const [form] = Form.useForm();

  const [state, dispatch] = useReducer(ContextReducer, initialState);

  const { doctorList ,subSpeciality ,selectedDocData ,limit} = state;
  // console.log('state',state)


  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: form
    })
    },[]);

    useEffect(() => {
      fetchDataAsync()
      },[]);
  
    const fetchDataAsync = () => {
      let variables={}
      getDocList(props, variables).then((res)=>{
          // setloading(false)
          if (res.data && res.data.listDoctorsByOrg && res.data.listDoctorsByOrg != null) {
              dispatch({
                  type: UPDATE_DOC_LIST,
                  payload: res.data.listDoctorsByOrg.items
                  })
               }
      }).catch((err)=>{
          // setloading(false)
          console.log(err)
      })
  }

  useEffect(()=>{
    let subspecialityList = []
    let options1=[]
    let arr1 = []

    specialityList.map((item) => {
      item.subSpeciality.map((a, i) => {
        subspecialityList.push(a)
      })
    })
    options1 = subspecialityList && subspecialityList.map((item) => {
      let arr = []
      doctorList!==null&& doctorList.length>0 && doctorList.forEach((a) => {
        if (a.speciality != null && a.speciality.length > 0) {
          a.speciality.filter((data) => {
            if (item.slugName.indexOf(data.subSpeciality) != -1) {
              arr.push({
                value: a,
                label: `Dr. ${a.fName} ${a.lName}`
              })
            }
          })
        }
      })
      if (arr.length > 0) {
        arr1.push({
          value: item.slugName,
          label: item.subspeciality,
          children: arr
        })
      }
      return (
        {
          value: item.slugName,
          label: item.subspeciality,
          children: arr
        }
      )
    })
    dispatch({
      type: UPDATE_SUBSPECIALITY,
      payload: arr1
    })
    // setsubSpeciality(arr1)
    // console.log('arr1',arr1)
 },[doctorList]);

 const selectedSpeciality =(index)=>{
  let selectedObj=subSpeciality[index]
  dispatch({
    type:UPDATE_SELECTED_SUBSPECIALITY,
    payload:selectedObj
  })
  dispatch({
    type: UPDATE_DOC_DATA_LIST,
    payload: selectedObj.children
  })

  if(state.globalComplaintsList!== null && state.globalComplaintsList.length>0){
    let data=state.globalComplaintsList.map((a,i)=>{
      // console.log('a',a)
      if(a.assignedList!==undefined){
        delete a['assignedList']
        delete a['newassigned']
        delete a['key']
      }
      return a
    })
    if(data){
      dispatch({
        type: SET_GLOBALCOMPLAINTS_LIST,
        payload: data
      })
    }
  }

  if(state.globalDiagnosisList!== null && state.globalDiagnosisList.length>0){
    let data=state.globalDiagnosisList.map((a,i)=>{
      // console.log('a',a)
      if(a.assignedList!==undefined){
        delete a['assignedList']
        delete a['newassigned']
        delete a['key']
      }
      return a
    })
    if(data){
      dispatch({
        type: SET_GLOBALDIAGNOSIS_LIST,
        payload: data
      })
    }
  }

}


const setLoading= ()=>{
  dispatch({
    type: SET_LOADING,
    payload: state.loading
  })
}

const addToDoctorlist=(type)=>{
  dispatch({
    type:UPDATE_DOC_DATA,
    payload:null
  })
  dispatch({
    type:UPDATE_SELECTED_SUBSPECIALITY,
    payload:null
  })
  // dispatch({
  //   type:UPDATE_SUBSPECIALITY,
  //   payload:null
  // })
}


const ComplaintsFunction = async()=>{
// console.log('')
  let variables = {
    limit: state['limit'],
    nextToken: ""
  }
  try{
      let res = await fetchGlobalComplaintListQuery(props,variables)
      if(res.data.getGlobalComplaintList && res.data.getGlobalComplaintList != null && res.data.getGlobalComplaintList.items != null){
        return res.data.getGlobalComplaintList.items
      }
  }catch(err){
      console.log(err)
  }
}

const diagnosisFunction = async()=>{
  // console.log('')
    let variables = {
      limit: state['limit'],
      nextToken: ""
    }
    try{
        let res = await fetchGlobalDiagnosisListQuery(props,variables)
        if(res.data.getGlobalDiagnosisList && res.data.getGlobalDiagnosisList != null && res.data.getGlobalDiagnosisList.items != null){
          return res.data.getGlobalDiagnosisList.items
        }
    }catch(err){
        console.log(err)
    }
  }


const getGlobaldataList= async(type)=>{
  // console.log('getGlobaldataList',type)
  let arr = []
  if(type == 'complaints'){
    setLoading()
    let res = await ComplaintsFunction()
    dispatch({
          type:SET_GLOBALCOMPLAINTS_LIST,
          payload:res
        })
      arr = res
    setLoading()
  }
  if(type == 'diagnosis'){
    setLoading()
    let res = await diagnosisFunction()
    dispatch({
          type:SET_GLOBALDIAGNOSIS_LIST,
          payload:res
        })
      arr = res
    setLoading()
  }
  if(type == 'medication'){
      
  }
  return arr ;
}

const updateGlobaldataList= (type,list)=>{
  // console.log('updateGlobaldataList',type,list)
  setLoading()
  if(type == 'complaints'){
   
    dispatch({
          type:SET_GLOBALCOMPLAINTS_LIST,
          payload:list
        })
  }
  if(type == 'diagnosis'){
    dispatch({
      type:SET_GLOBALDIAGNOSIS_LIST,
      payload:list
    })
  }
  if(type == 'medication'){
      
  }
  
}



const selectedDoc=(val)=>{
  let docData=JSON.parse(val)
  dispatch({
    type:UPDATE_DOC_DATA,
    payload:docData
  })
  // setselectedDoctor(docData)
}



const fetch=(type)=>{
  // console.log(state['limit'],type)
  let limit=state['limit']+50
  dispatch({
      type:UPDATE_LIMIT,
      payload:limit
  })
  if(type=='complaints'){
     getGlobaldataList(type) 
  }
  if(type=='diagnosis'){
    getGlobaldataList(type) 
 }

}

const onFinish = async(values,checkedList) => {
  console.log('Recived values',values,checkedList)
};

 
  return (
      <>
        <CasesheetConfigContext.Provider value={{
            state,
            onFinish,
            selectedSpeciality,
            getGlobaldataList,
            selectedDoc,
            fetch,
            setLoading,
            updateGlobaldataList,
            addToDoctorlist
          }}>
            <SecondaryWrapper>
            {props.children}
            </SecondaryWrapper>
        </CasesheetConfigContext.Provider> 
    </>
  )
}

const MainCaseSheetConfigurationWrapper = withRouter(withApollo(MainCaseSheetConfigComp));


export {
   MainCaseSheetConfigurationWrapper
}

// export default withRouter(withApollo(WithAuthenticator(MainCaseSheetConfiguration)))
