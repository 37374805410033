import React, { useContext } from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import { UsergroupAddOutlined, LaptopOutlined, NotificationOutlined, OrderedListOutlined, LogoutOutlined, MenuOutlined, UserOutlined  } from '@ant-design/icons';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import Amplify, { Auth } from "aws-amplify";
import { DoctorIcon} from '../commonImports/custIcons/icons'
import logo from '../../assets/small_logo.png'
import { OrgInfoContext } from '../../context/orgInfoContext';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;




const logout = () => {
  // localStorage.removeItem("user");
  // localStorage.removeItem("firstVisit");
  Auth.signOut().then((data) => {
    // console.log(data)
    window.location.href='/'
    // window.location.reload(true)
  });
};



const MainSidebar=(props)=>{
  const context = useContext(OrgInfoContext)

  let activeMenuKey = '/';
  if(props.location && props.location.pathname){
    activeMenuKey = props.location.pathname
  }
    return(

      <Sider 
     
      // style={{background.theme1.accentColor}}
        theme="light"
        width={250} 
        collapsible={true}
        collapsed={props.collapsed}
        className="main-sidebar"
        trigger={null}
        onCollapse={()=>{
            // console.log('clicked')
            // props.setCollapsed(!props.collapsed)
        }}
        >
        <Menu
         theme="dark"
          mode="inline"
          defaultSelectedKeys={[activeMenuKey]}
          style={{ 
            height: '100%', 
            borderRight: 0,
            paddingTop:'20px'
            // background.theme1.accentColor, 
            // color:'white' 
          }}
        >

          <Menu.Item key={'logo'} style={{padding:'0px'}}>
                      
                   <div style={{height:"40px",width:'40px', margin:'auto'}}>
                   <img src={logo} style={{height:"auto",width:'100%'}}/>
                   </div> 
                   
          </Menu.Item>
           <Menu.Item key={'collapse'}  onClick={() => {
                    props.setCollapsed(!props.collapsed)
                  }} > 
                <MenuOutlined
                  style={{ color: '#fff', fontSize: 15, display: 'inline-block', marginRight: 20 }}
                 />
                  </Menu.Item>

          {props.menuS.map((p)=>{
            if(p.type == 'submenu'){
              return(
                <SubMenu
                  key={p.to}
                  title={
                    <>
                      {p.icon}
                    <span>
                   
                      {p.title}
                    </span>
                  </>
                  }
                >
                {p.children && 
                p.children.map((m)=>{
                  return(
                    <Menu.Item key={m.to}>
                      <LaptopOutlined/>

                      <Link to={m.to}>{m.title}</Link>
                    
                    </Menu.Item>
                  )
                })}
              </SubMenu>
              )
            }else{
              return(
                <Menu.Item  
                  key={p.to}> 
                  <Link to={p.to ? p.to : '/'}>
                  {p.icon}
                  <span>

                 {p.title}
                  </span>
                  </Link>
                </Menu.Item>
              )
            }
          })}

            <Menu.Item key={'profile'}>
                  <Link to='/editProfile'>

              <UserOutlined style={{fontSize:'18px'}}/>
              <span>

                {'Profile'}
                </span>
              </Link>
                  </Menu.Item>
            <Menu.Item  
            key={'logout'}
            onClick={()=>{
              logout()}}
                  > 
                <span className="anticon">

                  <LogoutOutlined style={{fontSize:16,marginRight:0}}/>
              </span>
                  <span>Logout</span>
                </Menu.Item> 

         
        </Menu>
      </Sider>


);
}

export default withRouter(MainSidebar)

