import React, { useState, useContext, useEffect } from 'react';

import { getCaseheetsByPatientId, getTestResultsbyPatientId, fetchDataByZip, 
  listPatientsByOrg, listQuestionTemplates, listSectionTemplates, fetchMedication,
fetchComplaints ,fetchDiagnosis, getDoctorUnitMappings, listDoctorsByOrg,
listStaffByOrg, listUnits, getTestConfigByPhysicalId, getPatientById, getDoctorById, getEncounterIdByApptId,
getConsultationServices ,getGlobalComplaintList ,getGlobalDiagnosisList ,getGlobalMedicationList ,fetchAdvice , fetchHabit ,fetchAllergy ,fetchPhysicalExamination ,getGlobalAdvice ,getGlobalAllergy ,getGlobalHabit,
getGlobalPhysicalExamination} from '../../graphql/queries'
import { deletePatient, createQuestionTemplate, addMedicationToMyList, removeMedicationToMyList,
addComplaintsToMyList, removeComplaintsToMyList ,removeDiagnosisToMyList ,
addDiagnosisToMyList, deleteFormTemplate, deleteScheduleById, addDoctorToUnit, updateCogUnitId, updateEncounter, createPatientCasesheet,
shareCasesheetReport, createConsultationService, updateConsultationService ,addComplaintsToMyListByAdmin,addDiagnosisToMyListByAdmin ,addAdviceToMyList ,removeAdviceToMyList ,addAllergyToMyList ,removeAllergyToMyList ,removeHabitToMyList,
addHabitToMyList , addPhysicalExaminationToMyList , removePhysicalExaminationToMyList} from '../../graphql/mutations'
import { csvJSON } from '../common/functions';


export {
    getCasesheetQuery,
    getTestResultsbyPatientIdQuery,
    deletePatientMut,
    fetchDataByZipQuery,
    getPatientList,
    ListQuestionTemplatesQuery,
    listSectionTemplatesQuery,
    createQuestionTemplateMut,
    fetchMedicationQuery,
    fetchMyList,
    addMedicationToMyListMut,
    removeMedicationToMyListMut,
    fetchComplaintsQuery,
    addComplaintsToMyListMut,
    removeComplaintsToMyListMut,
    fetchDiagnosisQuery,
    removeDiagnosisToMyListMut,
    addDiagnosisToMyListMut,
    deleteFormTemplateMut,
    deleteScheduleByIdMut,
    getDoctorUnitMappingsQuery,
    deleteScheduleByIdMutPromise,
    GetUnitsQuery,
    listStaffByOrgQuery,
    getDocList,
    addDoctorToUnitMut,
    updateCogUnitIdMut,
    getTestConfigByPhysicalIdQuery,
    getEncounterIdByApptIdQuery,
    updateEncounterMut,
    getPatientByIdQuery,
    getDoctorByIdQuery,
    createCasesheetMut,
    shareCasesheetReportMut,
    getConsultationServicesQuery,
    createConsultationServiceQuery,
    updateConsultationServiceMut,
    fetchGlobalComplaintListQuery,
    fetchGlobalDiagnosisListQuery,
    fetchGlobalMedicationListQuery,
    addComplaintsToMyListByAdminmut,
    addDiagnosisToMyListByAdminMut,
    fetchAdviceQuery,
    fetchHabitQuery,
    fetchAllergyQuery,
    fetchPhysicalExaminationQuery,
    getGlobalAdviceQuery,
    addAdviceToMyListMut,
    removeAdviceToMyListMut,
    getGlobalAllergyQuery,
    addAllergyToMyListMut,
    removeAllergyToMyListMut,
    getGlobalHabitQuery,
    getGlobalPhysicalExaminationQuery,
    addHabitToMyListMut,
    removeHabitToMyListMut,
    addPhysicalExaminationToMyListMut ,
    removePhysicalExaminationToMyListMut
}

const removePhysicalExaminationToMyListMut = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.mutate({
      mutation:  removePhysicalExaminationToMyList,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const addPhysicalExaminationToMyListMut = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.mutate({
      mutation:  addPhysicalExaminationToMyList,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const removeHabitToMyListMut =  async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.mutate({
      mutation:  removeHabitToMyList,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}


const addHabitToMyListMut = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.mutate({
      mutation:  addHabitToMyList,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const getGlobalPhysicalExaminationQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: getGlobalPhysicalExamination,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const getGlobalHabitQuery =  async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: getGlobalHabit,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const removeAllergyToMyListMut = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.mutate({
      mutation:  removeAllergyToMyList,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const addAllergyToMyListMut =async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.mutate({
      mutation:  addAllergyToMyList,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const getGlobalAllergyQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: getGlobalAllergy,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const removeAdviceToMyListMut=async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.mutate({
      mutation:  removeAdviceToMyList,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const addAdviceToMyListMut=async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.mutate({
      mutation:  addAdviceToMyList,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const getGlobalAdviceQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: getGlobalAdvice,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const fetchPhysicalExaminationQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: fetchPhysicalExamination,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const fetchAllergyQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: fetchAllergy,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const fetchHabitQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: fetchHabit,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}


const fetchAdviceQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: fetchAdvice,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const addDiagnosisToMyListByAdminMut =  async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.mutate({
      mutation: addDiagnosisToMyListByAdmin,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const addComplaintsToMyListByAdminmut =  async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.mutate({
      mutation: addComplaintsToMyListByAdmin,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}



const fetchGlobalMedicationListQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: getGlobalMedicationList,
      variables: variables,
      fetchPolicy: 'no-cache',
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

const fetchGlobalDiagnosisListQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: getGlobalDiagnosisList,
      variables: variables,
      fetchPolicy:'network-only'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}

 const fetchGlobalComplaintListQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: getGlobalComplaintList,
      variables: variables,
      fetchPolicy:'network-only'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}


const updateConsultationServiceMut = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.mutate({
      mutation: updateConsultationService,
      variables: variables
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}



const createConsultationServiceQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.mutate({
      mutation: createConsultationService,
      variables: variables
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}


const getConsultationServicesQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: getConsultationServices,
      variables: variables,
      fetchPolicy:'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}



const shareCasesheetReportMut = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client
      .mutate({
        mutation: shareCasesheetReport,
        variables: variables
      })
      .then(data => {
        res(data);
      })
      .catch(err => {
        console.log(err);
      });
  });
};


const updateEncounterMut = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client
      .mutate({
        mutation: updateEncounter,
        variables: variables
      })
      .then(data => {
        res(data);
      })
      .catch(err => {
        console.log(err);
      });
  });
};


const getPatientByIdQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client
      .query({
        query: getPatientById,
        variables: variables
      })
      .then(data => {
        res(data);
      })
      .catch(err => {
        console.log(err);
      });
  });
};

const getDoctorByIdQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client
      .query({
        query: getDoctorById,
        variables: variables
      })
      .then(data => {
        res(data);
      })
      .catch(err => {
        console.log(err);
      });
  });
};

const createCasesheetMut = async (props, variables) => {
  // console.log('createCasesheetMut',variables)
  return await new Promise((res, rej) => {
    props.client
      .mutate({
        mutation: createPatientCasesheet,
        variables: { input: variables }
      })
      .then(data => {
        res(data);
        // console.log('data',data)
      })
      .catch(err => {
        console.log(err);
      });
  });
};

const getEncounterIdByApptIdQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client
      .query({
        query: getEncounterIdByApptId,
        variables: variables,
        fetchPolicy: "no-cache"
      })
      .then(data => {
        res(data);
      })
      .catch(err => {
        console.log(err);
      });
  });
};


const getTestConfigByPhysicalIdQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
      props.client.query({
          query: getTestConfigByPhysicalId,
          variables: variables,
          fetchPolicy:'no-cache'
      }).then((data) => {
          res(data)
      }).catch((err) => {
          console.log(err)
      })
  })
}

const updateCogUnitIdMut = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.mutate({
      mutation: updateCogUnitId,
      variables: variables 
    }).then((data) => {
        res(data)
      }).catch((err) => {

        rej(err)
      })
    })
  }


const addDoctorToUnitMut = async (props, variables) => {
  return new Promise((res, rej) => {
      props.client.mutate({
          mutation: addDoctorToUnit,
          variables: variables
      }).then((data) => {
        res(data)
    }).catch((err) => {
        console.log(err)
        rej(err)
    })
  })
}

const getDocList = async (props, variables) => {
  return await new Promise((res, rej) => {
      props.client.query({
          query: listDoctorsByOrg,
          variables: variables,
          fetchPolicy:'no-cache'
      }).then((data) => {
          res(data)
      }).catch((err) => {
          console.log(err)
      })
  })
}

const listStaffByOrgQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
      props.client.query({
          query: listStaffByOrg,
          variables: variables,
          fetchPolicy:'no-cache'
      }).then((data) => {
          res(data)
      }).catch((err) => {
          console.log(err)
      })
  })
}

const GetUnitsQuery = async (props, variables) => {
return await new Promise((res, rej) => {
  props.client.query({
    query: listUnits,
    variables:variables,
    fetchPolicy:'no-cache'
  }).then((data) => {
    res(data)
  }).catch((err) => {
    console.log(err)
    rej(err)
  })
})
}


const getDoctorUnitMappingsQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: getDoctorUnitMappings,
      variables: variables,
      fetchPolicy:'network-only'
    }).then((data) => {
        res(data)
      }).catch((err) => {
        console.log(err)
        rej(err)
      })
    })
  }

  const deleteScheduleByIdMutPromise = async (props, variables) => {
    return new Promise((res, rej) => {
        props.client.mutate({
            mutation: deleteScheduleById,
            variables: variables
        }).then((data) => {
          res(data)
      }).catch((err) => {
          console.log(err)
          rej(err)
      })
    })
  }
  

const deleteScheduleByIdMut = async (props, variables) => {
  return await new Promise((res, rej) => {
      props.client.mutate({
          mutation: deleteScheduleById,
          variables: variables
      }).then((data) => {
          res(data)
      }).catch((err) => {
          console.log(err)
          rej(err)
      })
  })
}


const deleteFormTemplateMut = async (props, variables) => {
  return await new Promise((res, rej) => {
      props.client.mutate({
          mutation: deleteFormTemplate,
          variables: variables
      }).then((data) => {
          res(data)
      }).catch((err) => {
          console.log(err)
          rej(err)
      })
  })
}

const removeComplaintsToMyListMut = async (props, variables) => {
  return await new Promise((res, rej) => {
      props.client.mutate({
          mutation: removeComplaintsToMyList,
          variables: variables
      }).then((data) => {
          res(data)
      }).catch((err) => {
          console.log(err)
          rej(err)
      })
  })
}



const addComplaintsToMyListMut = async (props, variables) => {
  return await new Promise((res, rej) => {
      props.client.mutate({
          mutation: addComplaintsToMyList,
          variables: variables
      }).then((data) => {
          res(data)
      }).catch((err) => {
          console.log(err)
          rej(err)
      })
  })
}

const fetchDiagnosisQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: fetchDiagnosis,
      variables: variables,
      fetchPolicy:'network-only'
    }).then((data) => {
        res(data)
      }).catch((err) => {
        console.log(err)
        rej(err)
      })
    })
  }


const fetchComplaintsQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: fetchComplaints,
      variables: variables,
      fetchPolicy:'network-only'
    }).then((data) => {
        res(data)
      }).catch((err) => {
        console.log(err)
        rej(err)
      })
    })
  }


  const removeDiagnosisToMyListMut = async (props, variables) => {
    return await new Promise((res, rej) => {
        props.client.mutate({
            mutation: removeDiagnosisToMyList,
            variables: variables
        }).then((data) => {
            res(data)
        }).catch((err) => {
            console.log(err)
            rej(err)
        })
    })
  }

const removeMedicationToMyListMut = async (props, variables) => {
  return await new Promise((res, rej) => {
      props.client.mutate({
          mutation: removeMedicationToMyList,
          variables: variables
      }).then((data) => {
          res(data)
      }).catch((err) => {
          console.log(err)
          rej(err)
      })
  })
}

const addDiagnosisToMyListMut = async (props, variables) => {
  return await new Promise((res, rej) => {
      props.client.mutate({
          mutation: addDiagnosisToMyList,
          variables: variables
      }).then((data) => {
          res(data)
      }).catch((err) => {
          console.log(err)
          rej(err)
      })
  })
}

const addMedicationToMyListMut = async (props, variables) => {
  return await new Promise((res, rej) => {
      props.client.mutate({
          mutation: addMedicationToMyList,
          variables: variables
      }).then((data) => {
          res(data)
      }).catch((err) => {
          console.log(err)
          rej(err)
      })
  })
}



const fetchMyList = async (props, variables) => {
  return await new Promise((resolve, reject) => {
      fetch(`https://${variables.bucket}.s3-${variables.region}.amazonaws.com/${variables.orgId}/local-storage/${variables.user}/${variables.fileName}.csv `,{
         headers: {
              "Accept": "text/csv",
              "cache-control": "no-cache",
              "cache": "no-store"
            }
      })
      .then(response => {
        if(response.status == 200){
          return response.text()
        }else{
          return null
        }
      })
      .then((data) => {
        if(data != null){
          let arr =  csvJSON(data)
          resolve(arr)
        }else{
          resolve([])
        }
      }).catch((err)=>{
        console.log(err)
        reject(err)
      })
    })
  }


const fetchMedicationQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: fetchMedication,
      variables: variables,
      fetchPolicy:'network-only'
    }).then((data) => {
        res(data)
      }).catch((err) => {
        console.log(err)
        rej(err)
      })
    })
  }


const createQuestionTemplateMut = async (props, variables) => {
  return await new Promise((res, rej) => {
      props.client.mutate({
          mutation: createQuestionTemplate,
          variables: variables
      }).then((data) => {
          res(data)
      }).catch((err) => {
          console.log(err)
          rej(err)
      })
  })
}



const listSectionTemplatesQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: listSectionTemplates,
      variables: variables,
      fetchPolicy:'network-only'
    }).then((data) => {
        res(data)
      }).catch((err) => {
        console.log(err)
        rej(err)
      })
    })
  }

const ListQuestionTemplatesQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: listQuestionTemplates,
      variables: variables,
      fetchPolicy:'network-only'
    }).then((data) => {
        res(data)
      }).catch((err) => {
        console.log(err)
        rej(err)
      })
    })
  }

const getPatientList = async (props, variables) => {
    return await new Promise((res, rej) => {
      props.client.query({
        query: listPatientsByOrg,
        fetchPolicy:'no-cache'
      }).then((data) => {
        res(data)
      }).catch((err) => {
        console.log(err)
      })
    })
  }

const getTestResultsbyPatientIdQuery = async (props, variables) => {
    return await new Promise((res, rej) => {
      props.client.query({
        query: getTestResultsbyPatientId,
        variables: variables,
        fetchPolicy:'no-cache'
      }).then((data) => {
        res(data)
      }).catch((err) => {
        console.log(err)
      })
    })
  }
  

const getCasesheetQuery = async (props, variables) => {
    return await new Promise((res, rej) => {
      props.client.query({
        query: getCaseheetsByPatientId,
        variables: variables,
        fetchPolicy:'no-cache'
  
      }).then((data) => {
        res(data)
      }).catch((err) => {
        console.log(err)
      })
    })
  }

  
  const deletePatientMut = async (props, variables) => {
    return await new Promise((res, rej) => {
      props.client.mutate({
        mutation: deletePatient,
        variables: variables,
      }).then((data) => {
          res(data)
        }).catch((err) => {
          console.log(err)
          rej(err)
        })
      })
    }


const fetchDataByZipQuery = async (props, variables) => {
    return await new Promise((res, rej) => {
      props.client.query({
        query: fetchDataByZip,
        variables: variables,
        fetchPolicy:'no-cache'
      }).then((data) => {
        res(data)
      }).catch((err) => {
        console.log(err)
      })
    })
  }


;