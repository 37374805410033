import React, { createContext, useReducer, useEffect, useContext, useState } from "react";
import { SecondReducer, SET_COMPLAINTS_LIST, SET_DIAGNOSIS_LIST, SET_MEDICATION_LIST } from './secondReducer';
import { AuthContext } from "../../../../../context/authContext";
import { configVariables } from "../../../../../configs/configs";
import { fetchMyList,fetchComplaintsQuery, fetchDiagnosisQuery, fetchMedicationQuery } from "../../../../common/graphqlFunctions";
import { withApollo } from 'react-apollo';


export const initialState = {
    complaintsList: [],
    diagnosisList: [],
    medicationList: []
  };


const SecondaryWrapperComp = (props)=>{
    const [state, dispatch] = useReducer(SecondReducer, initialState);
    const authContext = useContext(AuthContext);


    const fetchCSV = (type) => {
        if (authContext.data != null) {
          let variables = {
            bucket: configVariables.BUCKET,
            region: configVariables.REGION,
            orgId: authContext.user.orgId,
            user: authContext.data.email,
            fileName: type
          };
    
          fetchMyList(props, variables)
            .then(res => {
                if(type == 'complaints'){
                    dispatch({
                        type: SET_COMPLAINTS_LIST,
                        payload: res
                    })
                }
                if(type == 'diagnosis'){
                    dispatch({
                        type: SET_DIAGNOSIS_LIST,
                        payload: res
                    })
                }
                if(type == 'medication'){
                    dispatch({
                        type: SET_MEDICATION_LIST,
                        payload: res
                    })
                }
            })
            .catch(err => {
              console.log(err);
            });
        }
      };


      const fetchGlobalMedications=async(val)=>{
        let variables = {
                searchValue: val,
                from: 0,
                size: 50
        };
        try{
            let res = await fetchMedicationQuery(props, variables)

        if (res.data.fetchMedication && res.data.fetchMedication != null) {
            return res.data.fetchMedication;
        }

        }catch(err){
            console.log(err)
        }
    }


    const fetchGlobalComplaints=async(val)=>{
        let variables = {
                searchValue: val,
                from: 0,
                size: 50
        };
        try{
            let res = await fetchComplaintsQuery(props, variables)

        if (res.data.fetchComplaints && res.data.fetchComplaints != null) {
            return res.data.fetchComplaints;
        }

        }catch(err){
            console.log(err)
        }
    }

    const fetchGlobalDiagnosis=async(val)=>{
        let variables = {
                searchValue: val,
                from: 0,
                size: 50
        };
        try{
            let res = await fetchDiagnosisQuery(props, variables)

        if (res.data.fetchDiagnosis && res.data.fetchDiagnosis != null) {
            return res.data.fetchDiagnosis;
        }

        }catch(err){
            console.log(err)
        }
    }



      const getLocalData=(type)=>{
            fetchCSV(type)
      }



      const getGlobalData=async(type, val)=>{
          let arr = []
        if(type == 'complaints'){
          let res = await fetchGlobalComplaints(val)
            arr = res
        }
        if(type == 'diagnosis'){
            let res = await fetchGlobalDiagnosis(val)
              arr = res
        }
        if(type == 'medication'){
            let res = await fetchGlobalMedications(val)
            arr = res
        }
        return arr ;
      }


      const filterFunction=(type,val)=>{
          let arr = []
          if(type == 'complaints'){
            arr = state['complaintsList'].filter(item => {
                if (item.complaint.toLowerCase().indexOf(val.toLowerCase()) != -1) {
                  return item;
                }
              });
          }
          if(type == 'diagnosis'){
            arr = state['diagnosisList'].filter(item => {
                if (item.name.toLowerCase().indexOf(val.toLowerCase()) != -1) {
                  return item;
                }
              });
          }
          if(type == 'medication'){
            arr = state['medicationList'].filter(item => {
                if (item.medicationName.toLowerCase().indexOf(val.toLowerCase()) != -1
                || item.genericName.toLowerCase().indexOf(val.toLowerCase()) != -1) {
                  return item;
                }
              });
          }
          return arr;
      }


      const searchData=async(type, val, globalEnabled)=>{

        let flag = null;
        if(type == 'complaints'){
            flag = 'complaintsList'
        }
        if(type == 'diagnosis'){
            flag = 'diagnosisList'
        }
        if(type == 'medication'){
            flag = 'medicationList'
        }


          let returnArr = [];

        if (val && val.length > 1) {
            if (state[flag] && state[flag].length > 0) {
              let ss = filterFunction(type,val)
              if (ss.length > 10) {
                returnArr = ss;
              } else {
                returnArr =  [...ss,...await getGlobalData(type,val)]
              }

            } else {
              returnArr = await getGlobalData(type,val)
            }
          }
        return returnArr
      }



    return (
        <SecondaryContext.Provider value={{ state, dispatch, getLocalData, searchData }}>
            {props.children}
        </SecondaryContext.Provider>
    )
}

export const SecondaryContext = createContext(initialState);

const SecondaryWrapper = withApollo(SecondaryWrapperComp)


export {
    SecondaryWrapper
}