export const SET_COMPLAINTS_LIST = 'SET_COMPLAINTS_LIST'
export const SET_DIAGNOSIS_LIST = 'SET_DIAGNOSIS_LIST'
export const SET_MEDICATION_LIST = 'SET_MEDICATION_LIST'
export const SET_ADVICE_LIST = 'SET_ADVICE_LIST'
export const SET_GLOBALCOMPLAINTS_LIST = 'SET_GLOBALCOMPLAINTS_LIST'
export const SET_GLOBALDIAGNOSIS_LIST = 'SET_GLOBALDIAGNOSIS_LIST'
export const SET_GLOBALMEDICATION_LIST = 'SET_GLOBALMEDICATION_LIST'




export const SecondReducer = (state, action)=>{
    switch (action.type) {
        case SET_COMPLAINTS_LIST:
          return {...state, complaintsList : action.payload };
        case SET_DIAGNOSIS_LIST:
            return {...state, diagnosisList: action.payload }
        case SET_MEDICATION_LIST:
            return {...state, medicationList: action.payload }
        case SET_ADVICE_LIST:
            return {...state, adviceList: action.payload }
        case SET_GLOBALCOMPLAINTS_LIST:
          return {...state, globalComplaintsList : action.payload };
        case SET_GLOBALDIAGNOSIS_LIST:
            return {...state, globalDiagnosisList: action.payload }
        case SET_GLOBALMEDICATION_LIST:
            return {...state, globalMedicationList: action.payload }
        default:
          return state
      }
}