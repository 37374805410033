export const SET_COMPLAINTS_LIST = 'SET_COMPLAINTS_LIST'
export const SET_DIAGNOSIS_LIST = 'SET_DIAGNOSIS_LIST'
export const SET_MEDICATION_LIST = 'SET_MEDICATION_LIST'
export const SET_ADVICE_LIST = 'SET_ADVICE_LIST'
export const SET_ALLERGY_LIST = 'SET_ALLERGY_LIST'
export const SET_HABIT_LIST = 'SET_HABIT_LIST'
export const SET_PHYSICALEXAMINATION_LIST = 'SET_PHYSICALEXAMINATION_LIST'

export const SecondReducer = (state, action)=>{
    switch (action.type) {
        case SET_COMPLAINTS_LIST:
          return {...state, complaintsList : action.payload };
        case SET_DIAGNOSIS_LIST:
            return {...state, diagnosisList: action.payload }
        case SET_MEDICATION_LIST:
            return {...state, medicationList: action.payload }
        case SET_ADVICE_LIST:
            return {...state, adviceList: action.payload }
        case SET_ALLERGY_LIST:
            return {...state, allergyList: action.payload }
        case SET_HABIT_LIST:
            return {...state, habitList: action.payload }
        case SET_PHYSICALEXAMINATION_LIST:
            return {...state, physicalExaminationList: action.payload }

        default:
          return state
      }
}