
import React, { useState, useContext, useEffect } from 'react';
import { MenuOutlined, FileTextOutlined, TeamOutlined, DashboardOutlined, UsergroupAddOutlined, HddOutlined, ClusterOutlined, UserAddOutlined, CarryOutOutlined, ForkOutlined, LaptopOutlined, RadarChartOutlined, NotificationOutlined, OrderedListOutlined, FieldTimeOutlined, PictureOutlined, ProfileOutlined } from '@ant-design/icons';
import { AuthContext } from '../../context/authContext';


const style = {
  iconStyle: {
    fontWeight: 800,
    fontSize: 20
  }
}

const AuthenticateMenu = (permissions) => {
  const [isDoctor, setisDoctor] = useState(false);

  const context = useContext(AuthContext);;

  useEffect(() => {
    setisDoctor(context.isDoctor)
  })

  var Sidemenu = []
  // console.log(permissions, "pppppppppppppppside");
  if (permissions.indexOf('UnitAdmin') != -1 || permissions.indexOf('ReadPatient') != -1) {
    Sidemenu.push({
      title: 'Dashboard',
      to: '/',
      type: 'menu',
      icon: <DashboardOutlined style={style.iconStyle} />,
    })
  }

  if (permissions.indexOf('Admin') != -1) {
    Sidemenu.push({
      title: 'Organizations',
      to: '/createOrganization',
      type: 'menu',
      icon: <TeamOutlined style={style.iconStyle} />,
    })
  }

  if (permissions.indexOf('OrgAdmin') != -1) {
    Sidemenu.push({
      title: 'Units',
      to: '/manageUnits',
      type: 'menu',
      icon: <ForkOutlined style={style.iconStyle} />,
    })
  }

  if (permissions.indexOf('UnitAdmin') != -1 || permissions.indexOf('OrgAdmin') != -1) {
    Sidemenu.push({
      title: 'Devices',
      to: '/manageDevices',
      type: 'menu',
      icon: <ClusterOutlined style={style.iconStyle} />,
    })
  }


  if (permissions.indexOf('UnitAdmin') != -1) {
    Sidemenu.push({
      title: 'Inventory',
      to: '/searchDevice',
      type: 'menu',
      icon: <ClusterOutlined style={style.iconStyle} />,
    })
  }

  if (permissions.indexOf('ReadPatient') != -1 || permissions.indexOf('UnitAdmin') != -1) {

    Sidemenu.push({
      title: 'Appointments',
      to: '/manageAppointments',
      type: 'menu',
      icon: <CarryOutOutlined style={style.iconStyle} />,
    })
  }


  if (permissions.indexOf('ReadPatient') != -1 || permissions.indexOf('UnitAdmin') != -1) {

    Sidemenu.push({
      title: 'Schedule',
      to: '/manageSchedule',
      type: 'menu',
      icon: <FieldTimeOutlined style={style.iconStyle} />,
    })
  }


  if (permissions.indexOf('ReadPatient') != -1 || permissions.indexOf('UnitAdmin') != -1) {
    Sidemenu.push({
      title: 'Services',
      type: 'submenu',
      icon: <RadarChartOutlined style={style.iconStyle} />,
      children: [
        {
          title: 'Consultation',
          to: '/manageConsultationServices',
        },
      ]
    })
  }


  if (permissions.indexOf('ReadUser') != -1 || permissions.indexOf('UnitAdmin') != -1 || permissions.indexOf('OrgAdmin') != -1) {
    Sidemenu.push({
      title: 'Users',
      to: '/manageUsers',
      type: 'menu',
      // icon:<span role="img" className="fas fa-user-md anticon fa-lg"></span>,
      icon: <UserAddOutlined style={style.iconStyle} />
    })
  }

  if (permissions.indexOf('ReadPatient') != -1 || permissions.indexOf('UnitAdmin') != -1) {
    Sidemenu.push({
      title: 'Patients',
      to: '/managePatients',
      type: 'menu',
      icon: <UserAddOutlined style={style.iconStyle} />,
    })
  }




  if (permissions.indexOf('UnitAdmin') != -1) {

    Sidemenu.push({
      title: 'Casesheet Configuration',
      to: '/casesheetConfiguration',
      type: 'submenu',
      icon: <ProfileOutlined style={style.iconStyle} />,
      children: [
        {
          title: 'Complaint Configuration',
          to: '/complaintConfiguration',
        },
        // {
        //   title:'Physical Configuration',
        //   to:'/physicalExaminationConfiguration',
        // },
        // {
        //   title:'Advice Configuration',
        //   to:'/adviceConfiguration',
        // },
        // {
        //   title:'Instruction Configuration',
        //   to:'/instructionConfiguration',
        // },
        // {
        //   title:'Observation Configuration',
        //   to:'/observationConfiguration',
        // },
        // {
        //   title:'History Configuration',
        //   to:'/historyConfiguration',
        // },
        // {
        //   title:'Diagnosis System Configuration',
        //   to:'/diagnosisConfiguration',
        // },
      ]
    })
  }
  if (isDoctor == true) {
    Sidemenu.push({
      title: 'Master',
      to: '/masterDashboard',
      type: 'menu',
      icon: <FileTextOutlined style={style.iconStyle} />,
    })
  }



  //   if (isDoctor == true) {
  //     Sidemenu.push({
  //       title: 'Master',
  //       to: '/masterDashboard',
  //       type: 'submenu',
  //       icon: <FileTextOutlined style={style.iconStyle} />,
  //       children: [
  //         {
  //           title: 'Master',
  //           to: '/masterDashboard',
  //         },
  //         // {
  //         //   title: 'Speciality Master',
  //         //   to: '/specialityList',
  //         // },
  //         // {
  //         //   title: 'Referral Details',
  //         //   to: '/refDoctor',
  //         // },
  //         // {
  //         //   title: 'Reason Master',
  //         //   to: '/reasonMaster',
  //         // },
  //         // {
  //         //   title: 'Lab Registration Master',
  //         //   to: '/labRegistrationMaster',
  //         // },
  //         // {
  //         //   title: 'Inventory Master',
  //         //   to: '/inventoryMaster',
  //         // },
  //         // {
  //         //   title:'Medication Master',
  //         //   to:'/medicationMaster',
  //         // },
  //         // {
  //         //   title:'ROA Master',
  //         //   to:'/roaMaster',
  //         // },
  //         // {
  //         //   title: 'Waive Off Reason Master',
  //         //   to: '/waiveOffReasonMaster',
  //         // },
  //         // {
  //         //   title: 'NHS Master',
  //         //   to: '/nhsMaster',
  //         // },
  //         // {
  //         //   title: 'Pharamacy Master',
  //         //   to: '/pharamacyMaster',
  //         // },
  //         // {
  //         //   title: 'Vital Master',
  //         //   to: '/vitalMaster',
  //         // },
  //         // {
  //         //   title: 'Document Type Master',
  //         //   to: '/documentTypeMaster'
  //         // }
  //       ]
  //   })
  // }

  // if(permissions.indexOf('ReadPatient') != -1 || permissions.indexOf('UnitAdmin') != -1){
  //   Sidemenu.push( {
  //       title:'Billing',
  //       to:'/op_bill',
  //       type:'submenu',
  //       icon:<UserAddOutlined style={style.iconStyle}/>,
  //   })
  // }

  // if(permissions.indexOf('UnitAdmin') != -1){
  //   Sidemenu.push( {
  //       title:'Billing',
  //       to:'/op_bill',
  //       type:'submenu',
  //       icon:<HddOutlined style={style.iconStyle}/>,
  //       children:[
  //         {
  //           title:'OP Bill',
  //           to:'/op_bill',
  //         },
  //         {
  //           title:'Bill View',
  //           to:'/billView',
  //         },
  //       ]
  //   })
  // }




  return Sidemenu
}


export default AuthenticateMenu;