import React, { Component } from 'react';
import { Hub } from "aws-amplify";
import { ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignIn, SignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';
import { Button, Form, Spin, Card, Input } from 'antd';
import AuthLayout from './AuthLayout';
import { ThemeContext } from '../../context/themeContext';



class CustRequireNewPassword extends RequireNewPassword {

  constructor(props) {
    super(props);
    this.err = '';
  }

  handleChagePassword = e => {
    Hub.listen("auth", res => {
      const errorMsg = res.payload.data.message ? res.payload.data.message : null;
      // this.setState(prevState => ({...prevState, errMsg: errorMsg}));
      this.err = errorMsg
    })
    this.change()     
  }

render() {
    if(this.props.authState == "requireNewPassword"){

    return (
   <ThemeContext.Consumer>
          {({ theme1 }) => {
            return (
        <AuthLayout>
            <p style={{color:'#e01414',fontSize:'12px'}}>{'You need to reset password for first time login.'}</p>

            {this.err ? <p style={{color:'#e01414',fontSize:'12px'}}>{this.err}</p> : ''}
              <Form className="auth-form" style={{justifyContent:'space-around',flexDirection:'column'}}>
                <div>
                <Input.Password
                  placeholder="New Password"
                  size="large"
                  style={{color:'black', borderRadius:'5px'}} 
                    name="password"
                    onChange={(e)=>{
                      this.handleInputChange(e)
                    }}/>
                </div>
                <div>
                <Button  
                block
                 size="large"  
                 htmlType="submit"
                 style={{ background: theme1.accentColor, color: theme1.color,marginTop:20, borderRadius:'5px' }}
                  onClick={(e)=>{
                    this.handleChagePassword()
                  }}>
                     CHANGE
        
              </Button>
                </div>
            
          
              </Form>
          
              <a   
              style={{ marginTop: 40, float:'right' }}
                onClick={()=>{this.changeState('signIn')}}>
                  <p >Back To Sign In</p>
              </a>
              
     
      </AuthLayout>
             )
            }}
          </ThemeContext.Consumer>
      
    );
        }else {
          return <span></span>

        }
  }
}

export default CustRequireNewPassword;