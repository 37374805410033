import React, { useState, useContext } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next'

import { Translation } from 'react-i18next';
import { TranslationComp } from '../import1'

import {
  genderValues,
  maritalStatusValues,
  prefixValues
} from '../data';

export {
  address,
  uploadPhoto,
  uploadPhoto1,
  prefix,
  fName,
  mName,
  lName,
  gender,
  maritalStatus,
  mobNo,
  phoneNo,
  skypeId,
  email,
  docDetails,
  referralDocDetails,
  whatsappNo,
  skypeid
}


const uploadPhoto1 = [{
  type: 'upload',
  label: <TranslationComp name="form.regCommonFields.photo" />,
  name: 'photo',
  required: false,
  message: 'Please upload photo'
}]


const uploadPhoto = [{
  type: 'upload-picture',
  label: <TranslationComp name="form.regCommonFields.photo" />,
  name: 'photo',
  placeholder: 'Photo',
  required: false,
  message: 'Please upload photo'
}]

const email = [{
  type: 'input',
  // label: <TranslationComp name="form.regCommonFields.emaild" />,
  label: "Email Id",
  name: 'emailId',
  placeholder: 'Email Id',
  required: true,
  message: 'Please input email-id',
  custValidation: [{
    validator: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Please enter valid Email address',
    type: 'regx',
  }]
}]


const skypeId = [{
  type: 'input',
  label: 'Skype Id',
  name: 'skypeId',
  placeholder: 'Skype Id',
  required: false,
  message: 'Please input trade No',
  custValidation: [{

  }]
},]


const phoneNo = [{
  type: 'input',
  label: <TranslationComp name="form.regCommonFields.phone" />,
  name: 'phoneNo',
  placeholder: 'Phone No',
  required: false,
  message: 'Please input phone no',
  custValidation: [{

  }],
},]

const mobNo = [
  {
    type: 'mobile',
    // label: <TranslationComp name="form.regCommonFields.mobile"/>,
    label: 'Mobile Number',
    name: 'mobile',
    required: true,
    placeholder: 'Mobile',
    // prefix:'+91',
    message: 'Please input mobile no',
    custValidation: [{

    }]
  }]

const whatsappNo = [
  {
    type: 'mobile',
    label: <TranslationComp name="form.regCommonFields.mobile" />,
    name: 'whatsappNo',
    required: true,
    placeholder: 'WhatsApp No',
    // prefix:'+91',
    message: 'Please input WhatsApp no',
    custValidation: [{

    }]
  }]
const skypeid = [
  {
    type: 'mobile',
    label: 'Skype id',
    name: 'skypeid',
    required: true,
    placeholder: 'Skype id',
    // prefix:'+91',
    message: 'Please input Skype id',
    custValidation: [{

    }]
  }]


const maritalStatus = [
  {
    type: 'select',
    label: 'Marital Status',
    name: 'maritalStatus',
    required: false,
    message: 'Please input last name',
    values: maritalStatusValues,
    custValidation: [{

    }],
  },
]


const gender = [
  {
    type: 'radio',
    label: <TranslationComp name="form.regCommonFields.gender" />,
    name: 'gender',
    required: true,
    message: 'Please select gender',
    values: genderValues,
  },
]

const prefix = [{
  type: 'select',
  label: <TranslationComp name="form.regCommonFields.prefix" />,
  name: 'prefix',
  required: true,
  message: 'Please input prefix',
  values: prefixValues,
  width: '80px'
}]

const fName = [{
  type: 'input',
  label: <TranslationComp name="form.regCommonFields.fName" />,
  name: 'fName',
  required: true,
  placeholder: 'First Name',
  message: 'Please input first name',
  custValidation: [{
    validator: "^[a-zA-Z]+$",
    message: 'Please enter valid name',
    type: 'regx',
  }]
}]

const mName = [{
  type: 'input',
  label: <TranslationComp name="form.regCommonFields.mName" />,
  name: 'mName',
  required: false,
  placeholder: 'Middle Name',
  message: 'Please input middle name',
  custValidation: [{
    validator: "^[a-zA-Z]+$",
    message: 'Please enter valid name',
    type: 'regx',
  }]

}]

const lName = [{
  type: 'input',
  label: <TranslationComp name="form.regCommonFields.lName" />,
  name: 'lName',
  required: true,
  placeholder: 'Last Name',
  message: 'Please input last name',
  custValidation: [{
    validator: "^[a-zA-Z]+$",
    message: 'Please enter valid name',
    type: 'regx',
  }]
}]

const address = [{
  type: 'input',
  label: <TranslationComp name="form.regCommonFields.address.lineOne" />,
  name: 'lineOne',
  required: false,
  placeholder: 'Line One',
  message: 'Please input address line one',
  custValidation: [{
    validator: /^[a-zA-Z0-9 ]+$/,
    message: 'Please enter valid name',
    type: 'regx',
  }]
},
{
  type: 'input',
  label: <TranslationComp name="form.regCommonFields.address.lineTwo" />,
  name: 'lineTwo',
  required: false,
  placeholder: 'Line Two',
  message: 'Please input address line two',
  custValidation: [{
    validator: "^[0-9a-zA-Z]+$",
    message: 'Please enter valid name',
    type: 'regx',
  }]
},
{
  type: 'input',
  label: <TranslationComp name="form.regCommonFields.address.city" />,
  name: 'city',
  required: false,
  placeholder: 'City',
  message: 'Please input city',
  custValidation: [{
    validator: "^[a-zA-Z]+$",
    message: 'Please enter valid name',
    type: 'regx',
  }]
},
{
  type: 'input',
  label: <TranslationComp name="form.regCommonFields.address.state" />,
  name: 'state',
  required: false,
  placeholder: 'State',
  message: 'Please input state',
  custValidation: [{
    validator: "^[0-9a-zA-Z]+$",
    message: 'Please enter valid name',
    type: 'regx',
  }]
},
{
  type: 'select',
  label: <TranslationComp name="form.regCommonFields.address.country" />,
  name: 'country',
  required: false,
  values: ['IND'],
  message: 'Please input country',
  custValidation: [{

  }]
},
// placeholder:'First Name',
{
  type: 'input',
  label: <TranslationComp name="Zip Code / Postal Code " />,
  name: 'zip',
  placeholder: 'Zip',
  required: false,
  message: 'Please input postal code',
  custValidation: [{
    validator: "^[0-9]*$",
    message: 'Please enter valid zipcode',
    type: 'regx',
  }]

}

]


const docDetails = [
  {
    type: 'input',
    label: 'Document Type',
    name: 'docType',
    required: true,
    message: 'Please input document type'
  },
  {
    type: 'input',
    label: 'Document Name',
    name: 'docName',
    required: true,
    message: 'Please input document name'
  },
  {
    type: 'datepicker',
    label: 'Document Date',
    name: 'date',
    required: true,
    message: 'Please input document date'
  },
  {
    type: 'upload',
    label: 'Document Upload',
    name: 'doc',
    required: true,
    message: 'Please upload document '
  }
]

const referralDocDetails = [
  {
    type: 'input',
    label: 'Doctor Name',
    name: 'refName',
    required: false,
    message: 'Please input doctor name',
    custValidation: [{

    }]
  },
  {
    type: 'input',
    label: 'Doctor No',
    name: 'refMob',
    required: false,
    prefix: '+91',
    message: 'Please input doctor number',
    custValidation: [{

    }]
  },
  {
    type: 'input',
    label: 'Fax No',
    name: 'faxNo',
    required: false,
    message: 'Please input postal code',
    custValidation: [{

    }]
  },
  {
    type: 'input',
    label: 'Email Id',
    name: 'refEmail',
    placeholder: 'Email Id',
    required: false,
    message: 'Please input email-id',
    custValidation: [{

    }],
    divider: true
  },
  {
    type: 'input',
    label: 'Address Line One',
    name: 'addressLineOne',
    required: false,
    message: 'Please input address line one',
    custValidation: [{

    }]
  },
  {
    type: 'input',
    label: 'Address Line Two',
    name: 'addressLineTwo',
    required: false,
    message: 'Please input address line two',
    custValidation: [{

    }]
  },
  {
    type: 'input',
    label: 'City',
    name: 'city',
    required: false,
    message: 'Please input city',
    custValidation: [{

    }]
  },
  {
    type: 'input',
    label: 'State',
    name: 'state',
    required: false,
    message: 'Please input state',
    custValidation: [{

    }]
  },
  {
    type: 'input',
    label: 'Country',
    name: 'country',
    required: false,
    message: 'Please input country',
    custValidation: [{

    }]
  },
  {
    type: 'input',
    label: 'Area',
    name: 'area',
    required: false,
    message: 'Please input area',
    custValidation: [{

    }]
  },
  {
    type: 'input',
    label: 'Postal Code',
    name: 'postalCode',
    required: false,
    message: 'Please input postal code',
    custValidation: [{

    }],
    divider: true
  },
  // {
  //   type:'checkbox',
  //   label: 'Is Consultant Referral',
  //   name:'isConsultantReferral',
  //   showlabel:false,
  //   required:false,
  //   message:'Please input area',
  //   custValidation:[{

  //   }]
  // },
  // {
  //   type:'checkbox',
  //   label: 'Is NHS',
  //   name:'isNHS',
  //   showlabel:false,
  //   required:false,
  //   message:'Please input area',
  //   custValidation:[{

  //   }]
  // },

]