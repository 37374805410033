import React from 'react';


import MainLayout from '../../common/layout';
import Routes from './routes'

function App() {
  return (
 
        <Routes/>
  
  );
}

export default App;
