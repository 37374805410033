import React, { createContext, useReducer, useEffect, useContext, useState } from "react";
import { OrgInfoContext } from '../../../../../context/orgInfoContext';
import { withApollo } from 'react-apollo';
import { message, Button, Form } from 'antd'
import { Link, withRouter } from 'react-router-dom';
// import { CasesheetContext } from '.././context-reducer/mainCasesheetWrapper';
import { ContextReducer } from './mainReducer'
import { INCREMENT, SET_SELECTED_MENU, UPDATE_APPT_RECORD,
  UPDATE_PATIENT_DATA, UPDATE_DOC_DATA, UPDATE_ENC_ID,
  SET_SELECTED_TAB, UPDATE_FORM, UPDATE_FLAG, SET_LOADING, SET_CONSULT_TYPE } from './mainReducer';
import { casesheetMenu } from "../../../../commonImports/data";
import { 
  getTestResultsbyPatientIdQuery,
  getEncounterIdByApptIdQuery,
  updateEncounterMut,
  getPatientByIdQuery,
  getDoctorByIdQuery,
  createCasesheetMut,
  shareCasesheetReportMut
 } from '../../../../common/graphqlFunctions';
import moment from 'moment';
import { calculateAgeFun, returnSentenceCase } from "../../../../common/functions";
import { SecondaryWrapper } from './secondaryWrapper'


export const initialState = {
    count: 0,
    selectedMenu : casesheetMenu[0].title,
    record: null,
    patientData: null,
    doctorData: null,
    encounterId: null,
    fields: ["chief complaints","vitals","diagnosis","orders","Note","past history","allergies","Advice"// "order set"
      ],
    selected: "1",
    form: null,
    flag: false,
    loading: false,
    consultType: null
  };

  export const CasesheetContext = createContext(initialState);
  

const MainCasesheetWrapperComp = (props)=>{
  const orgContext = useContext(OrgInfoContext);

  let unitData = null;
  if(orgContext.unitData != null){
    unitData = orgContext.unitData;
  }

  const [form] = Form.useForm();
  // console.log(props)
  const [state, dispatch] = useReducer(ContextReducer, initialState);

 
  const { record, encounterId, patientData, doctorData } = state;


  let age = null;
  if (patientData) {
      age = calculateAgeFun(patientData.birthDate);
  }


  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: form
    })
    if(props.location.state &&  props.location.state.record){
        let obj = props.location.state.record;
       
        dispatch({
          type: UPDATE_APPT_RECORD,
          payload: obj
        })

        if(obj.patientId){
          getPatientData(obj.patientId)
        }

        if(obj.doctorId){
          getDoctorData(obj.doctorId)
        }
        if(!obj.doctorId && obj.userId){
          getDoctorData(obj.userId.indexOf('::') ? obj.userId.split('::')[1] : obj.userId);
          obj.doctorId = obj.userId.indexOf('::') ? obj.userId.split('::')[1] : obj.userId;
          dispatch({
            type: UPDATE_APPT_RECORD,
            payload: obj
          })
  
        }

        if(obj.encounterId && obj.encounterId != null){
          dispatch({
            type: UPDATE_ENC_ID,
            payload: obj.encounterId
          })
        }

        if((!obj.encounterId || obj.encounterId == null) && (obj.apptId || obj.patientId)){
          getEncounterId(obj)
        }

        if ( obj.source && obj.source == "ATM" ) {
          dispatch({
            type: SET_CONSULT_TYPE,
            payload: "TELECONSULTATION"
          });

        }
    
    }
  }, [])


  const getEncounterId=(obj)=>{
    // console.log('getEncounterId')
    let variables = {
      patientId: obj.patientId,
      apptId: obj.apptId
    };

    getEncounterIdByApptIdQuery(props, variables).then((res)=>{

      if (res.data &&res.data.getEncounterIdByApptId &&res.data.getEncounterIdByApptId != null) {
        dispatch({
          type: UPDATE_ENC_ID,
          payload: res.data.getEncounterIdByApptId.encounterId
        })
      }
    }).catch((err)=>{
      console.log(err)
    })
}

  const getDoctorData=(id)=>{
    getDoctorByIdQuery(props, { id: id })
    .then(res => {
      if (res.data) {
        dispatch({
          type: UPDATE_DOC_DATA,
          payload: res.data.getDoctorById
        })
      }
    })
    .catch(err => {
      console.log(err);
    });
}


  const getPatientData=(id)=>{
    dispatch({
      type:SET_LOADING,
      payload: true
    })
      getPatientByIdQuery(props, {
        patientId: id
      })
      .then(res => {
        if (res.data) {
          dispatch({
            type: UPDATE_PATIENT_DATA,
            payload: res.data.getPatientById
          })
          dispatch({
            type:SET_LOADING,
            payload: false
          })
        }
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type:SET_LOADING,
          payload: false
        })
      });
  }

  const setloading=(val)=>{
    dispatch({
      type: SET_LOADING,
      payload: val
    })
  }

  const createCasesheetOnSubmit=(values)=>{

     if (record != null) {
        setloading(true)
        let variables = { ...values };
        if (variables.medication) {
          variables.medication = variables.medication.map(x => {
            if (x.dosageDate != null && x.dosageDate != undefined) {
              x.dosageDate[0] = moment(x.dosageDate[0]).format("DD MMM YYYY");
              x.dosageDate[1] = moment(x.dosageDate[1]).format("DD MMM YYYY");
              return x;
            }
          });
        }
        variables.patientId = record.patientId;
        variables.doctorId = record.doctorId;
        variables.encounterId = encounterId;
        if (variables.diagnosis) {
          let diagnosisData = variables.diagnosis.map((a, i) => {
            return {
              diagnosis: a.name,
              type: a.type,
              priority: a.priority,
              note: a.note
            };
          });
          variables.diagnosis = diagnosisData;
        }
        if (variables.chiefComplaints) {
          let chiefComplaints = variables.chiefComplaints.map((a, i) => {
            return {
              complaintName: a.complaint,
              note: a.note
            };
          });
          variables.chiefComplaints = chiefComplaints;
        }

        // if(values.pastHistory){
        //   // pastHistory = values.pastHistory.map((a,i)=>{
        //   //   return{

        //   //   }
        //   // })
        //   // variables.pastHistory = values.pastHistory;
        // }
       console.log(variables)
        createCasesheetMut(props, variables)
          .then(data => {
            setloading(false)
            if (data.data.createPatientCasesheet != null) {
              message.success("Casesheet is saved");
              props.history.push("/");
            }

            updateEncounterFun();
            shareCasesheetReportFun(data.data.createPatientCasesheet);

          })
          .catch(err => {
            setloading(false)
            message.error("There is an error ");
          });
    }
  }

  const updateEncounterFun = () => {
    let variables = {
      patientId: record.orgId + "::" + record.patientId,
      encounterId: encounterId,
      status: "CLOSED"
    };
    updateEncounterMut(props, variables)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };


  const shareCasesheetReportFun=(casesheet)=>{
    let addrs = null;
  
    addrs = patientData.address == null
      ? "-"
      : `${patientData.address[0] !== null &&patientData.address[0].lineOne != null? patientData.address[0].lineOne: ""} ${patientData.address[0] !== null &&patientData.address[0].lineTwo != null? patientData.address[0].lineTwo: ""} ${patientData.address[0] !== null &&patientData.address[0].city != null? patientData.address[0].city: ""} ${patientData.address[0] !== null &&patientData.address[0].state != null? patientData.address[0].state: ""} ${patientData.address[0] !== null &&patientData.address[0].country != null? patientData.address[0].country: ""}`

      let unitAddr = null;
      if(unitData.address != null){
        unitAddr = unitData.address == null
        ? null
        : `${unitData.address.lineOne != null? unitData.address.lineOne: ''} ${unitData.address.lineTwo != null? unitData.address.lineTwo: ""} ${unitData.address.city != null? unitData.address.city: ""} ${unitData.address.state != null? unitData.address.state: ""} ${unitData.address.country != null? unitData.address.country: ""}`
      }


    let jsonObj = {
      patient:{
          name: `${patientData.fName.charAt(0).toUpperCase() + patientData.fName.slice(1)} ${patientData.lName.charAt(0).toUpperCase() + patientData.lName.slice(1)}`,
          dob: patientData.birthDate,
          age: age.year,
          address: addrs,
          gender: patientData.gender == null ? '-' : patientData.gender,
          mobile: patientData.contactNo == null ? '-' : patientData.contactNo,
          email: patientData.email
      },
      doctor: {
          name: doctorData != null  ?  `${doctorData.fName.charAt(0).toUpperCase() + doctorData.fName.slice(1)} ${doctorData.lName.charAt(0).toUpperCase() + doctorData.lName.slice(1)}` : '-' ,
          spec: doctorData != null && doctorData.allocateClassification ? doctorData.allocateClassification : "-",
          speciality: doctorData != null && doctorData.speciality ? doctorData.speciality : "-"
      },
      printedOn: moment().format('DD/MM/YYYY h:mm A'),
      orgName: unitData.unitName == null ? '-' : unitData.unitName,
      orgAddress:unitAddr,
      orgContact: unitData.contactNo == null ? '-' : unitData.contactNo,
      orgEmail: unitData.email == null ? '-' : unitData.email,
      casesheet: casesheet,
      createdAt: casesheet.createdAt,
      to: patientData.email
  }


      if(patientData.email && patientData.email != null){
        let variables = {
          valueString: JSON.stringify(jsonObj)
        };
        shareCasesheetReportMut(props, variables)
          .then(res => {
            console.log(res);
          })
          .catch(err => {
            console.log(err);
          });
      }
  }



  const setSelectedMenu = ( type )=>{
    dispatch({
      type: SET_SELECTED_MENU,
      payload: type
    })
  }

  const setSelectedTab = ( tab )=>{
    dispatch({
      type: SET_SELECTED_TAB,
      payload: tab
    })
  }

  const setflag = () =>{
    dispatch({
      type: UPDATE_FLAG
    })
  }


    return(
        <CasesheetContext.Provider value={{
          state, 
          setSelectedMenu,
          setSelectedTab,
          setflag,
          createCasesheetOnSubmit
          }}>
            <SecondaryWrapper>
            {props.children}

            </SecondaryWrapper>
        </CasesheetContext.Provider>
    )
}

const MainCasesheetWrapper = withRouter(withApollo(MainCasesheetWrapperComp));


export {
    MainCasesheetWrapper
}