import moment from 'moment';
import React from 'react'
import { UserOutlined, LaptopOutlined, ClockCircleTwoTone, EditTwoTone, SnippetsTwoTone, ClockCircleOutlined, SmileTwoTone, CalendarTwoTone, ExclamationCircleOutlined, SaveOutlined, MedicineBoxOutlined, NotificationOutlined, PlusSquareOutlined, ZoomInOutlined, PlusOutlined, StockOutlined, FileTextOutlined, VideoCameraOutlined, TableOutlined } from '@ant-design/icons';
import defaultImage from '../../assets/default.jpeg';

export {
  days,
  interval,
  months,
  visitType,
  chiefComplaints,
  examinations,
  advice,
  diagnosis,
  weeks,
  speciality,
  genderValues,
  maritalStatusValues,
  prefixValues,
  sampleQuestions,
  quesTypes,
  templateTypes,
  quesTypes2,
  countryCodes,
  demodata,
  questionsVariations,
  designTemplates,
  servicesColors,
  casesheetMenu,
  allergies,
  hobies,
  severity,
  defaultImage,
  roaData,
  frequencyList,
  dosageUnit,
  colorCodes,
  specialityList,
  addressType,
  allergyType
  // testMasterData
}


const addressType = ['Home', 'Office', 'Residential', 'Rental']
const allergyType = ['Food', 'Insect', 'Pet', 'Medicine','Plant','Enviromental']
const colorCodes = ['#f8857d', '#48b679', '#d383e2', '#f4ba38', '#38c0f4', '#4338f4', '#f438aa'];


const dosageUnit = ['mg', 'mcg', 'ml', 'drops', 'grains', 'gm', 'tsp(teaspoon)', 'tbsp(tablespoon)', 'Inj', 'Capsule', 'Cup', 'Litre']

const roaData = [
  {
    sr: '1',
    code: 'TOP',
    desc: 'Topical',
    status: true
  },
  {
    sr: '2',
    code: 'NAS',
    desc: 'Nasal',
    status: true
  },
  {
    sr: '3',
    code: 'ORAL',
    desc: 'Oral',
    status: true
  },
  {
    sr: '4',
    code: 'TRANSD',
    desc: 'Transdermal',
    status: true
  },
  {
    sr: '5',
    code: 'OCU',
    desc: 'Ocular',
    status: true
  },
  {
    sr: '6',
    code: 'PAR',
    desc: 'Parenteral',
    status: true
  }
]

const frequencyList = [
  {
    sr: '1',
    name: 'Once a day',
    desc: 'Once a day',
    status: true
  },
  {
    sr: '2',
    name: 'Thrice a day',
    desc: 'Thrice a day',
    status: true
  },
  {
    sr: '3',
    name: 'Four Times a day',
    desc: 'Four Times a day',
    status: true
  },
  {
    sr: '4',
    name: 'Before meals',
    desc: 'Before meals',
    status: true
  },
  {
    sr: '5',
    name: 'After meals',
    desc: 'After meals',
    status: true
  },
  {
    sr: '6',
    name: 'As required',
    desc: 'As required',
    status: true
  },
  {
    sr: '7',
    name: 'Hourly',
    desc: 'Hourly',
    status: true
  },
  {
    sr: '8',
    name: 'Every 2 Hours',
    desc: 'Every 2 Hours',
    status: true
  },
  {
    sr: '9',
    name: 'Before Sleep',
    desc: 'Before Sleep',
    status: true
  },
  {
    sr: '10',
    name: 'Every 4 Hours',
    desc: 'Every 4 Hours',
    status: true
  }
]
const casesheetMenu = [
  {
    title: 'Current Casesheet',
    icon: <EditTwoTone style={{ fontSize: '20px', color: '#31c0d4' }} />,
    popup: true,
    seq: 0
  },
  // {
  //     title:'Past History',
  //     // icon:<ClockCircleOutlined style={{fontSize:'20px',color:'#31c0d4'}}/>,
  //     icon:<ClockCircleTwoTone style={{fontSize:'20px',color:'#31c0d4'}}/>,
  //     popup:true,
  //     seq:0
  // },
  {
    title: 'Previous Casesheets',
    icon: <SnippetsTwoTone style={{ fontSize: '20px', color: '#31c0d4' }} />,
    seq: 1
  },
  // {
  //     title:'trends',
  //     icon:<StockOutlined style={{fontSize:'20px',color:'#31c0d4'}}/>,
  //     popup:true,
  //     seq:2
  // },
  // {
  //     title:'medications',
  //     icon:<MedicineBoxOutlined style={{fontSize:'20px',color:'#31c0d4'}}/>,
  //     popup:true,
  //     seq:3
  // },
  // {
  //     title:'Conference',
  //     icon:<VideoCameraOutlined style={{fontSize:'20px',color:'#31c0d4'}}/>,
  //     seq:4
  // },
  // {
  //     title:'letter',
  //     icon:<FileTextOutlined style={{fontSize:'20px',color:'#31c0d4'}}/>,
  //     seq:5
  // },
  // {
  //     title:'Notification',
  //     icon:<NotificationOutlined style={{fontSize:'20px',color:'#31c0d4'}}/>,
  //     seq:6
  // },
  {
    title: 'Follow Up',
    icon: <CalendarTwoTone style={{ fontSize: '20px', color: '#31c0d4' }} />,
    seq: 7
  },
  // {
  //     title:'My Life Information',
  //     icon:<SmileTwoTone style={{fontSize:'20px',color:'#31c0d4'}}/>,
  //     popup:true,
  //     seq:1
  // },
  // {
  //     title:'Book View',
  //     icon:<TableOutlined style={{fontSize:'20px',color:'#31c0d4'}}/>,
  //     seq:8
  // }
]




const servicesColors = [{
  name: 'NEW',
  color: '#f8857d'
}, {
  name: 'FOLLOW',
  color: '#48b679'
}, {
  name: 'TELEPHONIC',
  color: '#177fff'
},
  , {
  name: 'TELE_CONSULTATION',
  color: '#d383e2'
},
]

const designTemplates = [
  {
    title: 'One Row One Column',
    obj: {
      title: 'Form title',
      description: 'form description',
      rows: [{
        addColumns: true,
        columns: [{
          addSection: true,
          sections: [{
            isEditing: true,
            questions: []
          }]
        }]
      }]
    }
  },
  {
    title: 'One Row Two Columns',
    obj: {
      title: 'Form title',
      description: 'form description',
      rows: [{
        addColumns: true,
        columns: [{
          addSection: true,
          sections: [{
            isEditing: true,
            questions: []
          }]
        },
        {
          addSection: false,
          sections: [{
            isEditing: false,
            questions: []
          }]
        }]
      }]
    }
  },
  {
    title: 'Two Rows',
    obj: {
      title: 'Form title',
      description: 'form description',
      rows: [{
        addColumns: true,
        columns: [{
          addSection: true,
          sections: [{
            isEditing: true,
            questions: []
          }]
        }]
      },
      {
        addColumns: false,
        columns: [{
          addSection: false,
          sections: [{
            isEditing: false,
            questions: []
          }]
        }]
      }]
    }
  }
]

const questionsVariations = [{ title: 'Between', value: 'between' },
{ title: 'Greater than', value: '>' },
{ title: 'Less than', value: '<' },
{ title: 'Equal', value: '=' }]

const demodata = {
  title: 'Form title',
  description: 'form description',
  sections: [{
    title: 'section title',
    questions: [
      {
        freeText: { answerSummary: "xsdsad" },
        questionTitle: "What is your age ? ",
        questionType: "FREE_TEXT",
        slugValue: "What is your age ",
        typeValue: "global::question",
        keywords: ['age'],
        isLinking: true,
        conditions: [],
        linkedQuestions: [{
          freeText: { answerSummary: "xsdsad" },
          questionTitle: "From what age are you suffering from stomach pain ?",
          questionType: "FREE_TEXT",
          slugValue: "From what age are you suffering from stomach pain ?",
          typeValue: "global::question",
          keywords: ['pain', 'stomach', 'age'],
          linkedQuestions: [],
          conditions: [],

        }]
      },
      {
        checkboxList: [{
          freeTextRequired: false,
          optionSummary: "Sitting quitely",
          optionTitle: "Sitting quitely",
          // linkedQuestions: []
        },
        {
          freeTextRequired: false,
          optionSummary: "Rest",
          optionTitle: "Rest",
          // linkedQuestions: []
        },
        {
          freeTextRequired: false,
          optionSummary: "Nitroglycerin tablets or sprays",
          optionTitle: "Nitroglycerin tablets or sprays",
        }
        ],
        questionTitle: "What makes stomach pain better ?",
        questionType: "CHECK_BOX",
        slugValue: "What makes stomach pain better ?",
        typeValue: "global::question",
        linkedQuestions: [],
        conditions: [],
      },
      {
        freeText: { answerSummary: "xsdsad" },
        questionTitle: "How often do you take these tablets ? ",
        questionType: "FREE_TEXT",
        slugValue: "How often do you take these tablets ? ",
        typeValue: "global::question",
        keywords: ['pain', 'stomach'],
        conditions: [],
        linkedQuestions: [{
          freeText: { answerSummary: "xsdsad" },
          questionTitle: "What is the dosage of tablets ? ",
          questionType: "FREE_TEXT",
          slugValue: "What is the dosage of tablets",
          typeValue: "global::question",
          keywords: ['age'],
          isLinking: true,
          linkedQuestions: []
        }]
      },
      {
        questionTitle: "Do you do smoking ? ",
        questionType: "RADIO_BUTTON",
        conditions: [],
        radioList: [{
          freeTextRequired: false,
          optionSummary: "y",
          optionTitle: "Yes",
        }, {
          freeTextRequired: false,
          optionSummary: "y",
          optionTitle: "No"
        }],
        slugValue: "do-you-do-smoking",
        typeValue: "global::question",
        keywords: ['smoking', 'male', '25 Yr - 50 Yr'],
      }
    ],
    linkedSections: [],
    keywords: ['pain', 'stomach'],
    isEditing: true
  }]
}

// const testMasterData = [
//   {
//     category: 'Physical Test',
//     tests: [
//       {
//         key: '1',
//         text: 'PULSE OXIMETER',
//         value: 'PULSE_OXIMETER',
//         rate: 160,
//         url: Pulse_oxi,
//       },
//       {
//         key: '2',
//         text: 'BLOOD PRESSURE',
//         value: 'BLOOD_PRESSURE',
//         rate: 160,
//         url: blood_pressure,
//       },
//       {
//         key: '3',
//         text: 'WEIGHT',
//         value: 'WEIGHT',
//         rate: 80,
//         url: Weight,
//       },
//       {
//         key: 'HEIGHT',
//         text: 'HEIGHT',
//         value: 'HEIGHT',
//         rate: 80,
//         url: Height,
//       },
//       {
//         key: '4',
//         text: 'TEMPERATURE',
//         value: 'TEMPERATURE',
//         rate: 120,
//         url: Temperature,
//       },
//       {
//         key: 'BMI',
//         text: 'BMI',
//         value: 'BMI',
//         rate: 120,
//         url: bmi,
//       },
//       {
//         key: 'ECG',
//         text: 'ECG',
//         value: 'ECG',
//         rate: 120,
//         url: ECG,
//       },
//       {
//         key: 'MID_ARM',
//         text: 'Mid-arm circumference',
//         value: 'MID_ARM',
//         rate: 120,
//         url: Mid_arm,
//       },
//     ],
//   },
//   {
//     category: 'Whole Blood POCT',
//     tests: [
//       {
//         key: 'BLOOD_GROUPING',
//         text: 'Blood Grouping',
//         value: 'BLOOD_GROUPING',
//         rate: 200,
//         url: blood_grouping,
//       },
//       {
//         key: '5',
//         text: 'Blood Glucose',
//         value: 'BLOOD_GLUCOSE',
//         rate: 200,
//         url: blood_glucose,
//       },
//       {
//         key: 'CHOLESTEROL',
//         text: 'Cholestrol',
//         value: 'CHOLESTEROL',
//         rate: 200,
//         url: Cholestrol,
//       },
//       {
//         key: 'HEMOCUE',
//         text: 'Hemocue',
//         value: 'HEMOCUE',
//         rate: 200,
//         url: Cholestrol,
//       },
//       {
//         key: 'HEMOGLOBIN',
//         text: 'Hemoglobin',
//         value: 'HEMOGLOBIN',
//         rate: 200,
//         url: HB,
//       },
//       {
//         key: 'URIC_ACID',
//         text: 'Uric Acid',
//         value: 'URIC_ACID',
//         rate: 200,
//         url: Uric_acid,
//       },
//       {
//         key: 'LIPID_PROFILE',
//         text: 'Lipid Profile',
//         value: 'LIPID_PROFILE',
//         rate: 200,
//         url: liquid_profile,
//       },
//     ],
//   },
//   {
//     category: 'Whole Blood RDT',
//     tests: [
//       {
//         key: 'TYPHOID',
//         text: 'Typhoid',
//         value: 'TYPHOID',
//         rate: 200,
//         url: Typhoid,
//       },
//       {
//         key: 'MALARIA',
//         text: 'Malaria',
//         value: 'MALARIA',
//         rate: 500,
//         url: Malaria,
//       },
//       {
//         key: 'HEPATITIS_B',
//         text: 'Hepatits B',
//         value: 'HEPATITIS_B',
//         rate: 200,
//         url: Hepa_B,
//       },
//       {
//         key: 'SYPHILIS',
//         text: 'Syphilis',
//         value: 'SYPHILIS',
//         rate: 200,
//         url: Syphillis,
//       },
//       {
//         key: 'HEPATITIS_C',
//         text: 'Hepatits C',
//         value: 'HEPATITIS_C',
//         rate: 200,
//         url: Hepa_C,
//       },
//       {
//         key: 'HIV',
//         text: 'HIV',
//         value: 'HIV',
//         rate: 200,
//         url: HIV,
//       },
//       {
//         key: 'DENGUE_ANTIGEN',
//         text: 'Dengue Antigen',
//         value: 'DENGUE_ANTIGEN',
//         rate: 180,
//         url: Dengue,
//       },
//       {
//         key: 'CHIKUNGUNYA',
//         text: 'Chikungunya',
//         value: 'CHIKUNGUNYA',
//         rate: 200,
//         url: Chikangunya,
//       },
//       {
//         key: 'TROPONIN_1',
//         text: 'Troponin 1',
//         value: 'TROPONIN_1',
//         rate: 200,
//         url: Troponin,
//       },
//       {
//         key: 'D-DIMER',
//         text: 'D-Dimer',
//         value: 'D-DIMER',
//         rate: 200,
//         url: d_dimer,
//       },
//       {
//         key: 'CRP',
//         text: 'CRP',
//         value: 'CRP',
//         rate: 200,
//         url: crp,
//       },
//       {
//         key: 'LEPTOSPIRA',
//         text: 'Leptospira',
//         value: 'LEPTOSPIRA',
//         rate: 200,
//         url: laptospira,
//       },
//     ],
//   },
//   {
//     category: 'Urine Tests',
//     tests: [
//       {
//         key: 'PREGNANCY',
//         text: 'Pregnancy',
//         value: 'PREGNANCY',
//         rate: 200,
//         url: Pregnancy,
//       },
//       {
//         key: 'URINE_2P',
//         text: 'Urine 2P',
//         value: 'URINE',
//         rate: 200,
//         url: Urine_2P,
//       },
//     ],
//   },
//   {
//     category: 'Hematology',
//     tests: [
//       {
//         key: '19',
//         text: 'WBC DIFF',
//         value: 'WBC_DIFF',
//         rate: 200,
//         url: wbc,
//       },
//     ],
//   },
// ];



const countryCodes = ['IND', 'UK', 'US']

const templateTypes = ['Complaint', 'Examination', 'Observation', 'Advice', 'Instruction', 'Letter']
// const quesTypes = ['MULTI_LINE','SINGLE_LINE','CHECK_BOX','RADIO_BUTTON','NUMBER','DATE','FILE','LIST','SELECT']
const quesTypes = ['MULTI_LINE', 'SINGLE_LINE', 'CHECK_BOX', 'RADIO_BUTTON', 'NUMBER', 'DATE', 'FILE']

const quesTypes2 = [
  {
    label: 'Free Text',
    val: 'FREE_TEXT'
  },
  {
    label: 'Multiple Selection',
    val: 'CHECK_BOX'
  },
  {
    label: 'Single Selection',
    val: 'RADIO_BUTTON'
  },
  {
    label: 'Number',
    val: 'NUMBER'
  },
  {
    label: 'Date',
    val: 'DATE'
  },
  {
    label: 'File',
    val: 'FILE'
  },
]

const sampleQuestions = [{
  ques: 'Abnormal Swelling or growth',
  response: ['Present', 'Absent '],
  ansType: 'radio',
  key: '1',
  validation: ['Male', '20-30']
},
{
  ques: 'Any associated symptoms with foot pain?',
  response: ['Fever', 'Tingling and burning sensation', 'Dizziness', 'Fainting', 'Other'],
  ansType: 'checkbox',
  key: '2',
  validation: ['Female', '20-30']
},
{
  ques: 'Acute distress',
  response: ['Yes', 'No'],
  ansType: 'radio',
  key: '3',
  validation: ['All', '10-15']
}]

const prefixValues = ['Mr', 'Ms', 'Mrs'];

const maritalStatusValues = ['MARRIED', 'UNMARRIED', 'OTHER'];

const genderValues = ['MALE', 'FEMALE', 'OTHER']

const speciality = ['General Medicine', 'Cardiology', 'General Surgery', 'Orthopedics', 'Pediatrics', 'Dentistry', 'Dermatology']

const visitType = [{ title: 'New', val: 'NEW' }, { title: 'Follow Up', val: 'FOLLOW' }, { title: 'Telephonic', val: 'TELEPHONIC' }, { title: 'Tele Consultation', val: 'TELE_CONSULTATION' },{ title: 'Consultation Video ', val: 'CONSULTATION_VIDEO ' }, { title: 'Followup Video', val: 'FOLLOWUP_VIDEO' }, { title: 'Consultation In Person', val: 'CONSULTATION_IN_PERSON' }, { title: 'Followup In Person', val: 'FOLLOWUP_IN_PERSON' }, ];

const weeks = [
  { selected: false, val: 1 },
  { selected: false, val: 2 },
  { selected: false, val: 3 },
  { selected: false, val: 4 },
  { selected: false, val: 5 },
  { selected: false, val: 6 },
];

const months = [
  { month: 'January', val: 0, selected: false },
  { month: 'February', val: 1, selected: false },
  { month: 'March', val: 2, selected: false },
  { month: 'April', val: 3, selected: false },
  { month: 'May', val: 4, selected: false },
  { month: 'June', val: 5, selected: false },
  { month: 'July', val: 6, selected: false },
  { month: 'August', val: 7, selected: false },
  { month: 'September', val: 8, selected: false },
  { month: 'October', val: 9, selected: false },
  { month: 'November', val: 10, selected: false },
  { month: 'December', val: 11, selected: false }];

const days = [
  { day: 'Sun', selected: false, val: 0 },
  { day: 'Mon', selected: true, val: 1 },
  { day: 'Tue', selected: true, val: 2 },
  { day: 'Wed', selected: true, val: 3 },
  { day: 'Thu', selected: true, val: 4 },
  { day: 'Fri', selected: true, val: 5 },
  { day: 'Sat', selected: false, val: 6 }];

const interval = ['15 min', '20 min', '25 min', '30 min', '35 min', '40 min', '45 min'];

const chiefComplaints = ['Exhaustion',
  'Flatulence',
  'Fainting',
  'Heartburn',
  'Indigestion',
  'Abnormal nails',
  'Changes in appetite	',
  'Fatigue',
  'Weight loss',
  'Nausea',
  'Twitching',
  'Slow healing wound',
  'Polyphagia',
  'Blood pressure',
  'Bodyaches',
  'Breathlessness on exertion',
  'Heaviness of chest	',
  'Coughing that lasts three or more weeks',
  'haemoptysis',
  'Night sweats',
  'Loss of appetite',
  'Fever',
  'Joint symptom and complaint NOS',
  'Nausea',
  'Pain general and multiple sites	',
  'Weakness and tiredness general',
  'Visual disturbance other',
  'Pressure and tightness of heart',
  'Fever high grade since morning',
  'D01 Abdominal pain cramps general',
  'Appendicitis',
  'Inguinal hernia',
  'Hiatal hernia	',
  'Cholecystitis',
  'Peptic ulcer	',
  'Hepatomegaly',
  'Fever',
  'Itching',
  'Red Eyes',
  'Left Hand Pain',
  'Joint Pain',
  'Vomiting',
  'Acne',
  'Irregular Vaginal Bleeding',
  'Abdominal Pain',
  'Vaginal discharge',
  'Cough',
  'Back Pain',
  'Knee Pain',
  'Antenatal screening',
  'Headache',
  'Anal swelling',
  'Menstruation absent or scanty',
  'Menstruation excessive/Heavy Periods	',
  'Nipple discharge',
  'Painful intercourse',
  'Painful Periods/Intermenstrual bleeding',
  'Palpitations',
  'Pelvic pain',
  'Penile pain',
  'Penile Ulceration/Sores',
  'Polyuria (Excessive urination)']

const examinations = [
  'Red Eyes',
  'Swelling on hand',
  'Swelling on Right Leg',
  'Swelling on Leg',
  'Petechia spots seen	',
  'III looking',
  'Conscious	',
  'Semi conscious',
  'Unconscious',
  'Skin Rash',
  'Cold Clammy Skin',
  'Warm Skin',
  'Calf Tenderness	',
  'Clubbing',
  'Cyanosis',
  'Pallor Present'
]

const advice = [
  '  Do exercise	',
  'Take rest',
  'Take meal on proper time',
  'Avoid spicy food',
  'Avoid commercially packed foods	',
  'Avoid hydogenated fats',
  'Do not skip meals	',
  'Eat more fruits',
  'Eat more vegetables	',
  'Avoid stress',
  'Avoid Exertion	',
  'Maintain healthy weight',
  'Tepid sponging',
  'Avoid allergens if known'
]

const diagnosis = [
  'Cholera',
  'Cholera due to Vibrio cholerae',
  'Cholera due to Vibrio cholerae',
  'Cholera',
  'Typhoid and paratyphoid fevers',
  'Typhoid fever	',
  'Typhoid fever',
  '	Typhoid meningitis'
]

const hobies = [
  'alcohol',
  'Tobacco',
  'Hookah',
  'Tea',
  'Smoking',
  'Excercise',
  'Drugs',
]

const allergies = [
  {
    allergy: 'Food Allergy',
    subAll: [
      'Mango Rice',
      'Curd',
      'Curd Rice'
    ]
  },
  {
    allergy: 'Environment',
    subAll: [
      'Perfume',
      'Rashes',
      'Dust Mites'
    ]
  },
  {
    allergy: 'Medicine',
    subAll: [
      'Calpol',
      'Flagyl',
      'Cipla'
    ]
  },
  {
    allergy: 'Animal',
    subAll: [
      'Dog',
      'Cat',
    ]
  },
  {
    allergy: 'Pollen',
    subAll: [
      'Pollen Grains',
    ]
  },
  {
    allergy: 'Metal',
    subAll: [
      'Nickel Allergy',
    ]
  }
]

const severity = [
  'Anaphylaxis',
  'Mild',
  'Moderate',
  'Potentially Life Threatening ',
  'Severe',
  'Toxicosis'
]

const specialityList = [
  {
    slugName: "allergy-and-immunology",
    speciality: "Allergy and Immunology",
    value: "Allergy and Immunology",
    subSpeciality: [
      {
        slugName: "allergy-and-immunology",
        subspeciality: "Allergy and Immunology",
        value: "Allergy and Immunology",
        desc: "Allergy Specialist"
      }
    ]
  },
  {
    slugName: "anesthesiology-and-pain-management",
    speciality: "Anesthesiology and Pain Management",
    value: "Anesthesiology and Pain Management",
    subSpeciality: [
      {
        slugName: "anesthesiology",
        subspeciality: "Anesthesiology",
        value: "Anesthesiology"
      },
      {
        slugName: "critical-care-medicine",
        subspeciality: "Critical Care Medicine",
        value: "Critical Care Medicine"
      },
      {
        slugName: "obstetric-anesthesiology",
        subspeciality: "Obstetric Anesthesiology",
        value: "Obstetric Anesthesiology"
      },
      {
        slugName: "pain-medicine",
        subspeciality: "Pain Medicine",
        value: "Pain Medicine"
      },
      {
        slugName: "pediatric-anesthesiology",
        subspeciality: "Pediatric Anesthesiology",
        value: "Pediatric Anesthesiology"
      }
    ]
  },
  {
    slugName: "ayurved-and-panchakarma",
    speciality: "Ayurved and Panchakarma",
    value: "Ayurved and Panchakarma",
    subSpeciality: [{
      slugName: "ayurved-and-panchakarma",
      subspeciality: "Ayurved and Panchakarma",
      value: "Ayurved and Panchakarma"
    }]
  },
  {
    slugName: "cardiology",
    speciality: "Cardiology",
    value: "Cardiology",
    subSpeciality: [
      {
        slugName: "Cardiology",
        subspeciality: "Cardiology",
        value: "Cardiology"
      },
      {
        slugName: "fetal-cardiology",
        subspeciality: "Fetal Cardiology",
        value: "Fetal Cardiology"
      },
      {
        slugName: "cardiac-physiologist",
        subspeciality: "Cardiac Physiologist",
        value: "Cardiac Physiologist"
      },
      {
        slugName: "interventional-cardiology",
        subspeciality: "Interventional Cardiology",
        value: "Interventional Cardiology"
      },
    ]
  },
  {
    slugName: "dematology",
    speciality: "Dematology",
    value: "Dematology",
    subSpeciality: [
      {
        slugName: "dermatology",
        subspeciality: "Dermatology",
        value: "Dermatology"
      },
      {
        slugName: "pediatric-dermatology",
        subspeciality: "Pediatric Dermatology",
        value: "Pediatric Dermatology"
      },
      {
        slugName: "procedural-dermatology",
        subspeciality: "Procedural Dermatology",
        value: "Procedural Dermatology"
      }
    ]
  },
  {
    slugName: "diagnostics-radiology",
    speciality: "Diagnostics Radiology",
    value: "Diagnostics Radiology",
    subSpeciality: [
      {
        slugName: "abdominal-radiology",
        subspeciality: "Abdominal Radiology",
        value: "Abdominal Radiology"
      },
      {
        slugName: "breast-imaging",
        subspeciality: "Breast Imaging",
        value: "Breast Imaging"
      },
      {
        slugName: "cardiothoracic-radiology",
        subspeciality: "Cardiothoracic Radiology",
        value: "Cardiothoracic Radiology"
      },
      {
        slugName: "cardiovascular-radiology",
        subspeciality: "Cardiovascular Radiology",
        value: "Cardiovascular Radiology"
      },
      {
        slugName: "chest-radiology",
        subspeciality: "Chest Radiology",
        value: "Chest Radiology"
      },
      {
        slugName: "emergency-radiology",
        subspeciality: "Emergency Radiology",
        value: "Emergency Radiology"
      },
      {
        slugName: "endovascular-surgical-neuroradiology",
        subspeciality: "Endovascular Surgical Neuroradiology",
        value: "Endovascular Surgical Neuroradiology"
      },
      {
        slugName: "gastrointestinal-radiology",
        subspeciality: "Gastrointestinal Radiology",
        value: "Gastrointestinal Radiology"
      },
      {
        slugName: "genitourinary-radiology",
        subspeciality: "Genitourinary Radiology",
        value: "Genitourinary Radiology"
      },
      {
        slugName: "head-and-neck-radiology",
        subspeciality: "Head and Neck Radiology",
        value: "Head and Neck Radiology"
      },
      {
        slugName: "interventional-radiology",
        subspeciality: "Interventional Radiology",
        value: "Interventional Radiology"
      },
      {
        slugName: "musculoskeletal-radiology",
        subspeciality: "Musculoskeletal Radiology",
        value: "Musculoskeletal Radiology"
      },
      {
        slugName: "neuroradiology",
        subspeciality: "Neuroradiology",
        value: "Neuroradiology"
      },
      {
        slugName: "nuclear-radiology",
        subspeciality: "Nuclear Radiology",
        value: "Nuclear Radiology"
      },
      {
        slugName: "pediatric-radiology",
        subspeciality: "Pediatric Radiology",
        value: "Pediatric Radiology"
      },
      {
        slugName: "vascular-and-interventional-radiology",
        subspeciality: "Vascular and Interventional Radiology",
        value: "Vascular and Interventional Radiology"
      },
    ]
  },
  {
    slugName: "diet-&-nutrition",
    speciality: "Diet & Nutrition",
    value: "Diet & Nutrition",
    subSpeciality: [{
      slugName: "diet-&-nutrition",
      subspeciality: "Diet & Nutrition",
      value: "Diet & Nutrition"
    }]
  },
  {
    slugName: "ear-nose-throat",
    speciality: "Ear Nose Throat",
    value: "Ear Nose Throat",
    subSpeciality: [{
      slugName: "ear-nose-throat",
      subspeciality: "Ear Nose Throat",
      value: "Ear Nose Throat"
    }]
  },
  {
    slugName: "emergency-medicine",
    speciality: "Emergency Medicine",
    value: "Emergency Medicine",
    subSpeciality: [
      {
        slugName: "emergency-medicine",
        subspeciality: "Emergency Medicine",
        value: "Emergency Medicine"
      },
      {
        slugName: "pediatric-emergency-medicine",
        subspeciality: "Pediatric Emergency Medicine ",
        value: "Pediatric Emergency Medicine "
      },
      {
        slugName: "undersea-and-hyperbaric-medicine",
        subspeciality: "Undersea and Hyperbaric Medicine",
        value: "Undersea and Hyperbaric Medicine"
      }
    ]
  },
  {
    slugName: "endocrinology-Metabolism-&-Diabetes",
    speciality: "Endocrinology, Metabolism & Diabetes",
    value: "Endocrinology, Metabolism & Diabetes",
    subSpeciality: [
      {
        slugName: "endocrinology",
        subspeciality: "Endocrinology",
        value: "Endocrinology"
      },
      {
        slugName: "diabetology",
        subspeciality: "Diabetology",
        value: "Diabetology"
      },
    ]
  },
  {
    slugName: "gastroenterology",
    speciality: "Gastroenterology",
    value: "Gastroenterology",
    subSpeciality: [
      {
        slugName: "gastroenterology",
        subspeciality: "Gastroenterology",
        value: "Gastroenterology"
      },
      {
        slugName: "gastroenterology-&-hepatology",
        subspeciality: "Gastroenterology & Hepatology",
        value: "Gastroenterology & Hepatology"
      },
    ]
  },
  {
    slugName: "general-medicine",
    speciality: "General Medicine",
    value: "General Medicine",
    subSpeciality: [
      {
        slugName: "general-medicine",
        subspeciality: "General Medicine",
        value: "General Medicine"
      },
    ]
  },
  {
    slugName: "geriatrics",
    speciality: "Geriatrics",
    value: "Geriatrics",
    subSpeciality: [
      {
        slugName: "geriatrics",
        subspeciality: "Geriatrics",
        value: "Geriatrics"
      },
    ]
  },
  {
    slugName: "hematology",
    speciality: "Hematology",
    value: "Hematology",
    subSpeciality: [
      {
        slugName: "hematology",
        subspeciality: "Hematology",
        value: "Hematology"
      },
    ]
  },
  {
    slugName: "homeopathy",
    speciality: "Homeopathy",
    value: "Homeopathy",
    subSpeciality: [
      {
        slugName: "homeopathy",
        subspeciality: "Homeopathy",
        value: "Homeopathy"
      },
    ]
  },
  {
    slugName: "infectious-disease",
    speciality: "Infectious Disease",
    value: "Infectious Disease",
    subSpeciality: [
      {
        slugName: "infectious-disease",
        subspeciality: "Infectious Disease",
        value: "Infectious Disease"
      },
    ]
  },
  {
    slugName: "intensive-care-medicine",
    speciality: "Intensive Care Medicine",
    value: "Intensive Care Medicine",
    subSpeciality: [
      {
        slugName: "intensive-care-medicine",
        subspeciality: "Intensive Care Medicine",
        value: "Intensive Care Medicine"
      },
      {
        slugName: "paediatric-intensive-care-medicine",
        subspeciality: "Paediatric Intensive Care Medicine",
        value: "Paediatric Intensive Care Medicine"
      },
    ]
  },
  {
    slugName: "internal-medicine",
    speciality: "Internal Medicine",
    value: "Internal Medicine",
    subSpeciality: [
      {
        slugName: "internal-medicine",
        subspeciality: "Internal Medicine",
        value: "Internal Medicine"
      },
      {
        slugName: "internal-medicine-pediatrics",
        subspeciality: "Internal Medicine-Pediatrics",
        value: "Internal Medicine-Pediatrics"
      },
    ]
  },
  {
    slugName: "interventional-radiology",
    speciality: "Interventional Radiology",
    value: "Interventional Radiology",
    subSpeciality: [
      {
        slugName: "interventional-radiology",
        subspeciality: "Interventional Radiology",
        value: "Interventional Radiology"
      },
    ]
  },
  {
    slugName: "medical-genetics",
    speciality: "Medical Genetics",
    value: "Medical Genetics",
    subSpeciality: [
      {
        slugName: "biochemical-genetics",
        subspeciality: "Biochemical Genetics",
        value: "Biochemical Genetics"
      },
      {
        slugName: "clinical-cytogenetics",
        subspeciality: "Clinical Cytogenetics",
        value: "Clinical Cytogenetics"
      },
      {
        slugName: "clinical-genetics",
        subspeciality: "Clinical Genetics",
        value: "Clinical Genetics"
      },
      {
        slugName: "molecular-genetic-pathology",
        subspeciality: "Molecular Genetic Pathology",
        value: "Molecular Genetic Pathology"
      },
    ]
  },
  {
    slugName: "nephrology",
    speciality: "Nephrology",
    value: "Nephrology",
    subSpeciality: [
      {
        slugName: "nephrology",
        subspeciality: "Nephrology",
        value: "Nephrology"
      },
      {
        slugName: "pediatric-nephrology",
        subspeciality: "Pediatric Nephrology",
        value: "Pediatric Nephrology"
      }
    ]
  },
  {
    slugName: "neurology",
    speciality: "Neurology",
    value: "Neurology",
    subSpeciality: [
      {
        slugName: "neurology",
        subspeciality: "Neurology",
        value: "Neurology",
      },
      {
        slugName: "brain-injury-medicine",
        subspeciality: "Brain Injury Medicine",
        value: "Brain Injury Medicine"
      },
      {
        slugName: "clinical-neurophysiology",
        subspeciality: "Clinical Neurophysiology",
        value: "Clinical Neurophysiology"
      },
      {
        slugName: "endovascular-surgical-neuroradiology",
        subspeciality: "Endovascular Surgical Neuroradiology",
        value: "Endovascular Surgical Neuroradiology"
      },
      {
        slugName: "neurodevelopmental-disabilities",
        subspeciality: "Neurodevelopmental Disabilities",
        value: "Neurodevelopmental Disabilities"
      },
      {
        slugName: "neuromuscular-medicine",
        subspeciality: "Neuromuscular Medicine",
        value: "Neuromuscular Medicine"
      },
      {
        slugName: "pain-medicine",
        subspeciality: "Pain Medicine",
        value: "Pain Medicine"
      },
      {
        slugName: "pediatric-neurology",
        subspeciality: "Pediatric Neurology",
        value: "Pediatric Neurology"
      },
      {
        slugName: "vascular-neurology",
        subspeciality: "Vascular Neurology",
        value: "Vascular Neurology"
      }
    ]
  },
  {
    slugName: "nuclear-medicine",
    speciality: "Nuclear Medicine",
    value: "Nuclear Medicine",
    subSpeciality: [
      {
        slugName: "nuclear-medicine",
        subspeciality: "Nuclear Medicine",
        value: "Nuclear Medicine"
      }
    ]
  },
  {
    slugName: "obstetrics-&-gynaecology",
    speciality: "Obstetrics & Gynaecology",
    value: "Obstetrics & Gynaecology",
    subSpeciality: [
      {
        slugName: "obstetrics-&-gynaecology",
        subspeciality: "Obstetrics & Gynaecology",
        value: "Obstetrics & Gynaecology"
      },
      {
        slugName: "female-pelvic-medicine-and-reconstructive-surgery",
        subspeciality: "Female Pelvic Medicine and Reconstructive Surgery",
        value: "Female Pelvic Medicine and Reconstructive Surgery"
      },
      {
        slugName: "gynaecology",
        subspeciality: "Gynaecology",
        value: "Gynaecology"
      },
      {
        slugName: "maternal-fetal-medicine",
        subspeciality: "Maternal-Fetal Medicine",
        value: "Maternal-Fetal Medicine"
      },
      {
        slugName: "obstetrics",
        subspeciality: "Obstetrics",
        value: "Obstetrics"
      },
      {
        slugName: "reproductive-endocrinology-and-infertility",
        subspeciality: "Reproductive Endocrinology and Infertility",
        value: "Reproductive Endocrinology and Infertility"
      },
      {
        slugName: "gynaecological-oncology",
        subspeciality: "Gynaecological Oncology",
        value: "Gynaecological Oncology"
      },
      {
        slugName: "urogynaecology",
        subspeciality: "Urogynaecology",
        value: "Urogynaecology"
      },
    ]
  },
  {
    slugName: "occupational-medicine",
    speciality: "Occupational Medicine",
    value: "Occupational Medicine",
    subSpeciality: [
      {
        slugName: "occupational-medicine",
        subspeciality: "Occupational Medicine",
        value: "Occupational Medicine"
      },
    ]
  },
  {
    slugName: "oncology",
    speciality: "Oncology",
    value: "Oncology",
    subSpeciality: [
      {
        slugName: "medical-oncology",
        subspeciality: "Medical Oncology",
        value: "Medical Oncology"
      },
      {
        slugName: "ocular-oncology",
        subspeciality: "Ocular Oncology",
        value: "Ocular Oncology"
      },
      {
        slugName: "radiation-oncology",
        subspeciality: "Radiation Oncology",
        value: "Radiation Oncology"
      },
      {
        slugName: "surgical-oncology",
        subspeciality: "Surgical Oncology",
        value: "Surgical Oncology"
      },
      {
        slugName: "urologic-oncology",
        subspeciality: "Urologic Oncology",
        value: "Urologic Oncology"
      },
      {
        slugName: "hematology-oncology",
        subspeciality: "Hematology Oncology",
        value: "Hematology Oncology"
      },
    ]
  },
  {
    slugName: "ophthalmology",
    speciality: "Ophthalmology",
    value: "Ophthalmology",
    subSpeciality: [
      {
        slugName: "ophthalmology",
        subspeciality: "Ophthalmology",
        value: "Ophthalmology"
      },
      {
        slugName: "anterior-segment/cornea-ophthalmology",
        subspeciality: "Anterior Segment/Cornea ophthalmology",
        value: "Anterior Segment/Cornea ophthalmology"
      },
      {
        slugName: "glaucoma-ophthalmology",
        subspeciality: "Glaucoma Ophthalmology",
        value: "Glaucoma Ophthalmology"
      },
      {
        slugName: "neuro-ophthalmology",
        subspeciality: "Neuro-Ophthalmology",
        value: "Neuro-Ophthalmology"
      },
      {
        slugName: "ophthalmic-plastic-&-reconstructive-surgery",
        subspeciality: "Ophthalmic Plastic & Reconstructive Surgery",
        value: "Ophthalmic Plastic & Reconstructive Surgery"
      },
      {
        slugName: "ophthalmology",
        subspeciality: "Ophthalmology",
        value: "Ophthalmology"
      },
      {
        slugName: "strabismus/pediatric-ophthalmology",
        subspeciality: "Strabismus/Pediatric Ophthalmology",
        value: "Strabismus/Pediatric Ophthalmology"
      },
    ]
  },
  {
    slugName: "oral/dental-surgery",
    speciality: "Oral/Dental Surgery",
    value: "Oral/Dental Surgery",
    subSpeciality: [
      {
        slugName: "oral/dental-surgery",
        subspeciality: "Oral/Dental Surgery",
        value: "Oral/Dental Surgery"
      },

    ]
  },
  {
    slugName: "orthopaedics",
    speciality: "Orthopaedics",
    value: "Orthopaedics",
    subSpeciality: [
      {
        slugName: "orthopaedics",
        subspeciality: "Orthopaedics",
        value: "Orthopaedics"
      },
      {
        slugName: "adult-reconstructive-orthopaedics",
        subspeciality: "Adult Reconstructive Orthopaedics",
        value: "Adult Reconstructive Orthopaedics"
      },
      {
        slugName: "orthopaedic-sports-medicine",
        subspeciality: "Orthopaedic Sports Medicine",
        value: "Orthopaedic Sports Medicine"
      },
      {
        slugName: "pediatric-orthopaedics",
        subspeciality: "Pediatric Orthopaedics",
        value: "Pediatric Orthopaedics"
      },
    ]
  },
  {
    slugName: "otolaryngology",
    speciality: "Otolaryngology",
    value: "Otolaryngology",
    subSpeciality: [
      {
        slugName: "otolaryngology",
        subspeciality: "Otolaryngology",
        value: "Otolaryngology"
      },
      {
        slugName: "otology-neurotology",
        subspeciality: "Otology - Neurotology",
        value: "Otology - Neurotology"
      },
      {
        slugName: "pediatric-otolaryngology",
        subspeciality: "Pediatric Otolaryngology",
        value: "Pediatric Otolaryngology"
      },
    ]
  },
  {
    slugName: "otorhinolaryngology",
    speciality: "Otorhinolaryngology",
    value: "Otorhinolaryngology",
    subSpeciality: [
      {
        slugName: "otorhinolaryngology",
        subspeciality: "Otorhinolaryngology",
        value: "Otorhinolaryngology"
      },
    ]
  },
  {
    slugName: "palliative-medicine",
    speciality: "Palliative Medicine",
    value: "Palliative Medicine",
    subSpeciality: [
      {
        slugName: "palliative-medicine",
        subspeciality: "Palliative Medicine",
        value: "Palliative Medicine"
      },
    ]
  },
  {
    slugName: "pediatrics",
    speciality: "Pediatrics",
    value: "Pediatrics",
    subSpeciality: [
      {
        slugName: "pediatrics",
        subspeciality: "Pediatrics",
        value: "Pediatrics"
      },
      {
        slugName: "adolescent-medicine",
        subspeciality: "Adolescent Medicine",
        value: "Adolescent Medicine"
      },
      {
        slugName: "child-abuse-pediatrics",
        subspeciality: "Child Abuse Pediatrics",
        value: "Child Abuse Pediatrics"
      },
      {
        slugName: "developmental-behavioral-pediatrics",
        subspeciality: "Developmental-Behavioral Pediatrics",
        value: "Developmental-Behavioral Pediatrics"
      },
      {
        slugName: "neonatal-perinatal-medicine",
        subspeciality: "Neonatal-Perinatal Medicine",
        value: "Neonatal-Perinatal Medicine"
      },
      {
        slugName: "neonatology",
        subspeciality: "Neonatology",
        value: "Neonatology"
      },
      {
        slugName: "pediatric-cardiology",
        subspeciality: "Pediatric Cardiology",
        value: "Pediatric Cardiology"
      },
      {
        slugName: "pediatric-critical-care-medicine",
        subspeciality: "Pediatric Critical Care Medicine",
        value: "Pediatric Critical Care Medicine"
      },
      {
        slugName: "pediatric-emergency-medicine",
        subspeciality: "Pediatric Emergency Medicine",
        value: "Pediatric Emergency Medicine"
      },
      {
        slugName: "pediatric-endocrinology",
        subspeciality: "Pediatric Endocrinology",
        value: "Pediatric Endocrinology"
      },
      {
        slugName: "pediatric-gastroenterology",
        subspeciality: "Pediatric Gastroenterology",
        value: "Pediatric Gastroenterology"
      },
      {
        slugName: "pediatric-hematology-oncology",
        subspeciality: "Pediatric Hematology-Oncology",
        value: "Pediatric Hematology-Oncology"
      },
      {
        slugName: "paediatric-gastroenterology-and-hepatology",
        subspeciality: "Paediatric Gastroenterology and Hepatology",
        value: "Paediatric Gastroenterology and Hepatology"
      },
      {
        slugName: "pediatric-infectious-diseases",
        subspeciality: "Pediatric Infectious Diseases",
        value: "Pediatric Infectious Diseases"
      },
      {
        slugName: "pediatric-nephrology",
        subspeciality: "Pediatric Nephrology",
        value: "Pediatric Nephrology"
      },
      {
        slugName: "pediatric-pulmonology",
        subspeciality: "Pediatric Pulmonology",
        value: "Pediatric Pulmonology"
      },
      {
        slugName: "pediatric-rheumatology",
        subspeciality: "Pediatric Rheumatology",
        value: "Pediatric Rheumatology"
      },
      {
        slugName: "pediatric-sports-medicine",
        subspeciality: "Pediatric Sports Medicine",
        value: "Pediatric Sports Medicine"
      },
      {
        slugName: "pediatric-transplant-hepatology",
        subspeciality: "Pediatric Transplant Hepatology",
        value: "Pediatric Transplant Hepatology"
      },
      {
        slugName: "pediatric-allergy-immunology",
        subspeciality: "Pediatric Allergy-Immunology",
        value: "Pediatric Allergy-Immunology"
      },
      {
        slugName: "pediatric-otolaryngology",
        subspeciality: "Pediatric Otolaryngology",
        value: "Pediatric Otolaryngology"
      },
      {
        slugName: "pediatric-radiology",
        subspeciality: "Pediatric Radiology",
        value: "Pediatric Radiology"
      },
      {
        slugName: "pediatric-respiratory-medicine",
        subspeciality: "Pediatric Respiratory Medicine",
        value: "Pediatric Respiratory Medicine"
      },
      {
        slugName: "pediatric-urology",
        subspeciality: "Pediatric Urology",
        value: "Pediatric Urology"
      },
    ]
  },
  {
    slugName: "physiology",
    speciality: "Physiology",
    value: "Physiology",
    subSpeciality: [
      {
        slugName: "physiology",
        subspeciality: "Physiology",
        value: "Physiology"
      },
      {
        slugName: "electrophysiology",
        subspeciality: "Electrophysiology",
        value: "Electrophysiology"
      },
    ]
  },
  {
    slugName: "physiotherapy-&-rehabilitation",
    speciality: "Physiotherapy & Rehabilitation",
    value: "Physiotherapy & Rehabilitation",
    subSpeciality: [
      {
        slugName: "neuromuscular-medicine",
        subspeciality: "Neuromuscular Medicine",
        value: "Neuromuscular Medicine"
      },
      {
        slugName: "physiotherapy",
        subspeciality: "Physiotherapy",
        value: "Physiotherapy"
      },
      {
        slugName: "rehabilitation-medicine",
        subspeciality: "Rehabilitation Medicine",
        value: "Rehabilitation Medicine"
      },
      {
        slugName: "pediatric-rehabilitation-medicine",
        subspeciality: "Pediatric Rehabilitation Medicine",
        value: "Pediatric Rehabilitation Medicine"
      },
      {
        slugName: "spinal-cord-injury-medicine",
        subspeciality: "Spinal Cord Injury Medicine",
        value: "Spinal Cord Injury Medicine"
      },
      {
        slugName: "paediatric-neuro-rehabilitation",
        subspeciality: "Paediatric Neuro Rehabilitation",
        value: "Paediatric Neuro Rehabilitation"
      },
    ]
  },
  {
    slugName: "preventive-medicine",
    speciality: "Preventive Medicine",
    value: "Preventive Medicine",
    subSpeciality: [
      {
        slugName: "aerospace-medicine",
        subspeciality: "Aerospace Medicine",
        value: "Aerospace Medicine"
      },
      {
        slugName: "medical-toxicology",
        subspeciality: "Medical Toxicology",
        value: "Medical Toxicology"
      },
      {
        slugName: "preventive-medicine",
        subspeciality: "Preventive Medicine",
        value: "Preventive Medicine"
      },
      {
        slugName: "public-health-medicine",
        subspeciality: "Public Health Medicine",
        value: "Public Health Medicine"
      },
    ]
  },
  {
    slugName: "psychiatry",
    speciality: "Psychiatry",
    value: "Psychiatry",
    subSpeciality: [
      {
        slugName: "addiction-psychiatry",
        subspeciality: "Addiction Psychiatry",
        value: "Addiction Psychiatry"
      },
      {
        slugName: "administrative-psychiatry",
        subspeciality: "Administrative Psychiatry",
        value: "Administrative Psychiatry"
      },
      {
        slugName: "child-and-adolescent-psychiatry",
        subspeciality: "Child and Adolescent Psychiatry",
        value: "Child and Adolescent Psychiatry"
      },
      {
        slugName: "community-psychiatry",
        subspeciality: "Community Psychiatry",
        value: "Community Psychiatry"
      },
      {
        slugName: "consultation/liaison-psychiatry",
        subspeciality: "Consultation/Liaison Psychiatry",
        value: "Consultation/Liaison Psychiatry"
      },
      {
        slugName: "emergency-psychiatry",
        subspeciality: "Emergency Psychiatry",
        value: "Emergency Psychiatry"
      },
      {
        slugName: "forensic-psychiatry",
        subspeciality: "Forensic Psychiatry",
        value: "Forensic Psychiatry"
      },
      {
        slugName: "geriatric-psychiatry",
        subspeciality: "Geriatric Psychiatry",
        value: "Geriatric Psychiatry"
      },
      {
        slugName: "mental-retardation-psychiatry",
        subspeciality: "Mental Retardation Psychiatry",
        value: "Mental Retardation Psychiatry"
      },
      {
        slugName: "military-psychiatry",
        subspeciality: "Military Psychiatry",
        value: "Military Psychiatry"
      },
      {
        slugName: "psychiatry",
        subspeciality: "Psychiatry",
        value: "Psychiatry"
      },
      {
        slugName: "psychosomatic-medicine",
        subspeciality: "Psychosomatic Medicine",
        value: "Psychosomatic Medicine"
      },
    ]
  },
  {
    slugName: "psychology",
    speciality: "Psychology",
    value: "Psychology",
    subSpeciality: [
      {
        slugName: "clinical-psychology",
        subspeciality: "Clinical Psychology",
        value: "Clinical Psychology"
      },
    ]
  },
  {
    slugName: "pulmonary-medicine",
    speciality: "Pulmonary Medicine",
    value: "Pulmonary Medicine",
    subSpeciality: [
      {
        slugName: "pulmonary-medicine",
        subspeciality: "Pulmonary Medicine",
        value: "Pulmonary Medicine"
      },
    ]
  },
  {
    slugName: "respiratory-&-sleep-medicine",
    speciality: "Respiratory & Sleep Medicine",
    value: "Respiratory & Sleep Medicine",
    subSpeciality: [
      {
        slugName: "sleep-medicine",
        subspeciality: "Sleep Medicine",
        value: "Sleep Medicine"
      },
      {
        slugName: "respiratory-medicine",
        subspeciality: "Respiratory medicine",
        value: "Respiratory medicine"
      },


    ]
  },
  {
    slugName: "radiology",
    speciality: "Radiology",
    value: "Radiology",
    subSpeciality: [
      {
        slugName: "neuroradiology",
        subspeciality: "Neuroradiology",
        value: "Neuroradiology"
      },
      {
        slugName: "nuclear-medicine",
        subspeciality: "Nuclear Medicine",
        value: "Nuclear Medicine"
      },
      {
        slugName: "paediatric-radiology",
        subspeciality: "Paediatric Radiology",
        value: "Paediatric Radiology"
      },
      {
        slugName: "interventional-radiology",
        subspeciality: "Interventional Radiology",
        value: "Interventional Radiology"
      },
      {
        slugName: "cardiac-radiology",
        subspeciality: "Cardiac Radiology",
        value: "Cardiac Radiology"
      },
      {
        slugName: "breast-imaging",
        subspeciality: "Breast Imaging",
        value: "Breast Imaging"
      },


    ]
  },

  {
    slugName: "respiratory-medicine",
    speciality: "Respiratory medicine",
    value: "Respiratory medicine",
    subSpeciality: [
      {
        slugName: "rheumatology",
        subspeciality: "Rheumatology",
        value: "Rheumatology"
      },
    ]
  },
  {
    slugName: "sexual-&-reproductive-medicine",
    speciality: "Sexual & Reproductive Medicine",
    value: "Sexual & Reproductive Medicine",
    subSpeciality: [
      {
        slugName: "sexual-&-reproductive-medicine",
        subspeciality: "Sexual & Reproductive Medicine",
        value: "Sexual & Reproductive Medicine"
      },
    ]
  },
  {
    slugName: "sport-&-exercise-medicine",
    speciality: "Sport & Exercise Medicine",
    value: "Sport & Exercise Medicine",
    subSpeciality: [
      {
        slugName: "sport-&-exercise-medicine",
        subspeciality: "Sport & Exercise Medicine ",
        value: "Sport & Exercise Medicine "
      },
      {
        slugName: "yoga-holistic-healing",
        subspeciality: "Yoga - Holistic Healing",
        value: "Yoga - Holistic Healing"
      },

    ]
  },
  {
    slugName: "urology",
    speciality: "Urology",
    value: "Urology",
    subSpeciality: [
      {
        slugName: "female-urology",
        subspeciality: "Female Urology",
        value: "Female Urology"
      },
      {
        slugName: "male-infertility",
        subspeciality: "Male Infertility",
        value: "Male Infertility"
      },
      {
        slugName: "neurourology",
        subspeciality: "Neurourology",
        value: "Neurourology"
      },
      {
        slugName: "pediatric-urology",
        subspeciality: "Pediatric Urology",
        value: "Pediatric Urology"
      },
      {
        slugName: "renal-transplant",
        subspeciality: "Renal Transplant",
        value: "Renal Transplant"
      },
      {
        slugName: "calculi",
        subspeciality: "Calculi",
        value: "Calculi"
      },
      {
        slugName: "urology",
        subspeciality: "Urology",
        value: "Urology"
      },
    ]
  },
  {
    slugName: "surgery",
    speciality: "Surgery",
    value: "Surgery",
    subSpeciality: [
      {
        slugName: "bariatric-surgery",
        subspeciality: "Bariatric Surgery",
        value: "Bariatric Surgery"
      },
      {
        slugName: "breast-cancer-surgery",
        subspeciality: "Breast Cancer Surgery",
        value: "Breast Cancer Surgery"
      },
      {
        slugName: "cardio-thoracic-surgery",
        subspeciality: "Cardio-thoracic surgery",
        value: "Cardio-thoracic surgery"
      },
      {
        slugName: "cardiovascular-surgery",
        subspeciality: "Cardiovascular Surgery",
        value: "Cardiovascular Surgery"
      },
      {
        slugName: "colon-and-rectal-surgery",
        subspeciality: "Colon and Rectal Surgery",
        value: "Colon and Rectal Surgery"
      },
      {
        slugName: "congenital-cardiac-surgery",
        subspeciality: "Congenital Cardiac Surgery",
        value: "Congenital Cardiac Surgery"
      },
      {
        slugName: "maxillofacial-surgery",
        subspeciality: "Maxillofacial Surgery",
        value: "Maxillofacial Surgery"
      },
      {
        slugName: "endovascular-surgical-neuroradiology",
        subspeciality: "Endovascular Surgical Neuroradiology",
        value: "Endovascular Surgical Neuroradiology"
      },
      {
        slugName: "gastrointestinal-surgery",
        subspeciality: "Gastrointestinal Surgery",
        value: "Gastrointestinal Surgery"
      },
      {
        slugName: "general-and-minimal-access-surgery",
        subspeciality: "General and Minimal Access Surgery",
        value: "General and Minimal Access Surgery"
      },
      {
        slugName: "general-surgery",
        subspeciality: "General Surgery",
        value: "General Surgery"
      },
      {
        slugName: "hand-surgery",
        subspeciality: "Hand Surgery",
        value: "Hand Surgery"
      },
      {
        slugName: "head-and-neck-surgery",
        subspeciality: "Head and Neck Surgery",
        value: "Head and Neck Surgery"
      },
      {
        slugName: "hepato-pancreatico-biliary-(hpb)-surgery",
        subspeciality: "Hepato-Pancreatico-Biliary (HPB) Surgery",
        value: "Hepato-Pancreatico-Biliary (HPB) Surgery"
      },
      {
        slugName: "robotic-surgery",
        subspeciality: "Robotic Surgery",
        value: "Robotic Surgery"
      },
      {
        slugName: "neurosurgery",
        subspeciality: "Neurosurgery",
        value: "Neurosurgery"
      },
      {
        slugName: "surgical-oncology",
        subspeciality: "Surgical Oncology",
        value: "Surgical Oncology"
      },
      {
        slugName: "gastrointestinal-&-colorectal-surgery",
        subspeciality: "Gastrointestinal & Colorectal Surgery",
        value: "Gastrointestinal & Colorectal Surgery"
      },
      {
        slugName: "oral-and-maxillofacial-surgery",
        subspeciality: "Oral and maxillofacial surgery",
        value: "Oral and maxillofacial surgery"
      },
      {
        slugName: "podiatric-surgery",
        subspeciality: "Podiatric Surgery",
        value: "Podiatric Surgery"
      },
      {
        slugName: "orthopaedics-surgery",
        subspeciality: "Orthopaedics surgery",
        value: "Orthopaedics surgery"
      },
      {
        slugName: "plastic-&-Reconstructive-Surgery",
        subspeciality: "Plastic & Reconstructive Surgery",
        value: "Plastic & Reconstructive Surgery"
      },
      {
        slugName: "plastic-surgery",
        subspeciality: "Plastic Surgery",
        value: "Plastic Surgery"
      },
      {
        slugName: "thoracic-surgery",
        subspeciality: "Thoracic Surgery",
        value: "Thoracic Surgery"
      },
      {
        slugName: "transplant-surgery",
        subspeciality: "Transplant Surgery",
        value: "Transplant Surgery"
      },
      {
        slugName: "thoracic-surgery-integrated",
        subspeciality: "Thoracic Surgery-Integrated",
        value: "Thoracic Surgery-Integrated"
      },
      {
        slugName: "upper-gi-surgery",
        subspeciality: "Upper GI Surgery",
        value: "Upper GI Surgery"
      },
      {
        slugName: "vascular-surgery",
        subspeciality: "Vascular surgery",
        value: "Vascular surgery"
      },
    ]
  },
]
