import React, { createContext, useReducer, useState, useEffect , useContext} from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { MainReducer } from './reducers/mainReducer'
import { OrgInfoContext } from '../../context/orgInfoContext';
import { AuthContext } from '../../context/authContext';
import * as reducer from './reducers/mainReducer';
import * as queries from '../../graphql/queries';
import { returnDateWithFormat } from '../common/functions';
import moment from 'moment';



export const initialState = {
    loading: false,
    appointmentsMonth:[],
    docId: null,
    docInfo: null
  };


  const GetAppointmentsCustQuery = async (props, variables) => {
    return await new Promise((res, rej) => {
      props.client.query({
        query: queries.getAppointmentsByDocByMonth,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        res(data)
      }).catch((err) => {
        rej(err)
      })
    })
  }


const MainStoreWProvider=(props)=>{
    const [state, dispatch] = useReducer(MainReducer, initialState);
    const context = useContext(AuthContext);
    const orgContext = useContext(OrgInfoContext);

    const { docId, loading, docInfo, appointmentsMonth } = state;


    //----------------------- doctor data - start
    const setdocId=(res)=>{
        dispatch({
            type: reducer.SET_DOCID,
            payload: res
        })
    }

    const setdocInfo=(res)=>{
        dispatch({
            type: reducer.SET_DOCINFO,
            payload: res
        })
    }

    useEffect(() => {
        if (orgContext.docInfo != null) {
          setdocId(orgContext.docInfo.email)
        }
    
        if (orgContext.docInfo != null) {
          setdocInfo(orgContext.docInfo)
        }
      }, [orgContext]);
    //-------------------- doctor data - end



    //--------------- appointment data -monthly - start
      useEffect(() => {
        if (docId != null) {
            let dd = returnDateWithFormat(moment(), 'month');
            getAppointsDataForMonth(dd)
        }
      }, [docId]);

      const setMonthlyApptData=(res)=>{
        dispatch({
            type: reducer.SET_APPT_MONTHLY,
            payload: res
        })
      }
    //--------------- appointment data -monthly - end




    const getAppointsDataForMonth = (month) => {
        let variables = {
          doctorId: docId,
          month: month,
        };
        GetAppointmentsCustQuery(props, variables)
          .then((res) => {
            if (res.data && res.data.getAppointmentsByDocByMonth && res.data.getAppointmentsByDocByMonth != null) {
              setMonthlyApptData(res.data.getAppointmentsByDocByMonth)
            //   createDataSource(res.data.getAppointmentsByDocByMonth, selectedStatus)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }


    return(
        <MainContext.Provider 
        value={{ 
            state,
            getAppointsDataForMonth
         }}
        >
            {props.children}
        </MainContext.Provider>
    )
}


const MainStoreWrapper = withRouter(withApollo(MainStoreWProvider));

export const MainContext = createContext(initialState);

export {
    MainStoreWrapper
}