import React from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import MainSidebar from './sidebar';
import { Link } from 'react-router-dom';
import logo from '../../assets/CHT_logo_color.png'

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const MainMenu=(props)=>{
    return(
        <Menu
        // theme="dark"
        mode="horizontal"
        defaultSelectedKeys={['2']}
        style={{ lineHeight: '64px' }}
      >
        <Menu.Item key="1"> 
         <Link to='/'>
                <div className="logo" >
                  <img src={logo} style={{ width: 180, height: 'auto', marginBottom: 20 }} />
                </div>
              </Link>
              
              </Menu.Item>
        <Menu.Item key="2">nav 2</Menu.Item>
        <Menu.Item key="3">nav 3</Menu.Item>
      </Menu>
    )
}

export default MainMenu