import React, { useEffect, useState, Suspense } from 'react';
import Amplify, { Auth } from "aws-amplify";
import DoctorAdmin from './components/admins/doctorAdmin/app'
import AuthLayout from './components/authentication/AuthLayout';
import { Spin } from 'antd';
import { AuthContext } from './context/authContext';
import { getDoctorById, listDoctorsByOrg, getUnitOrg } from './graphql/queries'
import { withApollo } from 'react-apollo';
import { OrgInfoContext } from './context/orgInfoContext';
import GetDocByOrg from './components/admins/doctorAdmin/manageServices/funGetDocByOrg'
import { listDoctorsByOrgCust } from './graphql/customQuery'
import { docBasicDetails } from './graphql/fragments'
import gql from 'graphql-tag';
import { getDoctorUnitMappingsQuery, updateCogUnitIdMut } from './components/common/graphqlFunctions'
import AWS from 'aws-sdk'
import { configVariables } from './configs/configs'
import VideoCall from './components/admins/doctorAdmin/coverSheet/videCall'



var jwtDecode = require('jwt-decode');

const GetDocDetails = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: getDoctorById,
      variables: variables,
      fetchPolicy: 'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}


const getUnitQuery = async (props, variables) => {
  return await new Promise((res, rej) => {
    props.client.query({
      query: getUnitOrg,
      fetchPolicy: 'no-cache'
    }).then((data) => {
      res(data)
    }).catch((err) => {
      console.log(err)
      rej(err)
    })
  })
}


const getAuthData = async () => {
  let res = await Auth.currentAuthenticatedUser();

  var sessionIdInfo = jwtDecode(res.signInUserSession.idToken.jwtToken);
  // console.log(res)

  let attr = res.attributes;
  var orgId = attr['custom:orgId'] ? attr['custom:orgId'] : attr['custom:orgId']
  var unitId = attr['custom:unitId'];
  var fqdn = attr['custom:fqdn'];


  return {
    orgId: orgId,
    unitId: unitId,
    fqdn: fqdn,
    userId: sessionIdInfo.email,
    phone_number: sessionIdInfo.phone_number,
    groups: sessionIdInfo['cognito:groups'],
    sessionIdInfo: sessionIdInfo,
    user: res
  };
}

const App = (props) => {
  const [user, setuser] = useState(null)
  const [data, setdata] = useState(null)
  const [unitData, setunitData] = useState(null)
  const [speciality, setspeciality] = useState(null)
  const [arr, setarr] = useState([])
  const [resData, setresData] = useState(null)
  const [docList, setdocList] = useState([])
  const [isDoctor, setisDoctor] = useState(false)
  const [doctorUnitMapping, setdoctorUnitMapping] = useState([])



  useEffect(async () => {
    let res = await getAuthData();
    console.log(res);

      const forgetUser=(u)=>{
        // u.getDevice(
        //   {
        //     onSuccess: function (result) {
        //       console.log('call result: ' , result);
        //   },
        //   onFailure: function(err) {
        //       // alert(err);
        //       console.log('call result: ',  err);
  
        //   }
        //   }
        // )
        // console.log('ab',u.storage['CognitoIdentityServiceProvider.7bktrqg0gik7lbt962givobjdq.admin.deviceKey'])
        u.deviceKey = u.storage['CognitoIdentityServiceProvider.7bktrqg0gik7lbt962givobjdq.admin.deviceKey']
      //  u.getCachedDeviceKeyAndPassword(
      //         {
      //       onSuccess: function (result) {
      //         console.log('call result: ' , result);
      //     },
      //     onFailure: function(err) {
      //         // alert(err);
      //         console.log('call result: ',  err);
  
      //     }
      //     }
      //   )
        u.setDeviceStatusNotRemembered({
          onSuccess: function (result) {
            console.log('call result: ' , result);
        },
        onFailure: function(err) {
            // alert(err);
            console.log('error: ',  err);

        }
        })
      }
    // forgetUser(res.user)


    setresData(res);

    let grs = [];
    grs = res.groups;
    setarr(grs)
    let adminFlag = false;
    if (grs.length == 1 && grs[0] == 'Admin') {
      adminFlag = true;
    }

    getUnitQuery(props)
      .then((res) => {
        if (res.data.getUnitOrg != null) {
          // console.log(res.data.getUnitOrg)
          setunitData(res.data.getUnitOrg)
        }

      }).catch((err) => {
        console.log(err)
      })

    // console.log(res)
    if (adminFlag == false) {
      let variables = { id: res.user.attributes.email }
      GetDocDetails(props, variables).then((data) => {
        if (data.data.getDoctorById != null) {
          setisDoctor(true)
          setdata(data.data.getDoctorById);
          // getDocUnitMapping(data.data.getDoctorById)
          let docArr = [];
          docArr.push(data.data.getDoctorById)
          setdocList(docArr)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
    setuser(res)
  }, []);

  const rememberUser = () => {

  }

  const forgetUser = (u) => {
    // u.getDevice(
    //   {
    //     onSuccess: function (result) {
    //       console.log('call result: ' , result);
    //   },
    //   onFailure: function(err) {
    //       // alert(err);
    //       console.log('call result: ',  err);

    //   }
    //   }
    // )
    console.log('ab', u)
    u.getCachedDeviceKeyAndPassword(
      {
        onSuccess: function (result) {
          console.log('call result: ', result);
        },
        onFailure: function (err) {
          // alert(err);
          console.log('call result: ', err);

        }
      }
    )
    // u.setDeviceStatusNotRemembered({
    //   onSuccess: function (result) {
    //     console.log('call result: ' , result);
    // },
    // onFailure: function(err) {
    //     // alert(err);
    //     console.log('call result: ',  err);

    // }
    // })
  }


  useEffect(() => {
    const fetchDataAsync = async () => {
      GetDocByOrg(props).then((res) => {
        if (res) {
          // console.log('doclist',res)
          if (setdocList) {
            setdocList(res)
          }
          if (res.length > 0 && setdocList && res[0].speciality) {
            // console.log('setdocList', res)
            setdata(res[0])
            // getDocUnitMapping(res[0])
            setspeciality(res[0].speciality[0].subSpeciality)
          }
        }

      }).catch((err) => {
        console.log(err)
      })
    }
    //  if(context.isDoctor == false){
    if (arr.indexOf('UnitAdmin') != -1) {
      fetchDataAsync()
    }
  }, [user]);

  // console.log('resData',arr)

  const setDoctorIdOnChange = (val) => {
    setdata(val)

    // getDocUnitMapping(val)

    // if(docList.length > 0){
    //   let item = docList.find((item)=>item.email == val);
    //     if(item){
    //       setdata(item)
    //     }
    //   }
  }

  const setDoctorSpecialityOnChange = (val) => {
    setspeciality(val)
  }


  // ------ updating org doc list after creating doctor
  const updateDoctorList = () => {
    GetDocByOrg(props).then((res) => {
      if (res) {
        // console.log('doclist',res)
        if (setdocList) {
          setdocList(res)
        }
        if (res.length > 0 && setdocList) {
          setdata(res[0])
          // setspeciality(res[0].allocateClassification[0])
          setspeciality(res[0].speciality[0].subSpeciality)
        }
      }
    }).catch((err) => {
      console.log(err)
    })

  }

  const getDocUnitMapping = (doc) => {
    // console.log(doc)
    let variables = {
      email: doc.email
    }
    getDoctorUnitMappingsQuery(props, variables)
      .then(({ data }) => {

        setdoctorUnitMapping(data.getDoctorUnitMappings)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (data != null) {
      getDocUnitMapping(data)
    }
  }, [data])


  const changeDoctorUnit = (unitId) => {

    let variables = {
      unitId: unitId,
      username: resData.user.username
      // username: `${resData.fqdn}::${resData.user..email}`
    }
    updateCogUnitIdMut(props, variables)
      .then((res) => {

        if (res.data.updateCogUnitId == true) {
          // const RefreshToken =  resData.user.signInUserSession.refreshToken;
          // // console.log(resData.user.signInUserSession.refreshToken.token)
          // // resData.user.refreshSession()

          // resData.user.refreshSession(RefreshToken, (err, session) => {
          //   if (err) throw err;
          //   console.log(session)
          //   const tokens = getTokens(session);
          //   // AWS.config.credentials = getCognitoIdentityCredentials(tokens);
          //   AWS.config.credentials.get(function() {
          //     const credentials = AWS.config.credentials.data.Credentials;
          //     // req.session.AWSCredentials = getAWSCredentials(credentials);
          //     // next();
          //   });
          // })
          Auth.signOut().then((data) => {
            // console.log(data)
            window.location.href = '/'
            // window.location.reload(true)
          });
        }
      }).catch((err) => {
        console.log(err)
      })
  }

  const getTokens = function (session) {
    return {
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken()
    };
  };

  // const getCognitoIdentityCredentials = function(tokens) {
  //   const loginInfo = {};
  //   loginInfo[`cognito-idp.${AWS_REGION}.amazonaws.com/${COGNITO_USER_POOL_ID}`] = tokens.idToken;
  //   const params = {
  //     IdentityPoolId: COGNITO_IDENTITY_POOL_ID,
  //     Logins: loginInfo
  //   };
  //   return new AWS.CognitoIdentityCredentials(params);
  // };


  // const getAWSCredentials = function(credentials) {
  //   return {
  //     accessKey: credentials.AccessKeyId,
  //     secretKey: credentials.SecretKey,
  //     sessionToken: credentials.SessionToken,
  //     region: configVariables.REGION,
  //     // invokeUrl: 'https://' + AWS_API_GATEWAY_HOSTNAME
  //   };
  // };

  const forgetDeviceFun = () => {
    // console.log(resData)
    // console.log(resData.user.getDevice())
    resData.user.forgetDevice({
      onSuccess: function (result) {
        console.log('call result: ', result);
      },
      onFailure: function (err) {
        // alert(err);
        console.log('call result: ', err);

      }
    })


  }

  // console.log(unitData)  

  return (
    <Suspense fallback={<p>loading ..</p>}>
      <AuthContext.Provider value={{
        user: user,
        permissions: arr,
        data: data,
        resData: resData,
        isDoctor: isDoctor,
        doctorUnitMapping: doctorUnitMapping
      }}>
        <OrgInfoContext.Provider value={{
          isDoctor: isDoctor,
          docInfo: data,
          unitData: unitData,
          docList: docList,
          speciality: speciality,
          setDoctorIdOnChange: setDoctorIdOnChange,
          setDoctorSpecialityOnChange: setDoctorSpecialityOnChange,
          updateDoctorList: updateDoctorList,
          changeDoctorUnit: changeDoctorUnit,
          forgetDeviceFun: forgetDeviceFun,
          doctorUnitMapping: doctorUnitMapping
        }}>
          <DoctorAdmin />
          <VideoCall/>
        </OrgInfoContext.Provider>
      </AuthContext.Provider>
    </Suspense>
  );
}


export default withApollo(App);
// export default withTranslation()(App);
