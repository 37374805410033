import React , { useState, useContext, useEffect }from 'react';
import { Button, Card } from 'antd';
import TwilioPOC from './twilioPOC'
import WebRTC_POC from './webRTC_POC'
import { VideoCameraOutlined, PhoneOutlined, DashOutlined } from "@ant-design/icons";




const VideoCall=(props)=>{
  
    return (
   
        <WebRTC_POC/>
)
}

export default VideoCall;