import React, { Component } from 'react';

// export const ThemeContext = React.createContext({
//     theme1:'#00b386',
//     theme2:'#20232a'
// });

export const ThemeContext = React.createContext({
    // theme1:{bg:'#00b386',color:'#fff',accentColor:'#ff9200'},
    theme1:{bg:'#31c0d4',color:'#fff',accentColor:'#31c0d4',authBg:'white'},
    // theme1:{bg:'#20232a',color:'#fff',accentColor:'#03a9f5',authBg:'white'},
    // theme2:{bg:'#20232a',color:'#fff',accentColor:'#00b386',authBg:'white'},
});

// #0098de