import gql from 'graphql-tag';


export {
  docBasicDetails,
  docContactDetails,
  address
}



const docBasicDetails = gql`
  fragment docBasicDetails on Doctor {
    id
     orgId
     prefix
    fName
    mName
    lName
    gender
    dob
    photo{
      bucket
      region
      key
    }
    active
    allocateClassification
    speciality{
      speciality
	    subSpeciality
    }
  }
`

const docContactDetails = gql`
  fragment docContactDetails on Doctor {
    email
    countryCode
    mobile
    phoneNo
  }
`

const address = gql`
  fragment address on Doctor {
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
  }
`