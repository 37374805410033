import React, { Component } from 'react';


export const OrgInfoContext = React.createContext({
    docInfo:null,
    docList:[],
    currency:'INR',
    countryCodeMob:'+91',
    unitCode:null,
    setDoctorIdOnChange:null,
    countryCode:'IND'
});
