import gql from 'graphql-tag';

export {
  createOrg,
  createUnit,
  updateUnit,
  addDoctorToUnit,
  updateCogUnitId,
  createDoctor,
  updateDoctor,
  deleteDoctor,
  createPatient2,
  updatePatient,
  deletePatient,
  addPatientToOrg,
  createConsultationService,
  updateConsultationService,
  deleteConsultationService,
  createSchedule,
  createPatientCasesheet,
  createAppointment,
  cancelAppointment,
  deleteAppointment,
  checkedInAppointment,
  checkedOutAppointment,
  createPayment,
  updateBill,
  createQuestionTemplate,
  updateQuestionTemplate,
  deleteQuestionTemplate,
  createSectionTemplate,
  updateSectionTemplate,
  deleteSectionTemplate,
  createFormTemplate,
  updateFormTemplate,
  deleteFormTemplate,
  createFormAnswer,
  updateFormAnswer,
  createStaff,
  updateStaff,
  updateUnitTestConfig,
  deleteScheduleById,
  updateSchedule,
  createReferralDoctor,
  updatedReferralDoctor,
  createReasonMaster,
  updateReasonMaster,
  updateEncounter,
  addMedicationToMyList,
  removeMedicationToMyList,
  addComplaintsToMyList,
  removeComplaintsToMyList,
  addDiagnosisToMyList,
  removeDiagnosisToMyList,
  cancelAppointmentOnScheduleUpdate,
  updatePatientPastHistory,
  createPharmacyRegistration,
  updatePharmacyRegistration,
  createLabRegistration,
  updateLabRegistration,
  shareCasesheetReport,
  createInventory,
  updateInventory,
  deleteInventory,
  addComplaintsToMyListByAdmin,
  addDiagnosisToMyListByAdmin,
  addAdviceToMyList,
  removeAdviceToMyList,
  addAllergyToMyList,
  removeAllergyToMyList,
  addPhysicalExaminationToMyList,
  removePhysicalExaminationToMyList,
  removeHabitToMyList,
  addHabitToMyList,
  updateEmailComm,
  updatePaymentOption
}

const updatePaymentOption = gql`
mutation updatePaymentOption($active: Boolean! $paymentOptions: [PaymentOptionInput] ){
  updatePaymentOption(input:{
    active: $active
    paymentOptions: $paymentOptions
  }){
    orgId
    typeValue
    active
    updatedAt
    paymentOptions{
      paymentType
      id
      barcode{
        bucket
        region
        key
      }
    }
  }
}
# {
#   "active": true,
#   "paymentOptions": [{
#     "paymentType": "CASH"
#   },
#     {
#       "paymentType": "UPI",
#       "id": "3ederf",
#       "barcode": {
#         "bucket": "ds",
#         "key": "ds",
#         "region": "dsd"
#       }
#     }
#   ]
# }

# enum PAYMENT_OPTION {
#   CASH
#   UPI
#   CHEQUE
#   DEBIT_CREDIT
# }
`

const updateEmailComm=gql`
mutation updateEmailComm( $email: String!){
    updateEmailComm(email: $email){
      orgId
      typeValue
      email
      updatedAt
    }
  }
`


  
const deleteInventory = gql`
mutation deleteInventory($typeValue: String! $code: String!){
  deleteInventory(typeValue: $typeValue code: $code){
    typeValue
    code
    name
  }
}
# {
#   "typeValue": "global",
#   "code": "c2"
# }
`


const updateInventory = gql`
mutation updateInventory(
  	$typeValue: String!
    $code: String!
    $name: String
    $description: String
    $totalStock: Int
    $currentStock: Int
    $consumedStock: Int
    $adjustedStock: Int
){
  updateInventory(input:{
    typeValue: $typeValue
    code: $code
    name: $name
    description: $description
    totalStock: $totalStock
    currentStock: $currentStock
    consumedStock: $consumedStock
    adjustedStock: $adjustedStock
  }){
    id
    typeValue
    code
    name
    description
    totalStock
    currentStock
    consumedStock
    adjustedStock
    createdAt
    updatedAt
    updatedBy
  }
}
# {
#   "typeValue": "global",
#   "code": "c2",
#   "name": "hemoglobin",
#   "description": "test",
#   "totalStock": 10,
#   "currentStock": 0,
#   "consumedStock": 0,
#   "adjustedStock": 0
# }
`

const createInventory = gql`
mutation createInventory(
  	$typeValue: String!
    $code: String!
    $name: String!
    $description: String
    $totalStock: Int
    $currentStock: Int
    $consumedStock: Int
    $adjustedStock: Int
){
  createInventory(input:{
    typeValue: $typeValue
    code: $code
    name: $name
    description: $description
    totalStock: $totalStock
    currentStock: $currentStock
    consumedStock: $consumedStock
    adjustedStock: $adjustedStock
  }){
    id
    typeValue
    code
    name
    description
    totalStock
    currentStock
    consumedStock
    adjustedStock
    createdAt
    updatedAt
    updatedBy
  }
}
# {
#   "typeValue": "global",
#   "code": "c2",
#   "name": "hemoglobin",
#   "description": "ds",
#   "totalStock": 0,
#   "currentStock": 0,
#   "consumedStock": 0,
#   "adjustedStock": 0
# }
`

const shareCasesheetReport = gql`
mutation shareCasesheetReport($valueString: String!){ 
  shareCasesheetReport(valueString: $valueString)
}
`


const cancelAppointmentOnScheduleUpdate = gql`
mutation cancelAppointmentOnScheduleUpdate( $doctorId: String! $ids: [String!]!){
  cancelAppointmentOnScheduleUpdate(doctorId: $doctorId ids: $ids)
}
`;


const updatePatientPastHistory = gql`
mutation updatePatientPastHistory(
    $patientId: String!
    $lifeStyle: [LieStyleInput!]
    $familyHistory: [FamilyHistoryInput!]
    $surgicalHistory: [SurgicalHistoryInput!]
    $medicationHistory: [MedicationInput!]
    $medicalHistory: [MedicalHistoryInput!]
){
  updatePatientPastHistory(input:{
    patientId: $patientId
    lifeStyle: $lifeStyle
    familyHistory: $familyHistory
    surgicalHistory: $surgicalHistory
    medicationHistory: $medicationHistory
    medicalHistory: $medicalHistory
  }){
    patientId
    typeValue
    lifeStyle{
      description
      remark
    }
    familyHistory{
      diagnosis
      relation
      remark
    }
    surgicalHistory{
      surgery
      doctor
      date
      remark
    }
    medicationHistory{
      brandName
      genericName
      frequency
      dosage
      dosageUnit
      dosageDate
    }
    medicalHistory{
      icd
      remark
    }
    updatedAt
  }
}
# {
#   "patientId": "ash::gawai::female::2016-05-04",
#   "lifeStyle": {
#     "description": "sfsd",
#     "remark": "ds"
#   },
#   "familyHistory": {
#     "diagnosis": "dsf",
#     "relation": "bro",
#     "remark": "dsf"
#   },
#   "surgicalHistory": {
#     "surgery": "dsf",
#     "doctor": "fsdf",
#     "date": "dds",
#     "remark": "sdf"
#   },
#   "medicationHistory": {
#     "brandName": "dfs",
#     "genericName": "fsdf",
#     "frequency": "fsdf",
#     "dosage": "df",
#     "dosageUnit": "dfsd",
#     "dosageDate": "fd"
#   },
#   "medicalHistory": {
#     "icd": "fsd",
#     "remark": "fsd"
#   }
# }
`

const updateCogUnitId = gql`
mutation updateCogUnitId( $username: String! $unitId: String!){
  updateCogUnitId(username: $username unitId: $unitId)
}
# {
#   "unitId": "clinivantage-healthcare-pune",
#   "username": "clinivantage.pms.com::suzain@gmail.com"
# }
`


const addDoctorToUnit = gql`
mutation addDoctorToUnit( $unitId: String! $email: String!){
  addDoctorToUnit(unitId: $unitId email: $email)
}
# {
#   "unitId": "clinivantage-healthcare-mumbai",
#   "email": "suzain@gmail.com"
# }
`


const removeComplaintsToMyList = gql`
mutation removeComplaintsToMyList( $service: String! $serviceId: String!){
  removeComplaintsToMyList(service: $service serviceId: $serviceId)
}
# {
#   "service": "clinivantage-healthcare::complaints",
#   "serviceId": "khan.sohel005@gmail.com::1031"
# }
`;



const addComplaintsToMyList = gql`
mutation addComplaintsToMyList(
  $complaintId: String
  $complaint: String
){
  addComplaintsToMyList(input:{
    complaintId: $complaintId
    complaint: $complaint
  })
}
# {
#   "complaint": "polyphagia",
#   "complaintId": "141"
# }
`
// AddComplaintsToMyListByAdminInput
const  addComplaintsToMyListByAdmin =gql`
mutation  addComplaintsToMyListByAdmin(
  $email: String!
  $complaintId: String
  $complaint: String
){
   addComplaintsToMyListByAdmin(input:{
    email: $email
    complaintId: $complaintId
    complaint: $complaint
  })
}
`
const addDiagnosisToMyListByAdmin= gql`
mutation addDiagnosisToMyListByAdmin(
  $email: String!
  $code: String
  $name: String
){
  addDiagnosisToMyListByAdmin(input:{
    email: $email
    code: $code
    name: $name
  })
}
`

const removeMedicationToMyList = gql`
mutation removeMedicationToMyList( $service: String! $serviceId: String!){
  removeMedicationToMyList(service: $service serviceId: $serviceId)
}
# {
#   "service": "clinivantage-healthcare::medication",
#   "serviceId": "khan.sohel005@gmail.com::abies-canadensis-q::abies-canadensis-q"
# }
`
const removeDiagnosisToMyList = gql`
mutation removeDiagnosisToMyList( $service: String! $serviceId: String!){
  removeDiagnosisToMyList(service: $service serviceId: $serviceId)
}
# {
#   "service": "clinivantage-healthcare::diagnosis",
#   "serviceId": "khan.sohel005@gmail.com::T26.11"
# }
`;

const addDiagnosisToMyList = gql`
mutation addDiagnosisToMyList(
  $code: String
  $name: String
){
  addDiagnosisToMyList(input:{
    code: $code
    name: $name
  })
}
# {
#   "code": "T26.11",
#   "name": "acute post-traumatic headache, not intractable"
# }
`


const addMedicationToMyList = gql`
mutation addMedicationToMyList(
  $id: String
  $genericName: String
	$medicationName: String
){
  addMedicationToMyList(input:{
    id: $id
    genericName: $genericName
    medicationName: $medicationName
  })
}
# {
#   "id": "acebrophylline::ab-phylline-sr-200-tab-10",
#   "genericName": "acebrophylline",
#   "medicationName": "ab phylline sr 200 tab 10"
# }
`;



const updateUnit = gql`
mutation updateUnit(
  $fqdn: String!
  $unitId: String!
  $unitName: String!
  $email: String!
  $contactNo: String!
  $address: AddressInput!
  $pharmacyLicenseNo: String
  $clinicRegiNo: String
  $shopEstNo: String
  $tradeNo: String
  $isActive: Boolean!
  $skypeId:String
  $logo: S3ObjectInput
){
  updateUnit(input:{
    fqdn: $fqdn
    unitId: $unitId
    unitName: $unitName
    email: $email
    contactNo: $contactNo
    address: $address
    pharmacyLicenseNo: $pharmacyLicenseNo
    clinicRegiNo: $clinicRegiNo
    shopEstNo: $shopEstNo
    tradeNo: $tradeNo
    isActive: $isActive
    skypeId: $skypeId
    logo: $logo
  }){
    fqdn
    unitName
    orgId
    email
    contactNo
    pharmacyLicenseNo
    clinicRegiNo
    shopEstNo
    tradeNo
    isActive
    createdAt
    updatedAt
    skypeId
  }
}
# {
#   "fqdn": "unit::clinivantage.pms.com",
#   "unitId": "clinivantage-healthcare-baner",
#   "unitName": "Clinivantage Healthcare Baner",
#   "email": "cvbaner@clinivantage.com",
#   "contactNo": "+919028772221",
#   "pharmacyLicenseNo": "1111111111111",
#   "clinicRegiNo": "434343",
#   "shopEstNo": "42434567",
#   "tradeNo": "675754",
#   "isActive": true,
#   "address": {
#     "use": "WORK",
#     "type": "PHYSICAL",
#     "lineOne": "Tulsi Green",
#     "lineTwo": " Laxman Nagar Baner",
#     "city": "Pune",
#     "state": "Maharashtra",
#     "zip": "411045",
#     "country": "IND"
#   }
# }
`;


const updateEncounter = gql`
mutation updateEncounter(
  $patientId: String!
	$encounterId: String!
	$status: ENCOUNTER_STATUS 
){
  updateEncounter(input:{
    patientId: $patientId
    encounterId: $encounterId
    status: $status
  }){
    patientId
    encounterId
    apptId
    createdAt
    createdBy
    status
    updatedAt
  }
}
# {
#   "patientId": "clinivantage-healthcare::saquib::khan::male::2006-11-06",
#   "encounterId": "1596551741",
#   "status": "PENDING"
# }
`;


const updateSchedule = gql`
mutation updateSchedule(
  $scheduleId: String!
  $slots: [SlotInput]
){
updateSchedule(input:{
  scheduleId: $scheduleId
  slots: $slots
}){
  orgId
  scheduleId
  doctorId
  scheduleDate
  slots{
    start
    end
    aptType
  }
}
}
# {
#   "scheduleId": "khan.sohel005@gmail.com::2020-JUN-14",
#   "slots": [
#     {
#       "aptType": [
#         "general medicine new - Dr abs test3"
#       ],
#       "end": "13:00",
#       "start": "11:00"
#     },
#     {
#       "aptType": [
#         "general medicine new - Dr abs test3"
#       ],
#       "end": "18:00",
#       "start": "16:00"
#     }
#   ]
# }
`;

const deleteScheduleById = gql`
mutation deleteScheduleById( $scheduleId: String!){
  deleteScheduleById(scheduleId: $scheduleId)
}
# {
#   "scheduleId": "samadhan@gmail.cm::2020-JUN-06"
# }
`;

const updateUnitTestConfig = gql`
mutation updateUnitTestConfig(
  	$orgId: String!
    $deviceId: String!
    $active: Boolean!
	  $deployedAt: String
  	$physicalId: String!
    $deviceType: String!
    $categories: [TestCategoryInput!]
){
  updateUnitTestConfig(input:{
    orgId: $orgId
    deviceId: $deviceId
    physicalId: $physicalId
    deployedAt: $deployedAt
    active: $active
    deviceType: $deviceType
    categories: $categories
  }){
    orgId
    deviceId
    physicalId
    deployedAt
    active
    deviceType
    categories{
      category
      active
      tests{
        deviceName
        text
        value
        rate
        url{
          key
          bucket
          region
        }
        enabled
        active
      }
    }
  }
}



# {
#   "orgId": "clinivantage-healthcare",
#   "deviceId": "123",
#   "active": true,
#   "physicalId": "AS:7S:676",
#   "deployedAt": "clinivantage-healthcare-pune",
#   "deviceType": "ATM",
#   "categories": [
#     {
#       "category": "Physical Test",
#       "active": true,
#       "tests": [
#         {
    
#           "text": "PULSE OXIMETER",
#           "value": "PULSE_OXIMETER",
#           "rate": 160,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "BLOOD PRESSURE",
#           "value": "BLOOD_PRESSURE",
#           "rate": 160,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "WEIGHT",
#           "value": "WEIGHT",
#           "rate": 80,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "HEIGHT",
#           "value": "HEIGHT",
#           "rate": 80,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "TEMPERATURE",
#           "value": "TEMPERATURE",
#           "rate": 120,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "BMI",
#           "value": "BMI",
#           "rate": 120,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "ECG",
#           "value": "ECG",
#           "rate": 120,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Mid-arm circumference",
#           "value": "MID_ARM",
#           "rate": 120,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         }
#       ]
#     },
#     {
#       "category": "Whole Blood POCT",
#       "active": true,
#       "tests": [
#         {
    
#           "text": "Blood Grouping",
#           "value": "BLOOD_GROUPING",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Blood Glucose",
#           "value": "BLOOD_GLUCOSE",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Cholestrol",
#           "value": "CHOLESTEROL",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Hemocue",
#           "value": "HEMOCUE",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Hemoglobin",
#           "value": "HEMOGLOBIN",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Uric Acid",
#           "value": "URIC_ACID",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Lipid Profile",
#           "value": "LIPID_PROFILE",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         }
#       ]
#     },
#      {
#       "category": "Whole Blood RDT",
#       "active": true,
#       "tests": [
#         {
    
#           "text": "Typhoid",
#           "value": "TYPHOID",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Malaria",
#           "value": "MALARIA",
#           "rate": 500,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Hepatits B",
#           "value": "HEPATITIS_B",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Syphilis",
#           "value": "SYPHILIS",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Hepatits C",
#           "value": "HEPATITIS_C",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "HIV",
#           "value": "HIV",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Dengue Antigen",
#           "value": "DENGUE_ANTIGEN",
#           "rate": 180,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Chikungunya",
#           "value": "CHIKUNGUNYA",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Troponin 1",
#           "value": "TROPONIN_1",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "D-Dimer",
#           "value": "D-DIMER",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "CRP",
#           "value": "CRP",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Leptospira",
#           "value": "LEPTOSPIRA",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         }
#       ]
#     },
#     {
#       "category": "Urine Tests",
#       "active": true,
#       "tests": [
#         {
    
#           "text": "Pregnancy",
#           "value": "PREGNANCY",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         },
#         {
    
#           "text": "Urine 2P",
#           "value": "URINE",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         }
#       ]
#     },
#     {
#       "category": "Hematology",
#       "active": true,
#       "tests": [
#         {
    
#           "text": "WBC DIFF",
#           "value": "WBC_DIFF",
#           "rate": 200,
#           "url": "imag link",
#           "enabled": true,
#           "active": true
#         }
#       ]
#     }
#   ]
# }
`;

const updateStaff = gql`
mutation updateStaff(
        $prefix: String
        $fName: String!
        $mName: String
        $lName: String!
        $gender: Gender
        $email: String!
        $countryCode: String!
        $mobile: String!
        $photo: S3ObjectInput
        $dob: String!
        $maritalStatus: maritalStatus
        $address: AddressInput
        $userRole: String!
        $roleType: String
        $active: Boolean
        $isDoctor:Boolean
        $skypeId: String
){
  updateStaff(input:{
    prefix: $prefix
    fName: $fName
    mName: $mName
    lName: $lName
    gender: $gender
    email: $email
    countryCode: $countryCode
    mobile: $mobile
    photo: $photo
    dob: $dob
    maritalStatus: $maritalStatus
    address: $address
    userRole: $userRole
    roleType: $roleType
    active: $active
    isDoctor: $isDoctor
    skypeId: $skypeId
  }){
    orgId
    email
    prefix
    fName
    mName
    lName
    gender
    mobile
    dob
    maritalStatus
    photo{
      bucket
      region
      key
    }
    userRole
    roleType
    active
    isDoctor
    createdAt
    updatedAt
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    skypeId
  }
}
# {
#   "prefix": "Mr.",
#   "fName": "sameer",
#   "mName": "m.",
#   "lName": "shaikh",
#   "gender": "MALE",
#   "email": "sameer@gmail.com",
#   "countryCode": "+91",
#   "mobile": "917755999765",
#   "dob": "1994-10-28",
#   "photo": {
#     "bucket": "fdf",
#     "key": "dfd",
#     "region": "sds"
#   },
#   "maritalStatus": "UNMARRIED",
#   "userRole": "STAFF",
#   "roleType": "ATM_OPERATOR",
#   "active": true,
#   "isDoctor": false,
#   "address": {
#     "use": "HOME",
#     "type": "PHYSICAL",
#     "lineOne": "qwertyu",
#     "lineTwo": "asa",
#     "city": "pune",
#     "state": "maharashtra",
#     "zip": "411033",
#     "country": "india"
#   }
# }
`

const createStaff = gql`
mutation createStaff(
    $prefix: String
    $fName: String!
    $mName: String
    $lName: String!
    $gender: Gender
    $email: String!
    $countryCode: String!
    $mobile: String!
    $photo: S3ObjectInput
    $dob: String!
    $maritalStatus: maritalStatus
    $address: AddressInput
    $userRole: String!
    $roleType: String
    $active: Boolean
    $isDoctor:Boolean
    $skypeId: String
){
  createStaff(input:{
    prefix: $prefix
    fName: $fName
    mName: $mName
    lName: $lName
    gender: $gender
    email: $email
    countryCode: $countryCode
    mobile: $mobile
    photo: $photo
    dob: $dob
    maritalStatus: $maritalStatus
    address: $address
    userRole: $userRole
    roleType: $roleType
    active: $active
    isDoctor: $isDoctor
    skypeId: $skypeId
  }){
    orgId
    email
    prefix
    fName
    mName
    lName
    gender
    mobile
    dob
    maritalStatus
    photo{
      bucket
      region
      key
    }
    userRole
    roleType
    active
    isDoctor
    createdAt
    updatedAt
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    skypeId
  }
}
# {
#   "prefix": "Mr.",
#   "fName": "sameer",
#   "mName": "m.",
#   "lName": "shaikh",
#   "gender": "MALE",
#   "email": "sameer@gmail.com",
#   "countryCode": "+91",
#   "mobile": "917755999765",
#   "dob": "1994-10-28",
#   "photo": {
#     "bucket": "fdf",
#     "key": "dfd",
#     "region": "sds"
#   },
#   "maritalStatus": "UNMARRIED",
#   "userRole": "STAFF",
#   "roleType": "ATM_OPERATOR",
#   "active": true,
#   "isDoctor": false,
#   "address": {
#     "use": "HOME",
#     "type": "PHYSICAL",
#     "lineOne": "dfklhf",
#     "lineTwo": "sf",
#     "city": "pune",
#     "state": "maharashtra",
#     "zip": "411033",
#     "country": "india"
#   }
# }
`

const deleteConsultationService = gql`
mutation deleteConsultationService( $serviceId: String!){
  deleteConsultationService(serviceId: $serviceId){
    service
    serviceId
    doctorId
    serviceName
    type
    speciality
    duration
    cost
    createdAt
  }
}
# {
#   "serviceId": "clinivantage-healthcare-pune::khan.sohel005@gmail.com::general medicine followup"
# }
`;


const deletePatient = gql`
mutation deletePatient(
  $ordId:ID!
  $id:ID!
){
  deletePatient(input:{
	orgId:$ordId
    id:$id
  }){
   id
    patientId
    typeValue
  }
}
`;



const updateFormAnswer = gql`
mutation updateFormAnswer(
  $patientId: String
  $formId: String
  $doctorId: String
  $formData: [FormDataInput!]
){
  updateFormAnswer(input:{
    patientId: $patientId
    formId: $formId
    doctorId: $doctorId
    formData: $formData
  }){
    patientId
    formId
    doctorId
    createdAt
    updatedAt
    formData{
      queUId
      queTitle
      queType
      ansText
      ansSummary
      ansMap{
        bucket
        region
        key
      }
      ansList{
        ansText
        ansSummary
      }
    }
  }
}
# {
#   "patientId": "suzain::md::male::2006-11-06#1589632678",
#   "formId": "2796741a-c086-40ca-9632-54db1b2849b6",
#   "doctorId": "khan.sohel005@gmail.com",
#   "formData": [
#     {
#       "queUId": "58f38c09-6c4b-4d57-be75-27314cabcc45",
#       "queTitle": "What is your name",
#       "queType": "FREE_TEXT",
#       "ansText": "Suzain",
#       "ansSummary": "self name"
#     },
#     {
#       "queUId": "8190a9df-0223-41cc-88f0-2da68c082c95",
#       "queTitle": "DOB",
#       "queType": "DATE",
#       "ansText": "2006-11-26",
#       "ansSummary": null
#     },
#     {
#       "queUId": "fc718250-63f9-4649-9813-60b6ba1a9696",
#       "queTitle": "Photo",
#       "queType": "FILE",
#       "ansMap": {
#         "bucket": "sdf",
#         "key": "fsd",
#         "region": "fsfd"
#       },
#       "ansSummary": "photo"
#     },
#     {
#       "queUId": "173dcaed-afe9-4cdc-9873-b50feed1fbf0",
#       "queTitle": "Do you have question",
#       "queType": "RADIO_BUTTON",
#       "ansText": "Yes",
#       "ansSummary": "i have que"
#     }
#   ]
# }
`;


const createFormAnswer = gql`
mutation createFormAnswer(
  $patientId: String
  $formId: String
  $doctorId: String
  $formData: [FormDataInput!]
){
  createFormAnswer(input:{
    patientId: $patientId
    formId: $formId
    doctorId: $doctorId
    formData: $formData
  }){
    patientId
    formId
    doctorId
    createdAt
    updatedAt
    formData{
      queUId
      queTitle
      queType
      ansText
      ansSummary
      ansMap{
        bucket
        region
        key
      }
      ansList{
        ansText
        ansSummary
      }
    }
  }
}
# {
#   "patientId": "suzain::md::male::2006-11-06#1589632678",
#   "formId": "2796741a-c086-40ca-9632-54db1b2849b6",
#   "doctorId": "khan.sohel005@gmail.com",
#   "formData": [
#     {
#       "queUId": "58f38c09-6c4b-4d57-be75-27314cabcc45",
#       "queTitle": "What is your name",
#       "queType": "FREE_TEXT",
#       "ansText": "Suzain",
#       "ansSummary": "self name"
#     },
#     {
#       "queUId": "8190a9df-0223-41cc-88f0-2da68c082c95",
#       "queTitle": "DOB",
#       "queType": "DATE",
#       "ansText": "2006-11-26",
#       "ansSummary": null
#     },
#     {
#       "queUId": "fc718250-63f9-4649-9813-60b6ba1a9696",
#       "queTitle": "Photo",
#       "queType": "FILE",
#       "ansMap": {
#         "bucket": "sdf",
#         "key": "fsd",
#         "region": "fsfd"
#       },
#       "ansSummary": "photo"
#     },
#     {
#       "queUId": "173dcaed-afe9-4cdc-9873-b50feed1fbf0",
#       "queTitle": "Do you have question",
#       "queType": "RADIO_BUTTON",
#       "ansText": "Yes",
#       "ansSummary": "i have que"
#     }
#   ]
# }
`;



const deleteSectionTemplate = gql`
mutation deleteSectionTemplate($typeValue: String! $slugValue: String!){
  deleteSectionTemplate(typeValue: $typeValue slugValue: $slugValue){
    typeValue
    slugValue
    sectionName
    
  }
}
# {
#   "typeValue": "global::section",
#   "slugValue": "my-section-2"
# }
`;



const deleteFormTemplate = gql`
mutation deleteFormTemplate($typeValue: String! $slugValue: String!){
  deleteFormTemplate(typeValue: $typeValue slugValue: $slugValue){
    typeValue
    slugValue
    templateName
    templateType
  }
}
# {
#   "typeValue": "global::template",
#   "slugValue": "my-form-1"
# }
`;


const updateFormTemplate = gql`
mutation updateFormTemplate(
  $typeValue: String!
  $slugValue: String!
  $templateName: String!
  $templateType: String!
  $subTemplate: String
  $sectionRefs: [String!]!
  $active: Boolean
){
  updateFormTemplate(input:{
    typeValue: $typeValue
    slugValue: $slugValue
    templateName: $templateName
    templateType: $templateType
    subTemplate: $subTemplate
    sectionRefs: $sectionRefs
    active: $active
  }){
    typeValue
    slugValue
    templateName
    templateType
    subTemplate
    sectionRefs
    active
    createdAt
    updatedAt
  }
}
# {
#   "typeValue": "global::template",
#   "slugValue": "my-form",
#   "templateName": "My Form",
#   "templateType": "Physical Examination",
#   "subTemplate": null,
#   "sectionRefs": ["global::section#my-section","global::section#my-section-1"],
#   "active": true
# }
`;


const updateSectionTemplate = gql`
mutation updateSectionTemplate(
  $typeValue: String!
  $slugValue: String!
  $sectionName: String!
  $questionRefs: [String!]!
  $active: Boolean
){
  updateSectionTemplate(input:{
    typeValue: $typeValue
    slugValue: $slugValue
    sectionName: $sectionName
    questionRefs: $questionRefs
    active: $active
  }){
    typeValue
    slugValue
    sectionName
    questionRefs
    active
    createdAt
    updatedAt
  }
}
# {
#   "typeValue": "global::section",
#   "slugValue": "my-section",
#   "sectionName": "My Section",
#   "questionRefs": ["global::question#do-you-have-question","global::question#photo"],
#   "active": true
# }
`


const deleteQuestionTemplate = gql`
mutation deleteQuestionTemplate( $typeValue: String! $slugValue: String!){
  deleteQuestionTemplate(typeValue: $typeValue slugValue: $slugValue){
    typeValue
    slugValue
    questionTitle
    questionType
  }
}
# {
#   "typeValue": "global::question",
#   "slugValue": "dob"
# }
`;


const createReferralDoctor = gql`   
mutation createReferralDoctor(
    $referralName: String!
    $faxNo: String
    $email: String!
    $mobile: String!
    $address: AddressInput
    $referralType: RreferralType!
    $isActive: Boolean
){
  createReferralDoctor(input:{
    referralName: $referralName
    faxNo: $faxNo
    email: $email
    mobile: $mobile
    referralType: $referralType
    address: $address
    isActive: $isActive
  }){
    orgId
    referralId
    referralName
    faxNo
    email
    mobile
    createdBy
    createdAt
    referralType
    isActive
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
  }
}
# {
#   "referralName": "Sohel khan",
#   "faxNo": "564785676",
#   "email": "khan.sohel005@gmail.com",
#   "mobile": "8459832342",
#   "referralType": "CONSULTANT",
#   "address": {
#     "use": "WORK",
#     "type": "BOTH",
#     "lineOne": "qwerty",
#     "lineTwo": "dfds",
#     "city": "pune",
#     "state": "maharashtra",
#     "zip": "411033",
#     "country": "IND"
#   }
# }
`;





const updateQuestionTemplate = gql`
mutation updateQuestionTemplate(
  $typeValue: String!
  $slugValue: String!
  $questionTitle: String!
  $questionType: QUESTION_TYPE!
  $freeText: FreeTextInput
  $number: NumberInput
  $date: DateInput
  $file: FileInput
  $radioList: [RadioListInput!]
  $checkboxList: [CheckboxListInput!]
  $ageFrom: Int
  $ageTo: Int
  $appliedFor: Gender_For
  $active: Boolean
  $isRequired: Boolean
){
  updateQuestionTemplate(input:{
    typeValue: $typeValue
    slugValue: $slugValue
    questionTitle: $questionTitle
    questionType: $questionType
    freeText: $freeText
    number: $number
    date: $date
    file: $file
    radioList: $radioList
    checkboxList: $checkboxList
    ageFrom: $ageFrom
    ageTo: $ageTo
    appliedFor: $appliedFor
    active: $active
    isRequired: $isRequired
  }){
    typeValue
    slugValue
    questionTitle
    questionType
    uId
    ageFrom
    ageTo
    appliedFor
    isRequired
    createdAt
    updatedAt
    active
    freeText{
      answerSummary
      freeTextRequired
    }
    number{
      answerSummary
      freeTextRequired
    }
    date{
      answerSummary
      freeTextRequired
    }
    file{
      answerSummary
      freeTextRequired
    }
    radioList{
      optionTitle
      optionSummary
      freeTextRequired
    }
    checkboxList{
      optionTitle
      optionSummary
      freeTextRequired
    }
  }
}
# {
#   "typeValue": "global::question",
#   "slugValue": "what-is-your-name",
#   "questionTitle": "What is your name",
#   "questionType": "FREE_TEXT",
#   "freeText": {
#     "answerSummary": "summary 1",
#     "freeTextRequired": false
#   },
#   "ageFrom": 15,
#   "ageTo": 60,
#   "appliedFor": "MALE"
#   "isRequired": false
# }
# {
#   "typeValue": "global::question",
#   "slugValue": "do-you-have-question",
#   "questionTitle": "Do you have question",
#   "questionType": "RADIO_BUTTON",
# 	"radioList": [
#     {
#       "optionTitle": "Yes",
#       "optionSummary": "yes summary",
#       "freeTextRequired": false
#     },
#     {
#       "optionTitle": "no",
#       "optionSummary": "no summary",
#       "freeTextRequired": false
#     },
#     {
#       "optionTitle": "may be",
#       "optionSummary": "may be summary",
#       "freeTextRequired": false
#     }
#   ],
#   "ageFrom": 10,
#   "ageTo": 70,
#   "appliedFor": "BOTH"
#   "isRequired": false
# }
`;


const updateDoctor = gql`
mutation updateDoctor(
  $prefix: String
  $fName: String!
  $mName: String
  $lName: String!
  $gender: Gender
  $email: String!
  $mobile: String!
  $phoneNo: String
  $skypeId: String
  $empId: String
  $photo: S3ObjectInput
  $dob: String!
  $signature: S3ObjectInput
  $maritalStatus: maritalStatus
  $electronCoverSheet: Boolean
  $doctorNo: String
  $doctorType: doctorType
  $registrationNo: String
  $address: [AddressInput]
  $docDetails: [DocDetailsInput]
  $allocateClassification: [String!]
  $active: Boolean
  $isDoctor: Boolean
  $countryCode:String!
  $registrationCouncil: String
	$registrationYear: String
	$experience: String
  $registrationNo: String
  $speciality: [DoctorSpecialityInput!]
  $bankDetails: [BankDetailsInput] 
  $followUpDuration: Int
  $followUpVisit: Int
  $aboutDoctor: String
){
  updateDoctor(input:{
    prefix: $prefix
    fName: $fName
    mName: $mName
    lName: $lName
    gender: $gender
    email: $email
    mobile: $mobile
    phoneNo: $phoneNo
    skypeId: $skypeId
    empId: $empId
    photo: $photo
    dob: $dob
    signature: $signature
    maritalStatus: $maritalStatus
    electronCoverSheet: $electronCoverSheet
    doctorNo: $doctorNo
    doctorType: $doctorType
    registrationNo: $registrationNo
    address: $address
    docDetails: $docDetails
    allocateClassification: $allocateClassification
    active: $active
    isDoctor: $isDoctor
    countryCode:$countryCode
    registrationCouncil: $registrationCouncil
		registrationYear: $registrationYear
		experience: $experience
    registrationNo: $registrationNo
    speciality: $speciality
    bankDetails: $bankDetails 
    followUpDuration: $followUpDuration
    followUpVisit: $followUpVisit
    aboutDoctor: $aboutDoctor
  }){
    orgId
    prefix
    fName
    mName
    lName
    gender
    email
    mobile
    phoneNo
    skypeId
    empId
    dob
    countryCode
    registrationCouncil
		registrationYear
		experience
    registrationNo
    photo{
      bucket
      region
      key
    }
    signature{
      bucket
      region
      key
    }
    maritalStatus
    electronCoverSheet
    doctorNo
    doctorType
    registrationNo
    active
    isDoctor
    createdAt
    allocateClassification
    bankDetails{
      bankName
      ifscCode
      accNo
      accHolder
      isPrimary
    } 
		followUpDuration
		followUpVisit
		aboutDoctor
    speciality{
      speciality
	    subSpeciality
    }
    docDetails{
      docType
      docName
      docDate
      docFile{
        bucket
        region
        key
      }
    }
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
  }
}
# {
#   "prefix": "MR",
#   "fName": "Sohel",
#   "mName": "NS.",
#   "lName": "Khan",
#   "gender": "MALE",
#   "email": "khan.sohel005@gmail.com",
#   "mobile": "+919028887875",
#   "phoneNo": "4545154",
#   "skypeId": "sohel.k005",
#   "empId": "2132",
#   "photo": {
#     "bucket": "dsd",
#     "key": "sds",
#     "region": "sds"
#   },
#   "dob": "1994-10-28",
#   "signature": {
#     "bucket": "ds",
#     "key": "dsf",
#     "region": "dsf"
#   },
#   "maritalStatus": "UNMARRIED",
#   "electronCoverSheet": true,
#   "doctorNo": "434334",
#   "doctorType": "DOCTOR_ASSOCIATE",
#   "registrationNo": "343433",
#   "address": {
#     "use": "HOME",
#     "type": "BOTH",
#     "lineOne": "qwerty",
#     "lineTwo": "ds",
#     "city": "pune",
#     "state": "maharashtra",
#     "zip": "411033",
#     "country": "IND"
#   },
#   "docDetails": {
#     "docType": "qwq",
#     "docName": "wqwqwqw",
#     "docDate": "2017-10-12",
#     "docFile": {
#       "bucket": "ds",
#       "key": "fd",
#       "region": "fd"
#     }
#   },
#   "allocateClassification": ["sds","fd"],
#   "active": true,
#   "isDoctor": true
# }
`;



const deleteDoctor = gql`
mutation deleteDoctor( $email: String!){
  deleteDoctor(email: $email)
}
# {
#   "email": "khan.sohel001@gmail.com"
# }
`;


const deleteAppointment = gql`
mutation deleteAppointment( $appointmentId: String!){
  deleteAppointment(appointmentId: $appointmentId){
    orgId
    apptId
  }
}
# {
#   "appointmentId": "recaho@gmail.com::2020-MAY-01::10:00"
# }
`;


const createFormTemplate = gql`
mutation createFormTemplate(
  $typeValue: String!
  $slugValue: String!
  $templateName: String!
  $templateType: String!
  $subTemplate: String
  $sectionRefs: [String!]!
){
  createFormTemplate(input:{
    typeValue: $typeValue
    slugValue: $slugValue
    templateName: $templateName
    templateType: $templateType
    subTemplate: $subTemplate
    sectionRefs: $sectionRefs
  }){
    typeValue
    slugValue
    templateName
    templateType
    subTemplate
    sectionRefs
    active
    createdAt
    updatedAt
  }
}
# {
#   "typeValue": "global::template",
#   "slugValue": "test-complaint",
#   "templateName": "Test Complaint",
#   "templateType": "Complaint",
#   "subTemplate": "Fever",
#   "sectionRefs": ["global::section#my-section-2"]
# }
# {
#   "typeValue": "global::template",
#   "slugValue": "test-complaint",
#   "templateName": "Test Complaint",
#   "templateType": "Complaint",
#   "subTemplate": "Fever",
#   "sectionRefs": ["global::section#my-section-2"]
# }
`;

const createSectionTemplate = gql`
mutation createSectionTemplate(
  $typeValue: String!
  $slugValue: String!
  $sectionName: String!
  $questionRefs: [String!]!
){
  createSectionTemplate(input:{
    typeValue: $typeValue
    slugValue: $slugValue
    sectionName: $sectionName
    questionRefs: $questionRefs
  }){
    typeValue
    slugValue
    sectionName
    questionRefs
    active
    createdAt
    updatedAt
  }
}
# {
#   "typeValue": "global::section",
#   "slugValue": "my-section",
#   "sectionName": "My Section",
#   "questionRefs": ["global::question#do-you-have-question"]
# }
`

const createQuestionTemplate = gql`
mutation createQuestionTemplate(
  $typeValue: String!
  $slugValue: String!
  $questionTitle: String!
  $questionType: QUESTION_TYPE!
  $freeText: FreeTextInput
  $number: NumberInput
  $date: DateInput
  $file: FileInput
  $radioList: [RadioListInput!]
  $checkboxList: [CheckboxListInput!]
  $ageFrom: Int
  $ageTo: Int
  $appliedFor: Gender_For
){
  createQuestionTemplate(input:{
    typeValue: $typeValue
    slugValue: $slugValue
    questionTitle: $questionTitle
    questionType: $questionType
    freeText: $freeText
    number: $number
    date: $date
    file: $file
    radioList: $radioList
    checkboxList: $checkboxList
    ageFrom: $ageFrom
    ageTo: $ageTo
    appliedFor: $appliedFor
  }){
    typeValue
    slugValue
    questionTitle
    questionType
    uId
    ageFrom
    ageTo
    appliedFor
    createdAt
    updatedAt
    active
    freeText{
      answerSummary
      freeTextRequired
    }
    number{
      answerSummary
      freeTextRequired
    }
    date{
      answerSummary
      freeTextRequired
    }
    file{
      answerSummary
      freeTextRequired
    }
    radioList{
      optionTitle
      optionSummary
      freeTextRequired
    }
    checkboxList{
      optionTitle
      optionSummary
      freeTextRequired
    }
  }
}
# {
#   "typeValue": "global::question",
#   "slugValue": "what-is-your-name",
#   "questionTitle": "What is your name",
#   "questionType": "FREE_TEXT",
#   "freeText": {
#     "answerSummary": "summary1",
#     "freeTextRequired": false
#   },
#   "ageFrom": 5,
#   "ageTo": 60,
#   "appliedFor": "MALE"
# }
# {
#   "typeValue": "global::question",
#   "slugValue": "dob",
#   "questionTitle": "DOB",
#   "questionType": "DATE",
#   "date": {
#     "answerSummary": "2012-12-15",
#     "freeTextRequired": false
#   },
#   "ageFrom": 5,
#   "ageTo": 60,
#   "appliedFor": "BOTH"
# }
# {
#   "typeValue": "global::question",
#   "slugValue": "photo",
#   "questionTitle": "Photo",
#   "questionType": "FILE",
#   "file": {
#     "answerSummary": "fileSummary",
#     "freeTextRequired": false
#   },
#   "ageFrom": 5,
#   "ageTo": 60,
#   "appliedFor": "MALE"
# }
# {
#   "typeValue": "global::question",
#   "slugValue": "do-you-have-question",
#   "questionTitle": "Do you have question",
#   "questionType": "RADIO_BUTTON",
# 	"radioList": [
#     {
#       "optionTitle": "yes",
#       "optionSummary": "yes summary",
#       "freeTextRequired": false
#     },
#     {
#       "optionTitle": "no",
#       "optionSummary": "no summary",
#       "freeTextRequired": false
#     },
#     {
#       "optionTitle": "may be",
#       "optionSummary": "may be summary",
#       "freeTextRequired": false
#     }
#   ],
#   "ageFrom": 10,
#   "ageTo": 70,
#   "appliedFor": "BOTH"
# }
# {
#   "typeValue": "global::question",
#   "slugValue": "select-symptoms",
#   "questionTitle": "Select symptoms",
#   "questionType": "CHECK_BOX",
# 	"checkboxList": [
#     {
#       "optionTitle": "fever",
#       "optionSummary": "fever summary",
#       "freeTextRequired": false
#     },
#     {
#       "optionTitle": "headache",
#       "optionSummary": "headache summary",
#       "freeTextRequired": false
#     },
#     {
#       "optionTitle": "acidity",
#       "optionSummary": "acidity be summary",
#       "freeTextRequired": false
#     }
#   ],
#   "ageFrom": 10,
#   "ageTo": 70,
#   "appliedFor": "BOTH"
# }
`;

const updateBill = gql`
mutation updateBill(
  $patientId: String!
  $billId: String!
  $status: String
  $type: String
  $paymentStatus: String
  $finalAmt: Float
){
  updateBill(input:{
    patientId: $patientId
    billId: $billId
    type: $type
    status: $status
    paymentStatus: $paymentStatus
    finalAmt: $finalAmt
  }){
    patientId
    billId
    status
    type
    paymentStatus
    finalAmt
    updatedAt
    createdAt
  }
}
# {
#   "patientId": "clinivantage-healthcare::sohel::khan::male::1994-10-28::appt::khan.sohel005@gmail.com::2020-MAY-04::10:00",
#   "billId": "4d694b00-fe0e-42d4-9708-6df26449cdd1",
#   "status": "closed",
#   "type": "post",
#   "paymentStatus": "paid",
#   "finalAmt": 500
# }
`;


const createPayment = gql`
mutation createPayment(
  $billId: String!
  $amount: Float!
  $curr: CURRENCY_CODE!
  $mode: PAYMENT_MODE!
){
  createPayment(input:{
    billId: $billId
    amount: $amount
    curr: $curr
    mode: $mode
  }){
    billId
    paymentId
    amount
    curr
    mode
    createdAt
    collectedBy
  }
}
# {
#   "billId": "3bfdb302-8be8-4c9d-815d-88b9981281f5",
#   "amount": 500,
#   "curr": "INR",
#   "mode": "CASH"
# }
`



const checkedOutAppointment = gql`
mutation checkedOutAppointment( $appointmentId: String!){
  checkedOutAppointment(appointmentId: $appointmentId){
    orgId
    apptId
    status
  }
}
# {
#   "appointmentId": "khan.sohel005@gmail.com::2020-MAY-04::10:00"
# }
`;

const checkedInAppointment = gql`
mutation checkedInAppointment(
  $apptId: String!
){
  checkedInAppointment(input:{
    apptId: $apptId
  })
}
# {
#   "apptId": "khan.sohel005@gmail.com::2020-MAY-04::10:00"
# }
`;



const addPatientToOrg = gql`
mutation addPatientToOrg(
  $patientId: String!
  $active: Boolean
  $patientNo: String
  $registeredSource: String
  $registeredBy: String
){
  addPatientToOrg(input:{
    patientId: $patientId
    active: $active
    patientNo: $patientNo
    registeredSource: $registeredSource
    registeredBy: $registeredBy
  }){
    patientId
    typeValue
    active
    patientNo
    registeredSource
    registeredBy
    registeredAt
  }
}
# {
#   "patientId": "sohel::khan::male::1994-10-28",
#   "active": true,
#   "patientNo": "q323",
#   "registeredSource": "pms",
#   "registeredBy": "khan.sohel005@gmail.com"
# }
`;


const updatePatient = gql`
mutation updatePatient(
  $patientId: String!
  $typeValue: String!
  $active: Boolean
  $prefix: String
  $use: HumanNameUse
  $fName: String!
  $mName: String
  $lName: String!
  $contactSystem: ContactPointSystem
  $contactUse: ContactPointUse
  $contactNo: String
  $gender: Gender!
  $birthDate: AWSDate
  $address: [AddressInput]
  $photo: S3ObjectInput
  $email: String
  $registeredSource: String
  $registeredBy: String
  $contact: [PatientContactInput]
  $myDocuments: [MyDocumentsInput!]
  $referralDoctors: [String!]
  $myInsurance: [MyInsuranceInput]
  $sponserDetails: [SponserDetailsInput]
){
  updatePatient(input:{
    patientId: $patientId
    typeValue: $typeValue
    active: $active
    prefix: $prefix
    use: $use
    fName: $fName
    mName: $mName
    lName: $lName
    contactSystem: $contactSystem
    contactUse: $contactUse
    contactNo: $contactNo
    gender: $gender
    birthDate: $birthDate
    address: $address
    photo: $photo
    email: $email
    registeredSource: $registeredSource
    registeredBy: $registeredBy
    contact: $contact
    myDocuments: $myDocuments
    referralDoctors:$referralDoctors
    myInsurance: $myInsurance
    sponserDetails: $sponserDetails
  }){
    patientId
    typeValue
    active
    prefix
    use
    fName
    lName
    mName
    contactSystem
    contactUse
    contactNo
    gender
    birthDate
    email
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    photo{
      bucket
      region
      key
    }
    registeredSource
    registeredBy
    myDocuments{
      docType
      docName
      date
      doc{
        bucket
        region
        key
      }
    }
    sponserDetails{
      companyName
      memNumber
      email
    }
    myInsurance {
      companyName
      insuranceNo
      startDate
      endDate
      doc{
      bucket
      region
      key
    }
    }
    referralDoctors
    contact{
      prefix
      use
      fName
      mName
      lName
      relationship
      gender
      contactSystem
      contactUse
      contactNo
      address{
        use
        type
        lineOne
        lineTwo
        city
        state
        zip
        country
      }
    }
  }
}
# {
#   "patientId": "suzain::md::male::2006-11-06",
#   "typeValue": "profile",
#   "active": true,
#   "prefix": "Mr.",
#   "use": "OFFICIAL",
#   "fName": "Suzain",
#   "mName": "R.",
#   "lName": "Md",
#   "contactSystem": "PHONE",
#   "contactUse": "MOBILE",
#   "contactNo": "9028869939",
#   "gender": "MALE",
#   "birthDate": "2006-11-06",
#   "email": "khan.sohel005@gmail.com",
#   "address": {
#     "use": "WORK",
#     "type": "PHYSICAL",
#     "lineOne": "qwerty",
#     "lineTwo": "zxc",
#     "city": "pune",
#     "state": "maha",
#     "zip": "411033",
#     "country": "IND"
#   },
#   "photo": {
#     "bucket": "fsd",
#     "key": "fd",
#     "region": "dfd"
#   },
#   "registeredSource": "pms",
#   "registeredBy": "khan.sohel005@gmail.conm",
#   "contact": [
#     {
#       "prefix": "Mr.",
#       "use": "OFFICIAL",
#       "fName": "salmna",
#       "lName": "khan",
#       "relationship": "Brother",
#       "gender": "MALE",
#       "contactSystem": "PHONE",
#       "contactUse": "MOBILE",
#       "contactNo": "9028772221",
#       "address": {
#          "use": "WORK",
#         "type": "PHYSICAL",
#         "lineOne": "qwerty",
#         "lineTwo": "zxc",
#         "city": "pune",
#         "state": "maha",
#         "zip": "411033",
#         "country": "IND"
#       }
#     }
#   ]
# }
`;



const createUnit = gql`
mutation createUnit(
  $input:CreateUnitInput!
){
  createUnit(input: $input){
    fqdn
    unitName
    orgId
    email
    contactNo
    pharmacyLicenseNo
    clinicRegiNo
    shopEstNo
    tradeNo
    isActive
    createdAt
    updatedAt
    skypeId
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    logo{
      bucket
      region
      key
    }
  }
}
# {
#   "unitName": "Clinivantage Healthcare Mumbai",
#   "email": "cvmumbai@clinivantage.com",
#   "contactNo": "+919028772221",
#   "pharmacyLicenseNo": "24543",
#   "clinicRegiNo": "6576",
#   "shopEstNo": "42437654567",
#   "tradeNo": "7657",
#   "isActive": true,
#   "address": {
#     "use": "WORK",
#     "type": "PHYSICAL",
#     "lineOne": "63, BMC Industrial Estate",
#     "lineTwo": " Worli",
#     "city": "Mumbai",
#     "state": "Maharashtra",
#     "zip": "400013",
#     "country": "IND"
#   }
# }
`



const updateConsultationService = gql`
mutation updateConsultationService(
  $serviceId: String!
  $doctorId: String!
  $serviceName: String!
  $type: String!
  $speciality: String
  $duration: Int!
  $cost: Int
  $colorCode: String
){
  updateConsultationService(input:{
    serviceId: $serviceId
    doctorId: $doctorId
    serviceName: $serviceName
    type: $type
    speciality: $speciality
    duration: $duration
    cost: $cost
    colorCode: $colorCode
  }){
    service
    serviceId
    doctorId
    serviceName
    type
    speciality
    duration
    cost
    colorCode
    createdAt
  }
}
# {
#   "serviceId": "ruby.hospitals@gmail.com::NEW CHEST",
#   "doctorId": "ruby.hospitals@gmail.com",
#   "serviceName": "NEW CHEST",
#   "type": "NEW",
#   "speciality": "Cardio",
#   "duration": 15,
#   "cost": 400
# }
`;



const cancelAppointment = gql`
mutation cancelAppointment( $appointmentId: String! $reason: String){
  cancelAppointment(appointmentId: $appointmentId reason: $reason){
    orgId
    apptId
    status
    cancelledReason
    isActive
  }
}
# {
#   "appointmentId": "recaho@gmail.com::2020-MAY-01::09:15"
# }
`;

// const cancelAppointment = gql`
// mutation cancelAppointment( 
//   $appointmentId: String! 
//   $reason: String
//   ){
//   cancelAppointment(input:{
//     appointmentId: $appointmentId
//      reason: $reason
//  }){
//     orgId
//     apptId
//     status
//     reason
//     isActive
//   }
// }
// `;


const createConsultationService = gql`
mutation createConsultationService(
  $doctorId: String!
  $serviceName: String!
  $type: String!
  $speciality: String
  $duration: Int!
  $cost: Int
  $colorCode: String
){
  createConsultationService(input:{
    doctorId: $doctorId
    serviceName: $serviceName
    type: $type
    speciality: $speciality
    duration: $duration
    cost: $cost
    colorCode: $colorCode
  }){
    service
    serviceId
    doctorId
    serviceName
    type
    speciality
    duration
    cost
    colorCode
    createdAt
  }
}
# {
#   "doctorId": "ruby.hospitals@gmail.com(opens in new tab)",
#   "serviceName": "NEW CHEST",
#   "type": "NEW",
#   "speciality": "Cardio",
#   "duration": 15,
#   "cost": 500
# }
`;


const createAppointment = gql`
mutation createAppointment( 
    $doctorId: String!
  $patientId: String!
  $date: String!
  $slotTime: String!
  $duration: Int!
  $apptType: String!
  $isDocAccess: Boolean
  $status: APPOINTMENT_STATUS!
  $isActive: Boolean!
){
  createAppointment(input: {
    doctorId: $doctorId
    patientId: $patientId
    date: $date
    slotTime: $slotTime
    duration: $duration
    apptType: $apptType
    isDocAccess: $isDocAccess
    status: $status
    isActive: $isActive
  }){
    orgId
    apptId
    doctorId
    patientId
    date
    slotTime
    duration
    apptType
    isActive
    status
    createdAt
    doctorProfile{
      allocateClassification
      orgId
      prefix
      fName
      mName
      lName
      gender
      email
      mobile
      skypeId
      photo{
        bucket
        region
        key
      }
      isDoctor
      active
      doctorType
    }
    patientProfile{
      # id
      patientId
      typeValue
      active
      prefix
      use
      fName
      lName
      mName
      contactSystem
      contactUse
      contactNo
      gender
      birthDate
      email
      address{
        use
        type
        lineOne
        lineTwo
        city
        state
        zip
        country
      }
      photo{
        bucket
        region
        key
      }
      registeredSource
      registeredBy
      contact{
        prefix
        use
        fName
        mName
        lName
        relationship
        gender
        contactSystem
        contactUse
        contactNo
        address{
          use
          type
          lineOne
          lineTwo
          city
          state
          zip
          country
        }
      }
      patientOrgMap{
        patientId
        typeValue
        active
        patientNo
        registeredSource
        registeredBy
        registeredAt
      }
      }
  
  }
}
# {
#   "doctorId": "recaho@gmail.com",
#   "patientId": "sohel::khan::male::1994-10-28",
#   "date": "2020-MAY-01",
#   "slotTime": "10:00",
#   "duration": 15,
#   "apptType": "NEW",
#   "isDocAccess": true,
#   "status": "new",
#   "isActive": true
# }
`;

const createSchedule = gql`
mutation createSchedule($input: CreateScheduleInput!){
  createSchedule(input: $input){
    orgId
    doctorId
    scheduleDate
    slots{
      start
      end
      aptType
    }
  }
}
# {
#   "input": {
#     "doctorId": "khan.sohel005@gmail.com",
#     "scheduleDate": "2020-MAY-04",
#     "slots": [
#       {
#         "start": "9:00",
#         "end": "12:00",
#         "aptType": ["NEW"]
#       },
#       {
#         "start": "14:00",
#         "end": "17:00",
#         "aptType": ["NEW","FOLLOW"]
#       }
#     ]
#   }
# }
`

const createPatientCasesheet = gql`
mutation createPatientCasesheet( $input: CreatePatientCasesheetInput!){
  createPatientCasesheet(input: $input){
    patientId
		doctorId
    ehrId
    encounterId
    chiefComplaints{
      complaintName
      note
    }
    examinations{
      examination
      note
    }
    advice{
      advice
      note
    }
    medication{
      brandName
      genericName
      frequency
      dosage
      dosageUnit
      dosageDate
    }
    diagnosis{
      diagnosis
      type
      priority
      note
    }
    vitals{
      testName
      value
      unit
    }
    note
    allergy{
      allergyType 
      allergyName
      date
      type
      natureOfReaction
      severity
      remarks
    }
    pastHistory {
      lifeStyle{
      description
      remark
    }
    familyHistory{
      diagnosis
      relation
      remark
    }
    surgicalHistory{
      surgery
      doctor
      date
      remark
    }
    medicationHistory{
      brandName
      genericName
      frequency
      dosage
      dosageUnit
      dosageDate
    }
    medicalHistory{
      icd
      remark
      }
    }
    createdAt
  }
}
# {
#   "input": {
#     "patientId": "sohel::khan::male::1994-10-28",
#     "doctorId": "khan.sohel005@gmail.com",
#     "encounterId": "12155",
#     "chiefComplaints": {
#       "complaintName": "xyz",
#       "note": "fdfd"
#     },
#     "examinations": {
#       "examination": "fds",
#       "note": "fsdgf"
#     },
#     "advice": {
#       "advice": "sad",
#       "note": "sfdg"
#     },
#     "medication": {
#       "brandName": "gold",
#       "genericName": "ggg",
#       "frequency": "ds",
#       "dosage": "ds0",
#       "dosageUnit": "ss",
#       "dosageDate": "28-12-2020"
#     },
#     "diagnosis": {
#       "diagnosis": "dsfd",
#       "type": "tret",
#       "priority": "1",
#       "note": "fds"
#     },
#     "vitals": [
#       {
#       "testName": "HB",
#       "value": "9.4",
#       "unit": "gl/d"
#       },
#       {
#       "testName": "uric acid",
#       "value": "positive"
#       }
#     ],
#     "note": "dsdfndsk"
#   }
# }
`
const createDoctor = gql`
  mutation createDoctor(
  $prefix:String
  $fName: String!
  $mName: String
  $lName: String!
  $gender: Gender
  $email: String!
  $countryCode: String!
  $mobile: String!
  $phoneNo: String
  $skypeId: String
  $empId: String
  $photo: S3ObjectInput
  $dob: String!
  $signature: S3ObjectInput
  $maritalStatus: maritalStatus
  $electronCoverSheet: Boolean
  $doctorNo: String
  $doctorType: doctorType
  $registrationNo: String
  $address: [AddressInput]
  $docDetails: [DocDetailsInput]
  $allocateClassification: [String!]
  $active: Boolean
  $isDoctor: Boolean
  $registrationCouncil: String
	$registrationYear: String
	$experience: String
  $registrationNo: String
  $speciality: [DoctorSpecialityInput!]
  $bankDetails: [BankDetailsInput] 
  $followUpDuration: Int
  $followUpVisit: Int
  $aboutDoctor: String
){
  createDoctor(input:{
    prefix: $prefix
    fName: $fName
    mName: $mName
    lName: $lName
    gender: $gender
    email: $email
    countryCode: $countryCode
    mobile: $mobile
    phoneNo: $phoneNo
    skypeId: $skypeId
    empId: $empId
    photo: $photo
    dob: $dob
    signature: $signature
    maritalStatus: $maritalStatus
    electronCoverSheet: $electronCoverSheet
    doctorNo: $doctorNo
    doctorType: $doctorType
    registrationNo: $registrationNo
    address: $address
    docDetails: $docDetails
    allocateClassification: $allocateClassification
    active: $active
    isDoctor: $isDoctor
    registrationCouncil: $registrationCouncil
		registrationYear: $registrationYear
		experience: $experience
    registrationNo: $registrationNo
    speciality: $speciality
    bankDetails: $bankDetails 
    followUpDuration: $followUpDuration
    followUpVisit: $followUpVisit
    aboutDoctor: $aboutDoctor
  }){
    orgId
    prefix
    fName
    mName
    lName
    gender
    email
    countryCode
    mobile
    phoneNo
    skypeId
    empId
    dob
    photo{
      bucket
      region
      key
    }
    signature{
      bucket
      region
      key
    }
    maritalStatus
    electronCoverSheet
    doctorNo
    doctorType
    registrationNo
    active
    isDoctor
    createdAt
    allocateClassification
    registrationCouncil
		registrationYear
		experience
    registrationNo
    speciality{
      speciality
	    subSpeciality
    }
    docDetails{
      docType
      docName
      docDate
      docFile{
        bucket
        region
        key
      }
    }
    bankDetails{
      bankName
      ifscCode
      accNo
      accHolder
      isPrimary
    } 
		followUpDuration
		followUpVisit
		aboutDoctor
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
  }
}
# {
#   "fName": "Sohel",
#   "mName": "N.",
#   "lName": "Khan",
#   "gender": "MALE",
#   "email": "khan.sohel005@gmail.com",
#   "mobile": "+919028887875",
#   "phoneNo": "4545154",
#   "skypeId": "sohel.k",
#   "empId": "2132",
#   "photo": {
#     "bucket": "dsd",
#     "key": "sds",
#     "region": "sds"
#   },
#   "dob": "1994-10-28",
#   "signature": {
#     "bucket": "ds",
#     "key": "dsf",
#     "region": "dsf"
#   },
#   "maritalStatus": "UNMARRIED",
#   "electronCoverSheet": true,
#   "doctorNo": "434334",
#   "doctorType": "DOCTOR_ASSOCIATE",
#   "registrationNo": "343433",
#   "address": {
#     "use": "HOME",
#     "type": "BOTH",
#     "lineOne": "qwerty",
#     "lineTwo": "ds",
#     "city": "pune",
#     "state": "maharashtra",
#     "zip": "411033",
#     "country": "IND"
#   },
#   "docDetails": {
#     "docType": "ds",
#     "docName": "sfsd",
#     "docDate": "2017-10-12",
#     "docFile": {
#       "bucket": "ds",
#       "key": "fd",
#       "region": "fd"
#     }
#   },
#   "allocateClassification": ["sds","fd"],
#   "active": true,
#   "isDoctor": true
# } 
`

const createPatient2 = gql`
 mutation createPatient(
  $active: Boolean
  $prefix: String
  $use: HumanNameUse
  $fName: String!
  $mName: String
  $lName: String!
  $contactSystem: ContactPointSystem
  $contactUse: ContactPointUse
  $contactNo: String
  $gender: Gender!
  $birthDate: AWSDate
  $address: [AddressInput]
  $photo: S3ObjectInput
  $email: String
  $registeredSource: String
  $registeredBy: String
  $contact: [PatientContactInput]
  $myDocuments: [MyDocumentsInput!]
  $referralDoctors: [String!]
  $sponserDetails: [SponserDetailsInput]
  $myInsurance: [MyInsuranceInput]
){
  createPatient(input:{
    active: $active
    prefix: $prefix
    use: $use
    fName: $fName
    mName: $mName
    lName: $lName
    contactSystem: $contactSystem
    contactUse: $contactUse
    contactNo: $contactNo
    gender: $gender
    birthDate: $birthDate
    address: $address
    photo: $photo
    email: $email
    registeredSource: $registeredSource
    registeredBy: $registeredBy
    contact: $contact
    myDocuments: $myDocuments
    referralDoctors:$referralDoctors
    sponserDetails:$sponserDetails
    myInsurance:$myInsurance
  }){
    patientId
    typeValue
    active
    prefix
    use
    fName
    lName
    mName
    contactSystem
    contactUse
    contactNo
    gender
    birthDate
    email
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    photo{
      bucket
      region
      key
    }
    registeredSource
    registeredBy
    myDocuments{
      docType
      docName
      date
      doc{
        bucket
        region
        key
      }
    }
    sponserDetails{
      companyName
      memNumber
      email
    }
    myInsurance {
      companyName
      insuranceNo
      startDate
      endDate
      doc{
      bucket
      region
      key
    }
    }
    referralDoctors
    contact{
      prefix
      use
      fName
      mName
      lName
      relationship
      gender
      contactSystem
      contactUse
      contactNo
      address{
        use
        type
        lineOne
        lineTwo
        city
        state
        zip
        country
      }
    }
  }
}
# {
#   "active": true,
#   "prefix": "Mr.",
#   "use": "OFFICIAL",
#   "fName": "Suzain",
#   "mName": "R.",
#   "lName": "Md",
#   "contactSystem": "PHONE",
#   "contactUse": "MOBILE",
#   "contactNo": "9028869939",
#   "gender": "MALE",
#   "birthDate": "2006-11-06",
#   "email": "khan.sohel005@gmail.com",
#   "address": {
#     "use": "WORK",
#     "type": "PHYSICAL",
#     "lineOne": "qwerty",
#     "lineTwo": "zxc",
#     "city": "pune",
#     "state": "maha",
#     "zip": "411033",
#     "country": "IND"
#   },
#   "photo": {
#     "bucket": "fsd",
#     "key": "fd",
#     "region": "dfd"
#   },
#   "registeredSource": "pms",
#   "registeredBy": "khan.sohel005@gmail.conm",
#   "contact": [
#     {
#       "prefix": "Mr.",
#       "use": "OFFICIAL",
#       "fName": "salmna",
#       "lName": "khan",
#       "relationship": "Brother",
#       "gender": "MALE",
#       "contactSystem": "PHONE",
#       "contactUse": "MOBILE",
#       "contactNo": "9028772221",
#       "address": {
#          "use": "WORK",
#         "type": "PHYSICAL",
#         "lineOne": "qwerty",
#         "lineTwo": "zxc",
#         "city": "pune",
#         "state": "maha",
#         "zip": "411033",
#         "country": "IND"
#       }
#     }
#   ]
# }
`

const createPatientCognitoUser = gql`
mutation createPatientCognitoUser(
    $orgId:String!
    $prefix: String!
	  $fName:String!
    $mName:String
	  $lName:String!
    $photo:S3ObjectInput
	  $birthDate:String!
    $birthDateEpoch:Int!
    $age:AgeInput!
    $gender:GENDER!
    $nationality:String
    $mob:MobCountryCodeInput!
    $email:String!
    $address:GenericAddressInput!
    $contactInfo:PatientContactInfoInput
    $patientRefDoctor:[PatientRefDoctorInput!]
    $kinDetails:PatientKinDetailsInput
    $patientDocDetails:[PatientDocDetailsInput!]
){
  createPatientCognitoUser(input:{
    orgId:$orgId
    prefix: $prefix
	  fName: $fName
    mName: $mName
	  lName: $lName
    photo:$photo
	  birthDate: $birthDate
    birthDateEpoch: $birthDateEpoch
    age: $age
    gender: $gender
    nationality: $nationality
    mob: $mob
    email: $email
    address: $address
    contactInfo: $contactInfo
    patientRefDoctor: $patientRefDoctor
    kinDetails: $kinDetails
    patientDocDetails: $patientDocDetails
  }){
     prefix
      fName
      mName
      lName
      photo{
          key
      }
      birthDate
      age {
          year
          month
          day
      }
      gender
      nationality
      uhid
      uuid
      birthDateEpoch
      orgId
      role
       mob{
            countryCode
            mob
        }
      email
      address{
          addressLineOne
          addressLineTwo
          city
          state
          country
          zip
          locality
      }
      createdAt
    contactInfo{
      offcNo
      resiNo
      mob{
          countryCode
          mob
      }
      email
      address{
          addressLineOne
          addressLineTwo
          city
          state
          country
          zip
          locality
      }
    }
    patientRefDoctor{
        refName
        mob{
            countryCode
            mob
        }
        faxNo
        email
        address{
            addressLineOne
            addressLineTwo
            city
            state
            country
            zip
            locality
        }
        isConsultantReferral
    }
    kinDetails{
        kinFname
        kinMname
        kinLname
        kinRelation
        kinAddress
        kinMobileNo
    }
    patientDocDetails{
        docDate
        docType
        docFile{
            key
            bucket
            region
        }
        docName
    }
  }
}
# {
#     "prefix": "ms",
#     "fName": "pranjal",
#     "mName": "r",
#     "lName": "koshti",
#     "birthDate": "2-dec-2001",
#     "birthDateEpoch":123455,
#     "age": {
#       "year": 31,
#       "month": 1,
#       "day": 1
#     },
#     "gender": "FEMALE",
#     "nationality": "indian",
#     "uhid": "123",
#     "uuid": "a12",
# "mob": {
#       "countryCode": "+91",
#       "mob": "12344576"
#     },
#     "email": "abc@gmail.com",
#     "address": {
#       "addressLineOne": "abc",
#       "addressLineTwo": "abc",
#       "city": "abc",
#       "state": "abc",
#       "country": "IND",
#       "zip": "123"
#     },
#   "contactInfo": {
#     "offcNo": "123456",
#     "resiNo": "123456",
#     "mob": {
#       "countryCode": "+91",
#       "mob": "12344576"
#     },
#     "email": "abc@gmail.com",
#     "address": {
#       "addressLineOne": "abc",
#       "addressLineTwo": "abc",
#       "city": "abc",
#       "state": "abc",
#       "country": "IND",
#       "zip": "123"
#     }
#   },
#   "patientRefDoctor": {
#     "refName": "abc",
#     "mob": {
#       "countryCode": "+91",
#       "mob": "1234"
#     },
#     "faxNo": "asd",
#     "email": "abc@gmail.com",
#     "address": {
#       "addressLineOne": "abc",
#       "addressLineTwo": "abc",
#       "city": "abc",
#       "state": "abc",
#       "country": "IND",
#       "zip": "123"
#     },
#     "isConsultantReferral": true
#   },
#   "kinDetails": {
#     "kinFname": "abc",
#     "kinMname": "abc",
#     "kinLname": "anc",
#     "kinAddress": "anc",
#     "kinRelation": "brother",
#     "kinMobileNo": "12344"
#   },
#   "patientDocDetails": {
#     "docDate": "12-12-2011",
#     "docType": "abc",
#     "docName": "abc",
#     "docFile": {
#       "key": "abc",
#       "bucket": "abc",
#       "region": "anc"
#     }
#   }
# }
`


const createOrg = gql`
mutation createOrg(
  $fqdn: String!
  $unitId: ID!
  $orgName: String!
  $email: String!
  $contactNo: String
  $address: AddressInput!
  $isActive: Boolean!
){
  createOrg(input:{
    fqdn: $fqdn
    unitId: $unitId
    orgName: $orgName
    email: $email
    contactNo: $contactNo
    address: $address
    isActive: $isActive
  }){
    fqdn
    unitId
    orgName
    email
    contactNo
    type
    isActive
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
  }
}
# {
#   "fqdn": "clinivantage.pms.com",
#   "unitId": "clinivantage-healthcare",
#   "orgName": "Clinivantage Healthcare",
#   "email": "admin@clinivantage.com",
#   "contactNo": "+918459832342",
#   "address": {
#     "use": "WORK",
#     "type": "PHYSICAL",
#     "lineOne": "Tulsi Green",
#     "lineTwo": " Laxman Nagar Baner",
#     "city": "Pune",
#     "state": "Maharashtra",
#     "zip": "411045",
#     "country": "IND"
#   },
#   "isActive": true
# }
`

const updatedReferralDoctor = gql`  
    mutation updatedReferralDoctor(
        $orgId: String!
        $referralId: String!
        $referralName: String!
        $faxNo: String
        $email: String!
        $mobile: String!
        $address: AddressInput
        $referralType: RreferralType!
        $isActive: Boolean
    ){
      updatedReferralDoctor(input:{
        orgId: $orgId
        referralId: $referralId
        referralName: $referralName
        faxNo: $faxNo
        email: $email
        mobile: $mobile
        referralType: $referralType
        address: $address
        isActive: $isActive
        
      }){
        orgId
        referralId
        referralName
        faxNo
        email
        mobile
        createdBy 
        createdAt
        referralType
        address{
          use
          type
          lineOne
          lineTwo
          city
          state
          zip
          country
        }
      }
    }
    
    # {
    #   "orgId": "clinivantage-healthcare::doctor",
    #   "referralId": "khan.sohel005@gmail.com",
    #   "isActive": true,
    #   "referralName": "Sohel khan",
    #   "faxNo": "564785676",
    #   "email": "khan.sohel005@gmail.com",
    #   "mobile": "8459832342",
    #   "referralType": "CONSULTANT",
    #   "address": {
    #     "use": "WORK",
    #     "type": "PHYSICAL",
    #     "lineOne": "qwerty",
    #     "lineTwo": "dfds",
    #     "city": "pune",
    #     "state": "maharashtra",
    #     "zip": "411033",
    #     "country": "IND"
    #   }
    # }
    
`
const createReasonMaster = gql`
mutation createReasonMaster(
  $reasonName: String!
    $slugReasonName: String!
    $description: String
    $isActive: Boolean!
){
  createReasonMaster(input:{
    reasonName: $reasonName
    slugReasonName: $slugReasonName
    description: $description
    isActive: $isActive
  }){
    orgId
    referralId
    reasonName
    slugReasonName
    description
    isActive
    createdAt
    updatedAt
    createdBy
    
  }
}
# {
#   "reasonName": "Other Meeeting",
#   "slugReasonName": "other-meeting",
#   "description": "qwertyui",
#   "isActive": true
# }
`

const updateReasonMaster = gql`
mutation updateReasonMaster(
  $orgId: String!
  $referralId: String!
  $reasonName: String!
  $slugReasonName: String!
  $description: String
  $isActive: Boolean!
){
  updateReasonMaster(input:{
    orgId: $orgId
    referralId: $referralId
    reasonName: $reasonName
    slugReasonName: $slugReasonName
    description: $description
    isActive: $isActive
  }){
    orgId
    referralId
    reasonName
    slugReasonName
    description
    isActive
    createdAt
    updatedAt
    createdBy
    
  }
}
# {
#   "orgId": "clinivantage-healthcare::reason",
#   "referralId": "other-meeting",
#   "reasonName": "Other Meeeting",
#   "slugReasonName": "other-meeting",
#   "description": "test update",
#   "isActive": true
# }
`
const createPharmacyRegistration = gql`
mutation createPharmacyRegistration(
  $slugValue: String!
	$pharmacyName: String!
	$email: String
	$countryCode: String
	$mobile: String!
	$address: AddressInput
){
  createPharmacyRegistration(input:{
    slugValue: $slugValue
    pharmacyName: $pharmacyName
    email: $email
    countryCode: $countryCode
    mobile: $mobile
    address: $address
  }){
    id
    orgId
    slugValue
    pharmacyName
    email
    countryCode
    mobile
    enabled
    address{
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    createdAt
  }
}
# {
#   "slugValue": "test-pharma",
#   "pharmacyName": "Test Pharma",
#   "email": "test@gmail.com",
#   "countryCode": "+91",
#   "mobile": "9028887875",
#   "address": {
#     "use": "WORK",
#     "type": "BOTH",
#     "lineOne": "awtewtae",
#     "lineTwo": "ds",
#     "city": "pune",
#     "state": "maharashtra",
#     "zip": "411033",
#     "country": "IND"
#   }
# }
`;

const updatePharmacyRegistration = gql`
mutation updatePharmacyRegistration(
  $orgId:  String!
  $slugValue: String!
	$pharmacyName: String!
	$email: String
	$countryCode: String
	$mobile: String!
	$address: AddressInput
  $enabled: Boolean
){
  updatePharmacyRegistration(input:{
    orgId: $orgId
    slugValue: $slugValue
    pharmacyName: $pharmacyName
    email: $email
    countryCode: $countryCode
    mobile: $mobile
    address: $address
    enabled: $enabled
  }){
    # id
    orgId
    slugValue
    pharmacyName
    email
    countryCode
    mobile
    enabled
    address{
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    createdAt
  }
}
# {
#   "orgId": "pharmacy::recaho-demo-organization",
#   "slugValue": "recaho-demo-organization::test-pharma",
#   "pharmacyName": "Test Pharma",
#   "email": "test@gmail.com",
#   "countryCode": "+91",
#   "mobile": "9028887875",
#   "enabled": true,
#   "address": {
#     "use": "WORK",
#     "type": "BOTH",
#     "lineOne": "awtewtae",
#     "lineTwo": "ds",
#     "city": "pune",
#     "state": "maharashtra",
#     "zip": "411033",
#     "country": "IND"
#   }
# }
`;


const createLabRegistration = gql`
mutation createLabRegistration(
  $slugValue: String!
	$labName: String!
	$email: String
	$countryCode: String
	$mobile: String!
	$address: AddressInput
){
  createLabRegistration(input:{
    slugValue: $slugValue
    labName: $labName
    email: $email
    countryCode: $countryCode
    mobile: $mobile
    address: $address
  }){
    id
    orgId
    slugValue
    labName
    email
    countryCode
    mobile
    enabled
    address{
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    createdAt
  }
}
# {
#   "slugValue": "test-pharma",
#   "labName": "Test Pharma",
#   "email": "test@gmail.com",
#   "countryCode": "+91",
#   "mobile": "9028887875",
#   "address": {
#     "use": "WORK",
#     "type": "BOTH",
#     "lineOne": "awtewtae",
#     "lineTwo": "ds",
#     "city": "pune",
#     "state": "maharashtra",
#     "zip": "411033",
#     "country": "IND"
#   }
# }
`;

const updateLabRegistration = gql`
mutation updateLabRegistration(
    $orgId: String!
    $slugValue: String!
    $labName: String!
    $email: String
    $countryCode: String
    $mobile: String!
    $address: AddressInput
  ){
    updateLabRegistration(input:{
      orgId: $orgId
      slugValue: $slugValue
      labName: $labName
      email: $email
      countryCode: $countryCode
      mobile: $mobile
      address: $address
    }){
      # id
      orgId
      slugValue
      labName
      email
      countryCode
      mobile
      enabled
      address{
        lineOne
        lineTwo
        city
        state
        zip
        country
      }
      createdAt
    }
  }
`;

const addAdviceToMyList=gql`
mutation addAdviceToMyList(
  $id: String!
  $adviceName: String!
	$status: String
){
  addAdviceToMyList(input:{
    id: $id
    adviceName: $adviceName
    status: $status
  })
}
# {
#   "id": "test-2",
#   "adviceName": "Test 2",
#   "status": "A"
# }
`
const removeAdviceToMyList=gql`
mutation removeAdviceToMyList($service: String! $serviceId: String!){
  removeAdviceToMyList(service: $service serviceId: $serviceId)
}
# {
#   "service": "recaho-demo-organization::habit",
#   "serviceId": "khan.sohel005@gmail.com::test-1"
# }
`
const addAllergyToMyList =gql`
mutation addAllergyToMyList(
	$id: String!
	$allergyName: String!
  $allergyDesc: String
	$status: String
){
  addAllergyToMyList(input:{
    id: $id
    allergyName: $allergyName
		allergyDesc: $allergyDesc
    status: $status
  })
}
# {
#   "id": "test-1",
#   "allergyName": "Test 1",
#   "allergyDesc": "Test 1 desc",
#   "status": "A"
# }
`
const removeAllergyToMyList=gql`
mutation removeAllergyToMyList($service: String! $serviceId: String!){
  removeAllergyToMyList(service: $service serviceId: $serviceId)
}
# {
#   "service": "recaho-demo-organization::allergy",
#   "serviceId": "khan.sohel005@gmail.com::test-1"
# }
`

const addPhysicalExaminationToMyList=gql`
mutation addPhysicalExaminationToMyList(
  $id: String!
  $examinationName: String!
	$status: String
){
  addPhysicalExaminationToMyList(input:{
    id: $id
    examinationName: $examinationName
    status: $status
  })
}
# {
#   "id": "test-2",
#   "examinationName": "Test 2",
#   "status": "A"
# }
`
const removePhysicalExaminationToMyList=gql`
mutation removePhysicalExaminationToMyList($service: String! $serviceId: String!){
  removePhysicalExaminationToMyList(service: $service serviceId: $serviceId)
}
# {
#   "service": "recaho-demo-organization::physical_examination",
#   "serviceId": "khan.sohel005@gmail.com::test-1"
# }
`

const addHabitToMyList = gql`
mutation addHabitToMyList(
  $id: String!
  $habit: String!
	$status: String
){
  addHabitToMyList(input:{
    id: $id
    habit: $habit
    status: $status
  })
}
# {
#   "id": "test-2",
#   "habit": "Test 2",
#   "status": "A"
# }
`

const removeHabitToMyList = gql`
mutation removeHabitToMyList($service: String! $serviceId: String!){
  removeHabitToMyList(service: $service serviceId: $serviceId)
}
# {
#   "service": "recaho-demo-organization::habit",
#   "serviceId": "khan.sohel005@gmail.com::test-1"
# }
`