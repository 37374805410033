import React from 'react';
import { Layout, Menu, Breadcrumb, Card } from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import MainMenu from '../common/menu';
import { ThemeContext } from '../../context/themeContext';
import logo from '../../assets/CHT_logo_color.png'

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const AuthLayout=(props)=>{
    return(
      // <ThemeContext.Provider
      // value={{theme1}}
      // >
    
  <Layout>
    {/*     
    <Header className="header">
      <div className="logo" />
     <MainMenu/>
    </Header> */}
    

      <Layout style={{ padding: '0 24px 24px',background: '#fff' }}>

        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 900,
            display:'flex',
            justifyContent:'space-around',
            alignContent:'space-around',
            alignItems:'center',
            flexDirection:'column'
          }}
        >
          <div style={{textAlign: 'center'}}>
            <img src={logo} style={{width:300,height:'auto', marginBottom:20}} />
            <h1 style={{fontSize:20, marginBottom:35}}>Practice Management System</h1>

          <Card bordered={false} style={{ width: 600, margin: 'auto' }}>
            {props.children}
          </Card>
          </div>
      
        </Content>
      </Layout>

  </Layout>
      
  // </ThemeContext.Provider>
);
}

export default AuthLayout;
