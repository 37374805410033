let url = window.location.href;
console.log(url);

let env = 'production';


let environment = process.env && process.env.NODE_ENV ? process.env.NODE_ENV : env;
if (url.indexOf('dev') != -1) {
  environment = 'development'
}
if (url.indexOf('qa') != -1) {
  environment = 'testing'
}

console.log(environment);

environment = 'production';


// development
var configVariables = {
  ENDPOINT:
    "https://g6b5cnyclnd73ga7xq3kr6xz3e.appsync-api.ap-south-1.amazonaws.com/graphql",
  AUTHENTICATION_TYPE: "AMAZON_COGNITO_USER_POOLS",
  API_KEY: "da2-rxhdhda5dvdgxjtdldtntlr7je",
  REGION: "ap-south-1",
  accessKeyId: "AKIAVKH6JEUBXEXOCBWY",
  secretAccessKey: "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",
  BUCKET: "pms-vtwo-dev",
  COGNITO_USERPOOL_ID: "ap-south-1_4vKgGugmh",
  COGNITO_USERPOOL_WEBCLIENT_ID: "7bktrqg0gik7lbt962givobjdq"
};

// development
if (environment == 'development') {
  configVariables = {
    ENDPOINT: 'https://g6b5cnyclnd73ga7xq3kr6xz3e.appsync-api.ap-south-1.amazonaws.com/graphql',
    AUTHENTICATION_TYPE: 'AMAZON_COGNITO_USER_POOLS',
    API_KEY: 'da2-rxhdhda5dvdgxjtdldtntlr7je',
    REGION: 'ap-south-1',
    accessKeyId: 'AKIAVKH6JEUBXEXOCBWY',
    secretAccessKey: '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',
    BUCKET: 'pms-vtwo-dev',
    COGNITO_USERPOOL_ID: 'ap-south-1_4vKgGugmh',
    COGNITO_USERPOOL_WEBCLIENT_ID: '7bktrqg0gik7lbt962givobjdq'
  }
  // configVariables = {
  //   ENDPOINT:
  //     "https://2jvibkabivg5fh5yjccxpxexim.appsync-api.ap-south-1.amazonaws.com/graphql",
  //   AUTHENTICATION_TYPE: "AMAZON_COGNITO_USER_POOLS",
  //   API_KEY: "da2-rxhdhda5dvdgxjtdldtntlr7je",
  //   REGION: "ap-south-1",
  //   accessKeyId: "AKIAVKH6JEUBXEXOCBWY",
  //   secretAccessKey: "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",
  //   BUCKET: "pms-vtwo-qa",
  //   COGNITO_USERPOOL_ID: "ap-south-1_wEucfuJc3",
  //   COGNITO_USERPOOL_WEBCLIENT_ID: "537666g5h485urgnn9i7rao45e"
  // };
}

//testing
if (environment == "testing") {
  configVariables = {
    ENDPOINT:
      "https://2jvibkabivg5fh5yjccxpxexim.appsync-api.ap-south-1.amazonaws.com/graphql",
    AUTHENTICATION_TYPE: "AMAZON_COGNITO_USER_POOLS",
    API_KEY: "da2-rxhdhda5dvdgxjtdldtntlr7je",
    REGION: "ap-south-1",
    accessKeyId: "AKIAVKH6JEUBXEXOCBWY",
    secretAccessKey: "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",
    BUCKET: "pms-vtwo-qa",
    COGNITO_USERPOOL_ID: "ap-south-1_wEucfuJc3",
    COGNITO_USERPOOL_WEBCLIENT_ID: "537666g5h485urgnn9i7rao45e"
  };
}

//production
if (environment == "production") {
  configVariables = {
    ENDPOINT:
      "https://o7dl2mc7rnelfgt2dwun3fflzm.appsync-api.ap-south-1.amazonaws.com/graphql",
    AUTHENTICATION_TYPE: "AMAZON_COGNITO_USER_POOLS",
    API_KEY: "2qjh8u4p1pc8amhgcke5dooglk",
    REGION: "ap-south-1",
    accessKeyId: "AKIAVKH6JEUBXEXOCBWY",
    secretAccessKey: "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",
    BUCKET: "pms-vtwo-prod",
    COGNITO_USERPOOL_ID: "ap-south-1_4SEdjsyKE",
    COGNITO_USERPOOL_WEBCLIENT_ID: "2qjh8u4p1pc8amhgcke5dooglk"
  };
}

export { configVariables }; 
