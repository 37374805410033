import React, { createContext,useReducer,useState, useEffect ,useContext} from 'react';
import { Layout, Menu, Breadcrumb, Card, List, Avatar,Form, Button,Upload,Switch, Popconfirm, message, Skeleton, Row, Col, Calendar, PageHeader, Badge, Table, Radio, Input, Dropdown, Modal } from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined, CloseOutlined, RedoOutlined, LoginOutlined, PlusOutlined, SwapOutlined, UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { OrgInfoContext } from '../../../../../context/orgInfoContext';

import { withApollo } from 'react-apollo';
import WithAuthenticator from '../../../../withAuthenticator';
import { getDocList ,fetchGlobalComplaintListQuery ,fetchGlobalDiagnosisListQuery ,fetchGlobalMedicationListQuery, getGlobalAdviceQuery ,getGlobalAllergyQuery ,getGlobalHabitQuery ,getGlobalPhysicalExaminationQuery} from  '../../../../common/graphqlFunctions'
import { ContextReducer } from './mainMasterReducer'
import { UPDATE_FORM, SET_GLOBALCOMPLAINTS_LIST ,
  UPDATE_LIMIT ,  SET_LOADING ,SET_GLOBALDIAGNOSIS_LIST ,SET_GLOBALADVICE_LIST ,SET_GLOBALALLERGY_LIST,SET_GLOBALHABIT_LIST, SET_GLOBALPHYSICALEXAMINATION_LIST ,SET_GLOBALMEDICATION_LIST } from './mainMasterReducer'
// import {  specialityList } from '../../../../commonImports/data'
import { SecondaryWrapper } from './secondaryWrapper'



const { Search } = Input;
// const symptoms = ['Itching','Red Eyes','Left Hand Pain','Joint Pain','Vomiting' ,'Acne' ,'Shortness of breath','Fever','Testicular pain']




export const initialState = {
    limit:50,
    form: null,
    loading: false,
    globalComplaintsList:[],
    globalDiagnosisList: [],
    globalMedicationList: [],
    globalAdviceList:[],
    globalAllergyList:[],
    globalHabitList:[],
    globalPhysicalExaminationList:[]
  };

  export const ContextMainConfig = createContext(initialState);


const MainMasterComp = (props) => {

    const [form] = Form.useForm();

  const [state, dispatch] = useReducer(ContextReducer, initialState);

  const { limit} = state;
  // console.log('state',state)


  useEffect(() => {
    // console.log('call')
    
    dispatch({
      type: UPDATE_FORM,
      payload: form
    })
    },[]);

    useEffect(() => {
      // console.log('call')
    //   fetchDataAsync()
      },[]);
  




const setLoading= ()=>{
  dispatch({
    type: SET_LOADING,
    payload: state.loading
  })
}




const ComplaintsFunction = async()=>{
// console.log('')
  let variables = {
    limit: state['limit'],
    nextToken: ""
  }
  try{
      let res = await fetchGlobalComplaintListQuery(props,variables)
      if(res.data.getGlobalComplaintList && res.data.getGlobalComplaintList != null && res.data.getGlobalComplaintList.items != null){
        return res.data.getGlobalComplaintList.items
      }
  }catch(err){
      console.log(err)
  }
}

const diagnosisFunction = async()=>{
  // console.log('')
    let variables = {
      limit: state['limit'],
      nextToken: ""
    }
    try{
        let res = await fetchGlobalDiagnosisListQuery(props,variables)
        if(res.data.getGlobalDiagnosisList && res.data.getGlobalDiagnosisList != null && res.data.getGlobalDiagnosisList.items != null){
          return res.data.getGlobalDiagnosisList.items
        }
    }catch(err){
        console.log(err)
    }
  }

  const getGlobalMedicationList = async()=>{
    // console.log('')
      let variables = {
        limit: state['limit'],
        nextToken: ""
      }
      try{
          let res = await fetchGlobalMedicationListQuery(props,variables)
          // console.log('res',res)
          if(res.data.getGlobalMedicationList && res.data.getGlobalMedicationList != null && res.data.getGlobalMedicationList.items != null){
            return res.data.getGlobalMedicationList.items
          }
      }catch(err){
          console.log(err)
      }
  }
  
const getGlobalAdviceList = async()=>{
  // console.log('')
    let variables = {
      limit: state['limit'],
      nextToken: ""
    }
    try{
        let res = await getGlobalAdviceQuery(props,variables)
        console.log('res',res)
        if(res.data.getGlobalAdvice && res.data.getGlobalAdvice != null && res.data.getGlobalAdvice.items != null){
          return res.data.getGlobalAdvice.items
        }
    }catch(err){
        console.log(err)
    }
}


const getGlobalAllergyList = async()=>{
  // console.log('')
    let variables = {
      limit: state['limit'],
      nextToken: ""
    }
    try{
        let res = await getGlobalAllergyQuery(props,variables)
        // console.log('res',res)
        if(res.data.getGlobalAllergy && res.data.getGlobalAllergy != null && res.data.getGlobalAllergy.items != null){
          return res.data.getGlobalAllergy.items
        }
    }catch(err){
        console.log(err)
    }
}

const getGlobalHabitList = async()=>{
  // console.log('')
    let variables = {
      limit: state['limit'],
      nextToken: ""
    }
    try{
        let res = await getGlobalHabitQuery(props,variables)
        // console.log('res',res)
        if(res.data.getGlobalHabit && res.data.getGlobalHabit != null && res.data.getGlobalHabit.items != null){
          return res.data.getGlobalHabit.items
        }
    }catch(err){
        console.log(err)
    }
}


const getGlobalPhysicalExaminationList = async()=>{
  // console.log('')
    let variables = {
      limit: state['limit'],
      nextToken: ""
    }
    try{
        let res = await getGlobalPhysicalExaminationQuery(props,variables)
        // console.log('res',res)
        if(res.data.getGlobalPhysicalExamination && res.data.getGlobalPhysicalExamination != null && res.data.getGlobalPhysicalExamination.items != null){
          return res.data.getGlobalPhysicalExamination.items
        }
    }catch(err){
        console.log(err)
    }
}


const getGlobaldataList= async(type)=>{
  // console.log('getGlobaldataList',type)
  let arr = []
  if(type == 'complaints'){
    setLoading()
    let res = await ComplaintsFunction()
    dispatch({
          type:SET_GLOBALCOMPLAINTS_LIST,
          payload:res
        })
      arr = res
    setLoading()
  }
  if(type == 'diagnosis'){
    setLoading()
    let res = await diagnosisFunction()
    dispatch({
          type:SET_GLOBALDIAGNOSIS_LIST,
          payload:res
        })
      arr = res
    setLoading()
  }
  // getGlobalAdviceList
  if(type == 'medication'){
    setLoading()
    let res = await getGlobalMedicationList()
    dispatch({
      type:SET_GLOBALMEDICATION_LIST,
      payload:res
    })
    arr = res
    setLoading()
  }

  if(type == 'advice'){
    setLoading()
    let res = await getGlobalAdviceList()
    dispatch({
          type:SET_GLOBALADVICE_LIST,
          payload:res
        })
      arr = res
    setLoading()
  }

  if(type== 'allergy'){
    setLoading()
    let res = await getGlobalAllergyList()
    dispatch({
      type:SET_GLOBALALLERGY_LIST,
      payload:res
    })
    arr = res
    setLoading()
  }
  if(type == 'habit'){
    setLoading()
    let res = await getGlobalHabitList()
    dispatch({
      type:SET_GLOBALHABIT_LIST,
      payload:res
    })
  arr = res
  setLoading()
  }

  if(type == 'physical_examination'){
    setLoading()
    let res = await getGlobalPhysicalExaminationList()
    dispatch({
      type:SET_GLOBALPHYSICALEXAMINATION_LIST,
      payload:res
    })
  arr = res
  setLoading()
  }

  return arr ;
}





const fetch=(type)=>{
  // console.log(state['limit'],type)
  let limit=state['limit']+50
  dispatch({
      type:UPDATE_LIMIT,
      payload:limit
  })
  if(type=='complaints'){
     getGlobaldataList(type) 
  }
  if(type=='medication'){
    getGlobaldataList(type) 
  }
  if(type=='diagnosis'){
    getGlobaldataList(type) 
  }
  if(type=='advice'){
    getGlobaldataList(type) 
  }
  if(type == 'allergy'){
    getGlobaldataList(type)
  }
  if(type == 'habit'){
    getGlobaldataList(type)
  }
  if(type == 'physical_examination'){
    getGlobaldataList(type)
  }

}






 
  return (
      <>
        <ContextMainConfig.Provider value={{
            state,
            // onFinish,
            // selectedSpeciality,
              getGlobaldataList,
            // selectedDoc,
            fetch,
            setLoading,
            // updateGlobaldataList,
            // addToDoctorlist
          }}>
              <SecondaryWrapper>
            {props.children}
            </SecondaryWrapper>
        </ContextMainConfig.Provider> 
    </>
  )
}

const MainMasterWrapper = withRouter(withApollo(MainMasterComp));


export {
   MainMasterWrapper
}

// export default withRouter(withApollo(WithAuthenticator(MainMasteruration)))
