import gql from 'graphql-tag';

export {
  getFreeSlots,
  listPatients,
  getDoctorUnitMappings,
  getCaseheetsByPatientId,
  getConsultationServices,
  getAppointmentsByDocByMonth,
  getAppointmentsByDocByMonthCust,
  getDoctorById,
  getSchedulesByMonth,
  listDoctorsByOrg,
  listUnits,
  getUnitOrg,
  getPatientById,
  listPatientsByOrg,
  searchPatients,
  getBillByApptId,
  listQuestionTemplates,
  listSectionTemplates,
  listFormTemplates,
  getEncounterIdByApptId,
  getFormTemplatesByType,
  getFormTemplateByUId,
  getFormTemplateBySlugValue,
  getStaffById,
  listDevicesByOrg,
  listDevicesByUnit,
  listStaffByOrg,
  fetchDataByZip,
  getReferralDoctors,
  getReasonMasterByOrg,
  getReferralDoctorsInBulk,
  subscribeToNotification,
  getNotificationsByUserByCreatedAt,
  getEncounterById,
  getTestResultsbyPatientId,
  fetchMedication,
  fetchComplaints,
  fetchDiagnosis,
  getPatientPastHistory,
  getPharmacyRegistrationsByUnit,
  getPharmacyRegistrationBySlugValue,
  getLabRegistrationsByUnit,
  getTestConfigByPhysicalId,
  getGlobalComplaintList,
  getGlobalMedicationList,
  getGlobalDiagnosisList,
  getInventoriesByTypeValue,
  getTestReports,
  fetchAdvice,
  fetchHabit,
  fetchAllergy,
  fetchPhysicalExamination,
  getGlobalAdvice,
  getGlobalAllergy,
  getGlobalHabit,
  getGlobalPhysicalExamination,
  getPaymentOption,
  getEmailComm
}





const getPaymentOption = gql`
query{
  getPaymentOption{
    orgId
    typeValue
    active
    updatedAt
    paymentOptions{
      paymentType
      id
      barcode{
        bucket
        region
        key
      }
    }
  }
}`


const getEmailComm = gql`
  query{
    getEmailComm{
      orgId
      typeValue
      email
      updatedAt
    }
  }
`



const getTestReports = gql`
query getTestReports( $from: Int! $to: Int! ){
  getTestReports( from: $from to:$to ){
    # patientId
    createdAt
      testId
      tests{
        test
        unit
        results{
          value
          unit
          title
        }
      }
      orgId
      createdAt
      source
      unitId
      # patientId
      # createdBy
      patientProfile{
        patientId
        fName
        lName
        # mName
        address{
          lineOne
          lineTwo
          city
          state
          zip
          country
        }
        email
        contactNo
        gender
        birthDate
      }
      billDetails{
        # patientId
        # billId
        # status
        # type
        # createdAt
        # updatedAt
        paymentStatus
        finalAmt
        itemList{
          name
          rate
          qty
        }
        # billLineItemMap{
        #   billUId
        #   serviceCode
        #   qty
        #   rate
        #   totalAmt
        #   netAmt
        #   discount
        #   consumed
        #   addedBy
        #   addedAt
        # }
        # paymentType
        # createdBy
        # orgId
      }
  }
}`


const getInventoriesByTypeValue = gql`
query getInventoriesByTypeValue( $typeValue: String!){
  getInventoriesByTypeValue(typeValue: $typeValue){
     id
    typeValue
    code
    name
    description
    totalStock
    currentStock
    consumedStock
    adjustedStock
    createdAt
    updatedAt
    updatedBy
  }
}
# {
#   "typeValue": "global"
# }
`




const getGlobalPhysicalExamination = gql`
query getGlobalPhysicalExamination($limit: Int $nextToken: String!){
  getGlobalPhysicalExamination(limit: $limit nextToken: $nextToken){
    items{
      id
      slugValue
      examinationName
      status
    }
    nextToken
  }
}
# {
#   "limit": 20,
#   "nextToken": ""
# }
`

const getGlobalHabit = gql`
query getGlobalHabit($limit: Int $nextToken: String!){
  getGlobalHabit(limit: $limit nextToken: $nextToken){
    items{
      id
      slugValue
      habit
      status
    }
    nextToken
  }
}
# {
#   "limit": 20,
#   "nextToken": ""
# }
`

const getGlobalAllergy = gql`
query getGlobalAllergy($limit: Int $nextToken: String!){
  getGlobalAllergy(limit: $limit nextToken: $nextToken){
    items{
      id
      slugValue
      allergyName
      allergyDesc
      status
    }
    nextToken
  }
}
# {
#   "limit": 20,
#   "nextToken": ""
# }


`
const getGlobalAdvice = gql`
 query getGlobalAdvice($limit: Int $nextToken: String!){
  getGlobalAdvice(limit: $limit nextToken: $nextToken){
    items{
      id
      slugValue
      adviceName
      status
    }
    nextToken
  }
}
# {
#   "limit": 20,
#   "nextToken": ""
# }
`

const fetchPhysicalExamination = gql`
query fetchPhysicalExamination( $searchValue: String! $from: Int! $size: Int!){
  fetchPhysicalExamination(searchValue: $searchValue from: $from size:$size){
    id
    examinationName
    status
  }
}
# {
#   "searchValue": "kne",
#   "from": 0,
#   "size": 20
# }
`


const fetchAllergy = gql`
query fetchAllergy( $searchValue: String! $from: Int! $size: Int!){
  fetchAllergy(searchValue: $searchValue from: $from size:$size){
    id
    allergyName
    allergyDesc
    status
  }
}
# {
#   "searchValue": "foo",
#   "from": 0,
#   "size": 20
# }
`

const fetchHabit = gql`
query fetchHabit( $searchValue: String! $from: Int! $size: Int!){
  fetchHabit(searchValue: $searchValue from: $from size:$size){
    id
    habit
    status
  }
}
# {
#   "searchValue": "smokin",
#   "from": 0,
#   "size": 20
# }
`
const fetchAdvice = gql`
query fetchAdvice( $searchValue: String! $from: Int! $size: Int!){
  fetchAdvice(searchValue: $searchValue from: $from size:$size){
    id
    adviceName
    status
  }
}
# {
#   "searchValue": "rest",
#   "from": 0,
#   "size": 20
# }
`

const getGlobalDiagnosisList = gql`
query getGlobalDiagnosisList($limit: Int $nextToken:String){
  getGlobalDiagnosisList(limit:$limit, nextToken:$nextToken){
    items{
      id
      code
      name
    }
    nextToken
  }
}
# {
#   "limit": 5,
#   "nextToken": ""
# }
`
const getGlobalMedicationList = gql`
query getGlobalMedicationList($limit: Int $nextToken:String){
  getGlobalMedicationList(limit:$limit, nextToken:$nextToken){
    items{
      id
      genericName
      medicationName
      slugValue
    }
    nextToken
  }
}
# {
#   "limit": 5,
#   "nextToken": ""
# }
`

const getGlobalComplaintList = gql`
query getGlobalComplaintList($limit: Int $nextToken:String){
  getGlobalComplaintList(limit:$limit, nextToken:$nextToken){
    items{
      id
      complaintId
      complaint
    }
    nextToken
  }
}
# {
#   "limit": 5,
#   "nextToken": ""
# }
`

const getTestConfigByPhysicalId = gql`
query getTestConfigByPhysicalId( $physicalId: String!){
  getTestConfigByPhysicalId(physicalId: $physicalId){
    orgId
    deviceId
    physicalId
    deployedAt
    active
    deviceType
    categories{
      category
      active
      tests{
        text
        value
        rate
        url{
          key
          region
          bucket
        }
        enabled
        active
        deviceName
      }
    }
  }
}
`

const getDoctorUnitMappings = gql`
query getDoctorUnitMappings($email: String!){
  getDoctorUnitMappings(email:$email){
    id
    orgId
    email
    createdAt
  }
}
# {
#   "email": "suzain@gmail.com"
# }
`;



const getPatientPastHistory = gql`
query getPatientPastHistory( $patientId: String!){
  getPatientPastHistory(patientId: $patientId){
    patientId
    typeValue
    lifeStyle{
      description
      remark
    }
    familyHistory{
      diagnosis
      relation
      remark
    }
    surgicalHistory{
      surgery
      doctor
      date
      remark
    }
    medicationHistory{
      brandName
      genericName
      frequency
      dosage
      dosageUnit
      dosageDate
    }
    medicalHistory{
      icd
      remark
    }
    updatedAt
  }
}
# {
#   "patientId": "ash::gawai::female::2016-05-04"
# }
`

const fetchComplaints = gql`
query fetchComplaints( $searchValue: String! $from: Int! $size: Int!){ 
  fetchComplaints(searchValue: $searchValue from: $from size: $size){
    id
    complaintId
    complaint
  }
}
# {
#   "searchValue": "disease",
#   "from": 0,
#   "size": 20
# }
`;


const fetchMedication = gql`
query fetchMedication( $searchValue: String! $from: Int! $size: Int!){ 
  fetchMedication(searchValue: $searchValue from: $from size: $size){
    id
    genericName
    medicationName
  }
}


# {
#   "searchValue": "parra",
#   "from": 0,
#   "size": 20
# }
`;

const fetchDiagnosis = gql`
query fetchDiagnosis( $searchValue: String! $from: Int! $size: Int!){ 
  fetchDiagnosis(searchValue: $searchValue from: $from size: $size){
    id
    code
    name
  }
}
`;

const getTestResultsbyPatientId = gql`
query getTestResultsbyPatientId( $patientId: String! $limit: Int $nextToken: String){
  getTestResultsbyPatientId(patientId: $patientId limit: $limit nextToken: $nextToken){
    items{
      id
      patientId
      testId
      source
      createdBy
      createdAt
      tests{
        test
        unit
        results{
          title
          value
          unit
        }
      }
    }
    nextToken
  }

}
# {
#   "patientId": "ashwini::gawai::female::1994-01-01",
#   "limit": 10,
#   "nextToken": ""
# }
`;


const getEncounterById = gql`
query getEncounterById( $patientId: String! $encounterId: String!){
  getEncounterById(patientId: $patientId encounterId: $encounterId){
    patientId
    encounterId
    apptId
    createdAt
    updatedAt
    createdBy
    status
  }
}
# {
#   "patientId": "saquib::khan::male::2006-11-06",
#   "encounterId": "1596551741"
# }
`;


const getNotificationsByUserByCreatedAt = gql`
query getNotificationsByUserByCreatedAt( $limit: Int $nextToken: String){
  getNotificationsByUserByCreatedAt(limit: $limit nextToken: $nextToken){
    items{
      id
      userId
      notifId
      source
      notifType
      orgId
      createdAt
      patientId
      createdBy
      encounterId
    }
    nextToken
  }
}
# {
#   "limit": 20,
#   "nextToken": "" 
# }
`;

const subscribeToNotification = gql`
subscription subscribeToNotification($userId: String){
  subscribeToNotification(userId: $userId){
    id
      userId
      notifId
      source
      notifType
      orgId
      createdAt
      patientId
      createdBy
      encounterId
  }
}
# {
#   "userId": "khan.sohel005@gmail.com"
# }
`;



const fetchDataByZip = gql`
query fetchDataByZip( $country: CountryCode! $zip: String!){
  fetchDataByZip(country: $country zip: $zip){
    zip
    city
    state
    country
  }
}
# {
#   "country": "IND",
#   "zip": "411033"
# }
`;

const listStaffByOrg = gql`
query listStaffByOrg( $limit: Int $nextToken: String){
  listStaffByOrg(limit: $limit nextToken: $nextToken){
    items{
      id
      orgId
      prefix
      fName
      mName
      lName
      gender
      email
      countryCode
      mobile
      skypeId
      dob
      maritalStatus
      photo{
        bucket
        region
        key
      }
      userRole
      roleType
      active
      isDoctor
      createdAt
      updatedAt
      address{
        use
        type
        lineOne
        lineTwo
        city
        state
        zip
        country
      }
    }
    nextToken
  }
}
# {
#   "limit": 10,
#   "nextToken": ""
# }
`;

const listDevicesByOrg = gql`
query listDevicesByOrg{
  listDevicesByOrg{
    id
    orgId
    deviceId
    physicalId
    deployedAt
    active
    deviceType
    categories{
      category
      active
      tests{
        text
        value
        rate
        url{
          key
          bucket
          region
        }
        enabled
        active
      }
    }
  }
}
`;


const listDevicesByUnit = gql`
query listDevicesByUnit{
  listDevicesByUnit{
    # id
    orgId
    deviceId
    physicalId
    deployedAt
    active
    deviceType
    categories{
      category
      active
      tests{
        text
        value
        rate
        url{
          key
          bucket
          region
        }
        enabled
        active
      }
    }
  }
}
`;



const getStaffById = gql`
query getStaffById( $email: String!){
  getStaffById(email: $email){
    orgId
    email
    prefix
    fName
    mName
    lName
    gender
    mobile
    dob
    maritalStatus
    photo{
      bucket
      region
      key
    }
    userRole
    roleType
    active
    isDoctor
    createdAt
    updatedAt
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
  }
}
# {
#   "email": "sameer@gmail.com"
# }
`

const getFormTemplateBySlugValue = gql`
query getFormTemplateBySlugValue($typeValue: String! $slugValue: String!){
  getFormTemplateBySlugValue(typeValue: $typeValue slugValue: $slugValue){
    typeValue
    slugValue
    templateName
    templateType
    subTemplate
    sectionRefs
    active
    createdAt
    updatedAt
    attachedSections{
      id
      typeValue
      slugValue
      sectionName
      questionRefs
      active
      createdAt
      updatedAt
      attachedQuestions{
        id
    typeValue
    slugValue
    questionTitle
    questionType
    uId
    ageFrom
    ageTo
    appliedFor
    createdAt
    updatedAt
    active
    isRequired
    freeText{
      answerSummary
      freeTextRequired
    }
    number{
      answerSummary
      freeTextRequired
    }
    date{
      answerSummary
      freeTextRequired
    }
    file{
      answerSummary
      freeTextRequired
    }
    radioList{
      optionTitle
      optionSummary
      freeTextRequired
    }
    checkboxList{
      optionTitle
      optionSummary
      freeTextRequired
    }
    }
    }
  }
}
# {
#   "typeValue": "global::template",
#   "slugValue": "my-form"
# }
`;


const getFormTemplateByUId = gql`
query getFormTemplateByUId($uId: String! ){
  getFormTemplateByUId(uId: $uId ){
    typeValue
    slugValue
    templateName
    templateType
    subTemplate
    sectionRefs
    active
    createdAt
    updatedAt
    attachedSections{
      id
      typeValue
      slugValue
      sectionName
      questionRefs
      active
      createdAt
      updatedAt
      attachedQuestions{
        id
    typeValue
    slugValue
    questionTitle
    questionType
    uId
    ageFrom
    ageTo
    appliedFor
    createdAt
    updatedAt
    active
    isRequired
    freeText{
      answerSummary
      freeTextRequired
    }
    number{
      answerSummary
      freeTextRequired
    }
    date{
      answerSummary
      freeTextRequired
    }
    file{
      answerSummary
      freeTextRequired
    }
    radioList{
      optionTitle
      optionSummary
      freeTextRequired
    }
    checkboxList{
      optionTitle
      optionSummary
      freeTextRequired
    }
    }
    }
  }
}
# {
#   "uId": "ec533996-3102-4a1a-9cca-292b062ec7bc"
# }
`;


const getFormTemplatesByType = gql`
query getFormTemplatesByType( $typeValue: String! $templateType: String!){
  getFormTemplatesByType(typeValue: $typeValue templateType: $templateType){
    id
    uId
    typeValue
    slugValue
    templateName
    templateType
    subTemplate
    sectionRefs
    active
    createdAt
    updatedAt
    attachedSections{
      id
      typeValue
      slugValue
      sectionName
      questionRefs
      active
      createdAt
      updatedAt
      attachedQuestions{
        id
    typeValue
    slugValue
    questionTitle
    questionType
    uId
    ageFrom
    ageTo
    appliedFor
    createdAt
    updatedAt
    active
    isRequired
    freeText{
      answerSummary
      freeTextRequired
    }
    number{
      answerSummary
      freeTextRequired
    }
    date{
      answerSummary
      freeTextRequired
    }
    file{
      answerSummary
      freeTextRequired
    }
    radioList{
      optionTitle
      optionSummary
      freeTextRequired
    }
    checkboxList{
      optionTitle
      optionSummary
      freeTextRequired
    }
    }
    }
  }
}
# {
#   "typeValue": "global::template",
#   "templateType": "Complaint"
# }
`;


const getEncounterIdByApptId = gql`
query getEncounterIdByApptId( $patientId: String! $apptId: String!){
  getEncounterIdByApptId(patientId: $patientId apptId: $apptId){
    patientId
    encounterId
    apptId
    createdAt
    createdBy
  }
}
# {
#   "patientId": "sohel::khan::male::1994-10-28",
#   "apptId": "khan.sohel005@gmail.com::2020-MAY-04::10:00"
# }
`;


const listFormTemplates = gql`
query listFormTemplates( $typeValue: String!){
  listFormTemplates(typeValue: $typeValue){
    id
    typeValue
    slugValue
    templateName
    templateType
    subTemplate
    sectionRefs
    active
    createdAt
    updatedAt
    # uId
    attachedSections{
      id
      typeValue
      slugValue
      sectionName
      questionRefs
      active
      createdAt
      updatedAt
      attachedQuestions{
        id
    typeValue
    slugValue
    questionTitle
    questionType
    uId
    ageFrom
    ageTo
    appliedFor
    createdAt
    updatedAt
    active
    isRequired
    freeText{
      answerSummary
      freeTextRequired
    }
    number{
      answerSummary
      freeTextRequired
    }
    date{
      answerSummary
      freeTextRequired
    }
    file{
      answerSummary
      freeTextRequired
    }
    radioList{
      optionTitle
      optionSummary
      freeTextRequired
    }
    checkboxList{
      optionTitle
      optionSummary
      freeTextRequired
    }
      }
    }
  }
}
# {
#   "typeValue": "global::template"
# }
`;


const listSectionTemplates = gql`
query listSectionTemplates( $typeValue: String!){
  listSectionTemplates(typeValue: $typeValue){
    id
    typeValue
    slugValue
    sectionName
    questionRefs
    active
    createdAt
    updatedAt
    attachedQuestions{
    id
    typeValue
    slugValue
    questionTitle
    questionType
    uId
    ageFrom
    ageTo
    appliedFor
    createdAt
    updatedAt
    active
    isRequired
    freeText{
      answerSummary
      freeTextRequired
    }
    number{
      answerSummary
      freeTextRequired
    }
    date{
      answerSummary
      freeTextRequired
    }
    file{
      answerSummary
      freeTextRequired
    }
    radioList{
      optionTitle
      optionSummary
      freeTextRequired
    }
    checkboxList{
      optionTitle
      optionSummary
      freeTextRequired
    }
    }
  }
}
# {
#   "typeValue": "global::section"
# }
`

const listQuestionTemplates = gql`
query listQuestionTemplates( $typeValue: String!){
  listQuestionTemplates(typeValue: $typeValue){
    id
    typeValue
    slugValue
    questionTitle
    questionType
    uId
    ageFrom
    ageTo
    appliedFor
    createdAt
    updatedAt
    active
    isRequired
    freeText{
      answerSummary
      freeTextRequired
    }
    number{
      answerSummary
      freeTextRequired
    }
    date{
      answerSummary
      freeTextRequired
    }
    file{
      answerSummary
      freeTextRequired
    }
    radioList{
      optionTitle
      optionSummary
      freeTextRequired
    }
    checkboxList{
      optionTitle
      optionSummary
      freeTextRequired
    }
  }
}
# {
#   "patientId": "sohel::khan::male::1994-10-28",
#   "apptId": "khan.sohel005@gmail.com::2020-MAY-04::10:00"
# }
`;

const getBillByApptId = gql`
query getBillByApptId( $patientId: String! $apptId: String!){
  getBillByApptId(patientId: $patientId apptId: $apptId){
    patientId
    billId
    status
    type
    paymentStatus
    finalAmt
    updatedAt
    createdAt
    billLineItemMap{
      id
      billUId
      serviceCode
      qty
      rate
      totalAmt
      netAmt
      discount
      consumed
      addedBy
      addedAt
    }
  }
}
# {
#   "patientId": "sohel::khan::male::1994-10-28",
#   "apptId": "khan.sohel005@gmail.com::2020-MAY-04::10:00"
# }
`;

const searchPatients = gql`
query searchPatients($searchValue: String! $from: Int! $size: Int!){
  searchPatients(searchValue: $searchValue from: $from size: $size){
    items{
      id
      patientId
      typeValue
      active
      prefix
      fName
      mName
      lName
      contactNo
      gender
      birthDate
      email
      registeredSource
      myDocuments{
      docType
      docName
      date
      doc{
        bucket
        region
        key
      }
    }
    sponserDetails{
      companyName
      memNumber
      email
      }
      myInsurance {
        companyName
        insuranceNo
        startDate
        endDate
        doc{
        bucket
        region
        key
      }
      }
    referralDoctors
    }
    totCount
  }
}
`;



const listPatientsByOrg = gql`
query listPatientsByOrg( $limit: Int $nextToken: String){
  listPatientsByOrg(limit: $limit nextToken: $nextToken){
    items{
      id
    patientId
    typeValue
    active
    patientNo
    registeredSource
    registeredBy
    registeredAt
    patientProfile{
    patientId
    typeValue
    active
    prefix
    use
    fName
    lName
    mName
    contactSystem
    contactUse
    contactNo
    gender
    birthDate
    email
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    photo{
      bucket
      region
      key
    }
    registeredSource
    registeredBy
    myDocuments{
      docType
      docName
      date
      doc{
        bucket
        region
        key
      }
    }
    sponserDetails{
      companyName
      memNumber
      email
      }
      myInsurance {
        companyName
        insuranceNo
        startDate
        endDate
        doc{
        bucket
        region
        key
      }
      }
    referralDoctors
    contact{
      prefix
      use
      fName
      mName
      lName
      relationship
      gender
      contactSystem
      contactUse
      contactNo
      address{
        use
        type
        lineOne
        lineTwo
        city
        state
        zip
        country
      }
    }
    }

    }
    nextToken
  }
}
# {
#   "limit": 20,
#   "nextToken": ""
# }
`;


const getPatientById = gql`
query getPatientById( $patientId: String! ){
  getPatientById(patientId: $patientId ){
        # id
       patientId
      typeValue
      active
      prefix
      use
      email
      fName
      lName
      mName
      email
      contactSystem
      contactUse
      contactNo
      gender
      birthDate
      address{
        use
        type
        lineOne
        lineTwo
        city
        state
        zip
        country
      }
      photo{
        bucket
        region
        key
      }
      registeredSource
      registeredBy
      myDocuments{
      docType
      docName
      date
      doc{
        bucket
        region
        key
      }
    }
    sponserDetails{
      companyName
      memNumber
      email
      }
      myInsurance {
        companyName
        insuranceNo
        startDate
        endDate
        doc{
        bucket
        region
        key
      }
      }
    referralDoctors
      contact{
        prefix
        use
        fName
        mName
        lName
        relationship
        gender
        contactSystem
        contactUse
        contactNo
        address{
          use
          type
          lineOne
          lineTwo
          city
          state
          zip
          country
        }
      }
      patientOrgMap{
        patientId
        typeValue
        active
        patientNo
        registeredSource
        registeredBy
        registeredAt
      }
  }
}
# {
#   "patientId": "sohel::khan::male::1994-10-28"
# }
`;


const getUnitOrg = gql`
query getUnitOrg{
  getUnitOrg{
    # id
    fqdn
    unitName
    unitId
    orgId
    email
    contactNo
    pharmacyLicenseNo
    clinicRegiNo
    shopEstNo
    tradeNo
    isActive
    createdAt
    updatedAt
    skypeId
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    logo{
      bucket
      region
      key
    }
  }
}
# {
#   "fqdn": "clinivantage.pms.com"
# }
`


const listUnits = gql`
query listUnits( $fqdn: String!){
  listUnits(fqdn: $fqdn){
    id
    fqdn
    unitName
    unitId
    orgId
    email
    contactNo
    pharmacyLicenseNo
    clinicRegiNo
    shopEstNo
    tradeNo
    isActive
    createdAt
    updatedAt
    skypeId
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    logo{
      bucket
      region
      key
    }
  }
}
# {
#   "fqdn": "clinivantage.pms.com"
# }
`



const listDoctorsByOrg = gql`
query listDoctorsByOrg( $limit: Int $nextToken: String){
  listDoctorsByOrg(limit: $limit nextToken: $nextToken){
    items{
      id
     orgId
     prefix
    fName
    mName
    lName
    gender
    email
    countryCode
    mobile
    phoneNo
    skypeId
    empId
    dob
    photo{
      bucket
      region
      key
    }
    signature{
      bucket
      region
      key
    }
    maritalStatus
    electronCoverSheet
    doctorNo
    doctorType
    registrationCouncil
		registrationYear
		experience
    registrationNo
    active
    isDoctor
    createdAt
    allocateClassification
    speciality{
      speciality
	    subSpeciality
    }
    docDetails{
      docType
      docName
      docDate
      docFile{
        bucket
        region
        key
      }
    }
    bankDetails{
      bankName
      ifscCode
      accNo
      accHolder
      isPrimary
    } 
		followUpDuration
		followUpVisit
		aboutDoctor
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    }
    nextToken
    }
}
# {
#   "limit": 10,
#   "nextToken": ""
# }
`;




const getSchedulesByMonth = gql`
query getSchedulesByMonth( $doctorId: String! $month: String!){
  getSchedulesByMonth(doctorId: $doctorId month: $month){
    id
    scheduleId
    orgId
    doctorId
    scheduleDate
    slots{
      start
      end
      aptType
    }
  }
}
# {
#   "doctorId": "khan.sohel005@gmail.com(opens in new tab)",
#   "month": "2020-MAY"
# }
`


const getDoctorById = gql`
query getDoctorById( $id: String!){ 
  getDoctorById(id: $id){
    orgId
    prefix
    fName
    mName
    lName
    gender
    email
    countryCode
    mobile
    phoneNo
    skypeId
    empId
    dob
    photo{
      bucket
      region
      key
    }
    signature{
      bucket
      region
      key
    }
    maritalStatus
    electronCoverSheet
    doctorNo
    doctorType
    registrationNo
    active
    isDoctor
    createdAt
    allocateClassification
    registrationCouncil
		registrationYear
		experience
    speciality{
      speciality
	    subSpeciality
    }
    docDetails{
      docType
      docName
      docDate
      docFile{
        bucket
        region
        key
      }
    }
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
  }
}
#{
#  "id": "ruby.hospitals@gmail.com"
#
# }
`;



const getConsultationServices = gql`
query getConsultationServices( $doctorId: String!){
  getConsultationServices(doctorId: $doctorId){
    id
    service
    serviceId
    doctorId
    serviceName
    type
    speciality
    duration
    cost
    colorCode
    createdAt
  }
}
# {
#   "doctorId": "ruby.hospitals@gmail.com(opens in new tab)"
# }
`;


const getAppointmentsByDocByMonth = gql`
query getAppointmentsByDocByMonth( $doctorId: String! $month: String!){
  getAppointmentsByDocByMonth(doctorId: $doctorId month: $month){
    id
    orgId
    apptId
    doctorId
    patientId
    date
    slotTime
    duration
    apptType
    isActive
    status
    createdAt
    doctorProfile{
      allocateClassification
      orgId
      prefix
      fName
      mName
      lName
      gender
      email
      mobile
      skypeId
      photo{
        bucket
        region
        key
      }
      isDoctor
      active
      doctorType
    }
    patientProfile{
      # id
      patientId
      typeValue
      active
      prefix
      use
      fName
      lName
      mName
      contactSystem
      contactUse
      contactNo
      gender
      birthDate
      email
      address{
        use
        type
        lineOne
        lineTwo
        city
        state
        zip
        country
      }
      photo{
        bucket
        region
        key
      }
      registeredSource
      registeredBy
      contact{
        prefix
        use
        fName
        mName
        lName
        relationship
        gender
        contactSystem
        contactUse
        contactNo
        address{
          use
          type
          lineOne
          lineTwo
          city
          state
          zip
          country
        }
      }
      patientOrgMap{
        patientId
        typeValue
        active
        patientNo
        registeredSource
        registeredBy
        registeredAt
      }
      }
  }
}
# {
#   "doctorId": "khan.sohel005@gmail.com",
#   "month": "2020-MAY"
# }
`;


const getAppointmentsByDocByMonthCust = gql`
query getAppointmentsByDocByMonth( $doctorId: String! $month: String!){
  getAppointmentsByDocByMonth(doctorId: $doctorId month: $month){
    id
    apptId
    date
    status
  }
}
# {
#   "doctorId": "khan.sohel005@gmail.com",
#   "month": "2020-MAY"
# }
`;


const getFreeSlots = gql`
query getFreeSlots(
  $doctorId: String!
  $date: String!
  $type: String!
  $duration: Int!
){
  getFreeSlots(input:{
    doctorId: $doctorId
    date: $date
    type: $type
    duration: $duration
  })
}
# {
#   "doctorId": "recaho@gmail.com",
#   "date": "2020-MAY-03",
#   "type": "NEW",
#   "duration": 15
# }
`

const getCaseheetsByPatientId = gql`
query getCaseheetsByPatientId( $patientId: String! $limit: Int $nextToken: String){
  getCaseheetsByPatientId(patientId: $patientId limit: $limit nextToken: $nextToken){
    items{
    id
    patientId
    ehrId
    encounterId
    chiefComplaints{
      complaintName
      note
    }
    examinations{
      examination
      note
    }
    advice{
      advice
      note
    }
    medication{
      brandName
      genericName
      frequency
      dosage
      dosageUnit
      dosageDate
    }
    diagnosis{
      diagnosis
      type
      priority
      note
    }
    vitals{
      testName
      value
      unit
    }
    note
    createdAt
    }
    nextToken
  }
}
# {
#   "patientId": "sohel::khan::male::1994-10-28",
#   "encounterId": "12155",
#   "limit": 10,
#   "nextToken": ""
# }
`;



const listPatients = gql`
query listPatients( $limit: Int $nextToken: String){
  listPatients(limit: $limit nextToken: $nextToken){
    items{
    id
      active
      name {
        use
        family
        given
        prefix
        suffix
      }
      telecom {
        system
        value
        use
        rank
      }
      gender
      birthDate
      address {
        use
        type
        lineOne
        lineTwo
        city
        state
        zip
        country
      }
      photo {
        bucket
        key
        region
      }
      sponserDetails{
      companyName
      memNumber
      email
      }
      myInsurance {
        companyName
        insuranceNo
        startDate
        endDate
        doc{
        bucket
        region
        key
      }
      }
      contact {
        name {
          use
          family
          given
          prefix
          suffix
        }
        relationship
        gender
        address {
          use
          type
          lineOne
          lineTwo
          city
          state
          zip
          country
        }
        telecom {
          system
          value
          use
          rank
        }
      }
    }
    nextToken
  }
}
# {
#   "limit": 20,
#   "nextToken": ""
# }
`
const getReferralDoctors = gql`
query getReferralDoctors( $limit: Int $nextToken: String){
  getReferralDoctors( limit: $limit nextToken: $nextToken){
    items{
      id
      orgId 
      referralId
      referralName
      faxNo
      email
      mobile
      createdBy 
      createdAt
      referralType
      isActive
      address{
        use
        type
        lineOne
        lineTwo
        city
        state
        zip
        country
      }
    }
    nextToken
  }
}
# {
#   "limit": 10,
#   "nextToken": ""
# }
`;

const getReasonMasterByOrg = gql`
query{
  getReasonMasterByOrg{
    id
    orgId
    referralId
    reasonName
    slugReasonName
    description
    isActive
    createdAt
    updatedAt
    createdBy
  }
}
`

const getReferralDoctorsInBulk = gql`
query getReferralDoctorsInBulk( $ids: [String!]! ){
  getReferralDoctorsInBulk(ids: $ids){
    id
    orgId 
    referralId
    referralName
    faxNo
    email
    mobile
    createdBy 
    createdAt
    referralType
    isActive
    address{
      use
      type
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
  }
}
# {
#   "ids": ["khan.sohel005@gmail.com"],
#   "stage": "dev"
# }
`

const getPharmacyRegistrationsByUnit = gql`
query{
  getPharmacyRegistrationsByUnit{
    id
    orgId
    slugValue
    pharmacyName
    email
    countryCode
    mobile
    enabled
    address{
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    createdAt
  }
}
`;

const getPharmacyRegistrationBySlugValue = gql`
query getPharmacyRegistrationBySlugValue( $slugValue: String!){
  getPharmacyRegistrationBySlugValue(slugValue: $slugValue){
    # id
    orgId
    slugValue
    pharmacyName
    email
    countryCode
    mobile
    enabled
    address{
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    createdAt
  }
}
# {
#   "slugValue": "recaho-demo-organization::test-pharma"
# }
`;

const getLabRegistrationsByUnit = gql`
query{
  getLabRegistrationsByUnit{
    id
    orgId
    slugValue
    labName
    email
    countryCode
    mobile
    enabled
    address{
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    createdAt
  }
}
`;