import React, { createContext, useReducer, useEffect, useContext, useState } from "react";
import { SecondReducer, SET_COMPLAINTS_LIST, SET_DIAGNOSIS_LIST, SET_MEDICATION_LIST ,SET_GLOBALCOMPLAINTS_LIST} from './secondaryReducer';
import { AuthContext } from "../../../../../context/authContext";
import { configVariables } from "../../../../../configs/configs";
import { fetchMyList,fetchComplaintsQuery, fetchDiagnosisQuery, fetchMedicationQuery ,fetchGlobalComplaintListQuery} from "../../../../common/graphqlFunctions";
import { withApollo } from 'react-apollo';
import { CasesheetConfigContext } from './mainContextReducer';



export const initialState = {
    complaintsList: [],
    diagnosisList: [],
    medicationList: [],
    globalComplaintsList:[],
    globalDiagnosisList: [],
    globalMedicationList: [],
  };


const SecondaryWrapperComp = (props)=>{
    const authContext = useContext(AuthContext);
    const context = useContext(CasesheetConfigContext);
    const [state, dispatch] = useReducer(SecondReducer, initialState);

    // useEffect(() => {
    //     // console.log('call seconadry')
    //   selectedSubSpeCommonLocalData(context.state.selectedDocList)
    //   },[context.state.selectedDocList]);

    const fetchCSV = (type) => {
        // console.log('cla authContext',type,authContext)
        if (context.state.selectedDocData != null) {
          let variables = {
            bucket: configVariables.BUCKET,
            region: configVariables.REGION,
            orgId: context.state.selectedDocData.orgId,
            user: context.state.selectedDocData.email,
            fileName: type
          };
          
          
          fetchMyList(props, variables)
          .then(res => {
            console.log('res',res)
                if(type == 'complaints'){
                    dispatch({
                        type: SET_COMPLAINTS_LIST,
                        payload: res
                    })
                }
                if(type == 'diagnosis'){
                    dispatch({
                        type: SET_DIAGNOSIS_LIST,
                        payload: res
                    })
                }
                if(type == 'medication'){
                    dispatch({
                        type: SET_MEDICATION_LIST,
                        payload: res
                    })
                }
            })
            .catch(err => {
              console.log(err);
            });
        }
      };




   




      const getLocalData=(type)=>{
            fetchCSV(type)
      }

   
    

      const functionComplaintData= async (list,type)=>{
        console.log('call after submit')
        
        let doctorlist=list && list.length >0 ? list :[]
        for(let doctor of doctorlist ){
          let docComplainitList= await fetchCSCommon(doctor,type)
          if(docComplainitList && docComplainitList.length>0){

            for( let complaint of docComplainitList){
              let globalComplaintList= context.state.globalComplaintsList
              let index = globalComplaintList.findIndex((obj)=>obj.complaintId==complaint.complaintId)
              if(index !== -1){
                if(globalComplaintList[index].assignedList){
                  globalComplaintList[index].assignedList.push(doctor.value.email)
                }else{
                  globalComplaintList[index].assignedList=[]
                  globalComplaintList[index].assignedList.push(doctor.value.email)
                }
              }
              await context.updateGlobaldataList('complaint',globalComplaintList)
              
            }
          }
        }
        
      }

      const functionDiagnosisData= async (list,type)=>{
        let doctorlist=list && list.length >0 ? list :[]
        for(let doctor of doctorlist ){
          let docDiagnosisList= await fetchCSCommon(doctor,type)
          if(docDiagnosisList && docDiagnosisList.length>0){

            for( let diagnosis of docDiagnosisList){
              let globalDiagnosisList= context.state.globalDiagnosisList
          // console.log('functionDiagnosisData',docDiagnosisList,globalDiagnosisList)

               let index = globalDiagnosisList.findIndex((item)=>(item.code.indexOf('diagnosis::')!== -1 && item.code.split('::')[1]!==undefined ? item.code.split('::')[1] :item.code) ==diagnosis.code)
              //  console.log('item',item,diagnosis))
            //  console.log('index',index)
               if(index !== -1){
                if(globalDiagnosisList[index].assignedList){
                  globalDiagnosisList[index].assignedList.push(doctor.value.email)
                }else{
                  globalDiagnosisList[index].assignedList=[]
                  globalDiagnosisList[index].assignedList.push(doctor.value.email)
                }
              }
              // console.log('globalDiagnosisList',globalDiagnosisList)
              await context.updateGlobaldataList('diagnosis',globalDiagnosisList)
              
            }
          }
        }
        
      }


      // const assignCompliant = async (complaint)=>{
      //   for ( let doctor of doctorlist){
      //     if (complaint.assignedList && complaint.assignedList.length > 0 ){
      //       let index = complaint.assignedList.findIndex(val => val == doctor.email)
      //       if(index == -1){
      //         let res = await addtoMyList()
      //       }
      //     } else {

      //       let res = await addtoMyList()
      //     }
      //   }

      // }

      const selectedSubSpeCommonLocalData =async(list,type)=>{
          context.setLoading()
          if(type == 'complaints'){
            let data= await  functionComplaintData( list,type)
          }
          if(type == 'diagnosis'){
            let data= await functionDiagnosisData( list,type)
          }
          if(type == 'medication'){

          }
      }

      const fetchCSCommon = async(item,type) => {
        // let arr=[]
        // console.log('cla authContext',type,item)
        return await new Promise((responce, rej) => {
        if (item.value != null) {
          let variables = {
            bucket: configVariables.BUCKET,
            region: configVariables.REGION,
            orgId: item.value.orgId,
            user: item.value.email,
            fileName: type
          };
          fetchMyList(props, variables)
          .then(res => {
              // console.log('res',res)
                    responce(res)
            })
            .catch(err => {
              console.log(err);
            });
        
        }
      })
      };


     

    return (
        <SecondaryContext.Provider value={{ state, dispatch, getLocalData ,fetchCSV ,selectedSubSpeCommonLocalData}}>
            {props.children}
        </SecondaryContext.Provider>
    )
}

export const SecondaryContext = createContext(initialState);

const SecondaryWrapper = withApollo(SecondaryWrapperComp)


export {
    SecondaryWrapper
}